export enum ProfileAttrType {
  firstName = "firstName",
  lastName = "lastName",
  storeName = "storeName",
}

export interface ProfileFields {
  firstName: string;
  lastName: string;
  storeName: string;
}
