import React from "react";
import {
  Attribute,
  VariantAttribute,
} from "../../../types/product/new/product.model";
import ProductItemAttribute from "./ProductItemAttribute";
interface ProductItemAttributeListProps {
  attributes: VariantAttribute[];
  selectedAttributes: Attribute[];
  handleOnClick: (attributeValue: string, attributeName: string) => void;
}

export default function ProductItemAttributeList({
  attributes,
  selectedAttributes,
  handleOnClick,
}: ProductItemAttributeListProps) {
  return (
    <>
      {attributes.map((attribute, key) => (
        <ProductItemAttribute
          key={key}
          attribute={attribute}
          selectedAtrributes={selectedAttributes}
          handleOnClick={handleOnClick}
        />
      ))}
    </>
  );
}
