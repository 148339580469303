import { styled } from "../../../../stitches/stitches.config";
import * as FeatherIcons from "react-feather";
import { Display } from "../../../../stitches/typography/DisplayStyles";
import { Heading } from "../../../../stitches/typography/HeadingStyles";

const Container = styled("div", {
  background:
    "linear-gradient(180deg, rgba(74, 184, 207, 0.04) 0%, rgba(51, 156, 178, 0.13) 100%)",
  display: "inline-flex",
  flexDirection: "row",
  columnGap: "10px",
  borderRadius: "10px",
  position: "relative",
  padding: "10px",
});

const IconContainer = styled("div", {
  background:
    "linear-gradient(180deg, rgba(74, 184, 207, 0.04) 0%, rgba(51, 156, 178, 0.13) 100%)",
  width: "52px",
  height: "52px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const TextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const ValueText = styled(Display, {
  background: "$gradients$secondary_gradient",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  margin: "0",
});

const LabelText = styled(Heading, {
  color: "$text_40",
});

export interface ICardProps
  extends React.ComponentPropsWithoutRef<typeof Container> {
  iconName: keyof typeof FeatherIcons;
  iconColor?: string;
  value: string | number;
  label: string;
}

const getStyledIcon = (icon: FeatherIcons.Icon, iconColor?: string) => {
  return styled(icon, {
    color: iconColor || "$colors$secondary_60",
    width: "32px",
    height: "32px",
  });
};

export default function Card({
  iconName,
  iconColor,
  value,
  label,
}: ICardProps) {
  const Icon = getStyledIcon(FeatherIcons[iconName], iconColor);

  return (
    <Container>
      <IconContainer>
        <Icon />
      </IconContainer>
      <TextContainer>
        <ValueText variant="display_sm_01">{value}</ValueText>
        <LabelText variant="heading_02">{label}</LabelText>
      </TextContainer>
    </Container>
  );
}
