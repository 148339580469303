import React from "react";
import StoreProductList from "../../StoreProduct/view/StoreProductList";
import StoreHeader from "../../StoreHeader/View/StoreHeader";
import Spinner from "../../../../components/Spinner/Spinner";
import { styled } from "../../../../stitches/stitches.config";
import Footer from "../../../../components/Footer/Types/Footer";
import { useStoreview } from "./useStoreview";
import StoreSearchFilterBar from "../../../../admin/StoreSearchFilterBar/StoreSearchFilterBar";
import StoreFilterPanel from "../../../../admin/StoreSearchFilterBar/StoreFilterPanel";
import Container from "@mui/material/Container";
import { useDeviceDimensions } from "../../../../hooks/useDevice";
import { StoreLayout } from "../../StoreLayout";
import StoreNotFound from "./StoreNotFoundview";

const StoreBody = styled("div", {
  paddingBottom: "94px",
});

const StoreContainer = styled("div", {
  position: "relative",
  minHeight: "100vh",
});

const BodyContainer = styled("div", {
  display: "flex",
  gap: "32px",
  alignItems: "start",
});

const DesktopSearchFilterContainer = styled("div", {
  position: "sticky",
  top: "$space$space_16",
});

export default function Storeview() {
  const { loading, storeName, storeViewProducts, isLoading, storeFound } =
    useStoreview();
  const { isDesktop, isWideDesktop } = useDeviceDimensions();

  if (loading || !storeName || isLoading || storeFound === undefined) {
    return <Spinner />;
  }

  if (!storeFound) {
    return <StoreNotFound />;
  }

  return isDesktop || isWideDesktop ? (
    <StoreContainer>
      <StoreHeader />
      <Container maxWidth={false} sx={{ maxWidth: "1600px" }}>
        <BodyContainer>
          <DesktopSearchFilterContainer>
            <StoreSearchFilterBar />
          </DesktopSearchFilterContainer>
          <StoreBody>
            <StoreFilterPanel />
            <StoreProductList products={storeViewProducts} />
          </StoreBody>
        </BodyContainer>
      </Container>
      <Footer />
    </StoreContainer>
  ) : (
    <StoreContainer>
      <StoreHeader />
      <StoreSearchFilterBar />
      <StoreFilterPanel />
      <StoreLayout>
        <StoreBody>
          <StoreProductList products={storeViewProducts} />
        </StoreBody>
      </StoreLayout>
      <Footer />
    </StoreContainer>
  );
}
