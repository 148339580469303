import React from "react";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { styled } from "../../../stitches/stitches.config";
import { bodyStyles } from "../../../stitches/typography/BodyStyles";

const Description = styled("p", {
  color: "$text_40",
  ...bodyStyles.body_01,
  marginTop: "$space$space_16",
  textAlign: "justify",
});

const DescriptionContainer = styled("div", {
  marginBottom: "120px",
});

interface IProductDescription {
  children: React.ReactNode;
}

export default function ProductDescription({ children }: IProductDescription) {
  return (
    <DescriptionContainer>
      <Heading variant="heading_02">Description</Heading>
      <Description>{children}</Description>
    </DescriptionContainer>
  );
}
