import React from "react";
import { TextBox, TextBoxIconPosition } from "../../stitches/textbox/TextBox";
import { Search } from "react-feather";
import { styled } from "../../stitches/stitches.config";

const SearchContainer = styled("div", {
  width: "100%",
  marginLeft: "$space$space_16",
});

export interface StoreSearchProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function StoreSearch({ value, onChange }: StoreSearchProps) {
  const icon = {
    position: TextBoxIconPosition.LEADING,
    Icon: Search,
  };

  return (
    <SearchContainer>
      <TextBox
        placeholder="Search"
        icon={icon}
        value={value}
        onChange={onChange}
      />
    </SearchContainer>
  );
}
