import randomColorRGB from "random-color-rgb";
import {
  generateChartDataProps,
  IChartProps,
} from "../../../types/ChartFields";
import ChartGenerators from "./ChartGenerators";
import {
  generateLineChartOptionsProps,
  ILineChartOptions,
} from "./LineChartGenerator";

export default class ChartDataGenerator implements ChartGenerators {
  generateLineChartOptions({
    props,
  }: generateLineChartOptionsProps): ILineChartOptions {
    throw new Error("Method not implemented.");
  }

  generateDataset(
    data: generateChartDataProps
  ): IChartProps["data"]["datasets"] {
    return data.props.map(({ label, data }) => {
      const color = randomColorRGB();
      return {
        label,
        data,
        borderColor: color,
        backgroundColor: color,
      };
    });
  }
}
