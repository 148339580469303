import React from "react";
import { styled } from "../../../stitches/stitches.config";
import ItemsCard from "../OrderReview/ItemsCard/ItemsCard";
import { TextBox } from "../../../stitches/textbox/TextBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CustomerStore } from "../../../pullstate/Customer";
import { CustomerFields } from "../../../types/CustomerFields";
import { deliveryDetailsValidationSchema } from "../DeliveryDetails/DeliveryDetails.resolver";
import { Body } from "../../../stitches/typography/BodyStyles";
import { Home, User, DollarSign } from "react-feather";
import Payment from "../Payment/Payment";

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const Form = styled("form", {
  width: "100%",
  paddingLeft: "40px",
  paddingRight: "40px",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const PersonalInfoHeaderIcon = styled(User, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const HomeAddressHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const HomeAddressHeaderIcon = styled(Home, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const HomeAddressHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const Container = styled("div", {
  display: "flex",
  width: "100%",
  padding: "$space_20 $space_10",
  gap: "46px",
});

const Column = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const PaymentTypeHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10",
});

const PaymentTypeHeaderIcon = styled(DollarSign, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const PaymentTypeHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const InputWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "$space$space_16",
});

export default function CheckoutDesktop({
  onSubmitOrder,
  navigateBackToDeliveryDetails,
  handleOnClick,
}) {
  const { firstName, lastName, email, phoneNumber, address } =
    CustomerStore.useState((s) => s.checkout.deliveryDetails);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Omit<CustomerFields, "id">>({
    mode: "onChange",
    resolver: yupResolver(deliveryDetailsValidationSchema),
    defaultValues: {
      firstName,
      lastName,
      email,
      phoneNumber,
      address: {
        addressLine: address.addressLine,
        city: address.city,
        country: address.country,
      },
    },
  });

  return (
    <>
      <Form>
        <Container>
          <Column style={{ flex: "1.5 0 0" }}>
            <PersonalInfoHeadingContainer>
              <PersonalInfoHeaderIcon size={24} />
              <PersonalInfoHeaderHeading variant="body_02">
                Personal Information
              </PersonalInfoHeaderHeading>
            </PersonalInfoHeadingContainer>
            <InputWrapper>
              <Input
                label="First Name"
                placeholder="First Name"
                {...register("firstName")}
                onChange={(e) => {
                  CustomerStore.update((s) => {
                    s.checkout.deliveryDetails.firstName = e.target.value;
                  });
                }}
                error={errors.firstName?.message}
              />
              <Input
                label="Last Name"
                placeholder="Last Name"
                {...register("lastName")}
                error={errors.lastName?.message}
                onChange={(e) => {
                  CustomerStore.update((s) => {
                    s.checkout.deliveryDetails.lastName = e.target.value;
                  });
                }}
              />
            </InputWrapper>
            <Input
              label="Email Address"
              placeholder="Email Address"
              {...register("email")}
              error={errors.email?.message}
              onChange={(e) => {
                CustomerStore.update((s) => {
                  s.checkout.deliveryDetails.email = e.target.value;
                });
              }}
            />
            <Input
              label="Phone Number"
              placeholder="0342-2345678"
              {...register("phoneNumber")}
              error={errors.phoneNumber?.message}
              onChange={(e) => {
                CustomerStore.update((s) => {
                  s.checkout.deliveryDetails.phoneNumber = e.target.value;
                });
              }}
            />
            <HomeAddressHeadingContainer>
              <HomeAddressHeaderIcon size={24} />
              <HomeAddressHeaderHeading variant="body_02">
                Delivery Address
              </HomeAddressHeaderHeading>
            </HomeAddressHeadingContainer>
            <Input
              label="Address"
              placeholder="House#12, Street#1"
              {...register("address.addressLine")}
              error={errors.address?.addressLine?.message}
              onChange={(e) => {
                CustomerStore.update((s) => {
                  s.checkout.deliveryDetails.address.addressLine =
                    e.target.value;
                });
              }}
            />
            <InputWrapper>
              <Input
                label="City"
                placeholder="Karachi"
                {...register("address.city")}
                defaultValue={address.city}
                error={errors.address?.city?.message}
                onChange={(e) => {
                  CustomerStore.update((s) => {
                    s.checkout.deliveryDetails.address.city = e.target.value;
                  });
                }}
              />
              <Input
                label="Country"
                placeholder="Pakistan"
                {...register("address.country")}
                error={errors.address?.country?.message}
                onChange={(e) => {
                  CustomerStore.update((s) => {
                    s.checkout.deliveryDetails.address.country = e.target.value;
                  });
                }}
              />
            </InputWrapper>
            <PaymentTypeHeadingContainer>
              <PaymentTypeHeaderIcon size={24} />
              <PaymentTypeHeaderHeading variant="body_02">
                Payment Method
              </PaymentTypeHeaderHeading>
            </PaymentTypeHeadingContainer>
            <Payment
              navigateBackToDeliveryDetails={navigateBackToDeliveryDetails}
              handleOnClick={handleOnClick}
            />
          </Column>
          <Column>
            <ItemsCard onSubmitOrder={handleSubmit(onSubmitOrder)} />
          </Column>
        </Container>
      </Form>
    </>
  );
}
