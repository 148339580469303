import { createStitches } from "@stitches/react";
import type * as Stitches from "@stitches/react";
export type { VariantProps } from "@stitches/react";

export const {
  styled,
  css,
  theme,
  createTheme,
  getCssText,
  globalCss,
  keyframes,
  config,
  reset,
} = createStitches({
  theme: {
    gradients: {
      primary_gradient: "linear-gradient(0, #45B665, #5AE982)",
      secondary_gradient: "linear-gradient(0, #3f98AB, #4CBDD4)",
      tertiary_gradient: "linear-gradient(0, #B09244, #DEB751)",
    },
    colors: {
      primary_20: "#1E522D",
      primary_40: "#328549",
      primary_60: "#45B665",
      primary_80: "#5AE982",
      primary_100: "#5CFF8A",

      secondary_20: "#1E4952",
      secondary_40: "#337786",
      secondary_60: "#3f98AB",
      secondary_80: "#4CBDD4",
      secondary_100: "#57E2FF",

      tertiary_20: "#52341E",
      tertiary_40: "#907838",
      tertiary_60: "#B09244",
      tertiary_80: "#DEB751",
      tertiary_100: "#FFD057",

      text_20: "#000000",
      text_40: "#3A3A3A",
      text_60: "#5F5F5F",
      text_80: "#7C7C7C",
      text_100: "#9E9E9E",

      danger_50: "#BF2323",
      danger_100: "#FF5252",
      danger_150: "#FF6D6D",
      success_50: "#259036",
      success_100: "#50E969",
      success_75: "#3BBD50",
      caution_50: "#E4B714",
      caution_100: "#FFDC5C",
      grey_50: "#DFDFDF",
      grey_100: "#F2F2F2",
      white_100: "#FFFFFF",
      black_100: "#000000",
    },
    fonts: {
      primary: "",
      secondary: "",
    },
    space: {
      space_0: "0px",
      space_8: "8px",
      space_10: "10px",
      space_14: "14px",
      space_16: "16px",
      space_18: "18px",
      space_20: "20px",
      space_24: "24px",
      space_28: "28px",
    },

    sizes: {
      HEADING: "",
      SUB_HEADING: "",
      PARAGRAPH: "",
    },

    fontSizes: {},

    fontWeights: {
      1: "100",
      2: "200",
      3: "300",
      4: "400",
      5: "500",
      6: "600",
      7: "700",
      8: "800",
    },

    radii: {
      ROUND: "50%",
      PILL: "9999px",
    },

    zIndices: {
      Z1: "100",
      Z2: "200",
      Z3: "300",
      Z4: "400",
      MAX: "999",
    },
  },

  media: {
    Mobile: "(max-width: 767px)",
    Tablet: "(min-width: 768px) and (max-width: 1199px)",
    Desktop: "(min-width: 1200px)",
    Wide: "(min-width: 1584px)",
  },

  utils: {
    pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    mt: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<"marginRight">) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
      marginBottom: value,
    }),

    fd: (value: Stitches.PropertyValue<"flexDirection">) => ({
      flexDirection: value,
    }),
    fw: (value: Stitches.PropertyValue<"flexWrap">) => ({ flexWrap: value }),

    ai: (value: Stitches.PropertyValue<"alignItems">) => ({
      alignItems: value,
    }),
    ac: (value: Stitches.PropertyValue<"alignContent">) => ({
      alignContent: value,
    }),
    jc: (value: Stitches.PropertyValue<"justifyContent">) => ({
      justifyContent: value,
    }),
    as: (value: Stitches.PropertyValue<"alignSelf">) => ({ alignSelf: value }),
    fg: (value: Stitches.PropertyValue<"flexGrow">) => ({ flexGrow: value }),
    fs: (value: Stitches.PropertyValue<"flexShrink">) => ({
      flexShrink: value,
    }),

    bc: (value: Stitches.PropertyValue<"backgroundColor">) => ({
      backgroundColor: value,
    }),

    br: (value: Stitches.PropertyValue<"borderRadius">) => ({
      borderRadius: value,
    }),

    bs: (value: Stitches.PropertyValue<"boxShadow">) => ({ boxShadow: value }),

    lh: (value: Stitches.PropertyValue<"lineHeight">) => ({
      lineHeight: value,
    }),

    ox: (value: Stitches.PropertyValue<"overflowX">) => ({ overflowX: value }),
    oy: (value: Stitches.PropertyValue<"overflowY">) => ({ overflowY: value }),

    size: (value: Stitches.PropertyValue<"width">) => ({
      width: value,
      height: value,
    }),
  },
});

export type CSS = Stitches.CSS<typeof config>;
