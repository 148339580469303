import React from "react";
import { AdminStore } from "../../../../../pullstate/Admin";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";
import { styled } from "../../../../../stitches/stitches.config";
import IconBadge from "../../../../../stitches/iconBadge/IconBadge";
import { Box, Save, Trash, X } from "react-feather";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import { useProductContainer } from "./useEditProductContainer";
import ProductVariants from "./ProductVariants";
import ProductImageContainer from "./ProductImageContainer";
import { Button } from "../../../../../stitches/button/Button";
import Spinner from "../../../../../components/Spinner/Spinner";
import ExitWithoutSavingDialog from "./ExitWithoutSavingDialog";
import ProductFileUploader from "../../../../AddProduct/ProductContainer/ProductFileUploader";
import CancelChangesDialog from "./CancelChangesDialog";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "auto",
});

const ProductsHeading = styled(Heading, {
  color: "$secondary_60",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "80px 0 32px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
  justifyContent: "space-between",
});

const ButtonsContainer = styled("div", {
  display: "flex",
  columnGap: "16px",
});

const IconAndHeadingContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const Form = styled("form", {
  width: "100%",
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const PersonalInfoHeaderIcon = styled(Box, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const CancelButton = styled(Button, {
  width: "100%",
});

const SaveButton = styled(Button, {
  width: "100%",
});

export default function MobileProductContainer() {
  const {
    handleSubmit,
    onSubmit,
    register,
    unregister,
    uppyRef,
    setValue,
    watch,
    setHasVariantToTrue,
    isButtonDisabled,
    isLoading,
    navigateToProducts,
    handleExitWithoutSaving,
    handleCancelChanges,
    isExitModalOpen,
    isCancelChangesModalOpen,
    handleDialogClose,
    product,
    handleExitEditMode,
    deleteVariantsIdsRef,
    openExitWithoutSavingDialog,
    openCancelChangesDialog,
    handleSizeChartUpload,
  } = useProductContainer();

  if (!product) {
    navigateToProducts();
    return null;
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <IconAndHeadingContainer>
          <IconBadge
            iconName="ChevronLeft"
            outlined={false}
            size="large"
            customCSS={{
              Icon: {
                color: "secondary_60",
              },
            }}
            onClick={openExitWithoutSavingDialog}
          />
          <ProductsHeading variant="heading_05">Edit Mode</ProductsHeading>
        </IconAndHeadingContainer>
      </HeaderContainer>
      <ButtonsContainer>
        <CancelButton
          variant="secondary"
          size="small"
          withIcon="true"
          onClick={openCancelChangesDialog}
        >
          <X size={24} />
          Cancel
        </CancelButton>
        <SaveButton
          variant="primary"
          size="small"
          withIcon="true"
          onClick={handleSubmit(onSubmit)}
        >
          <Save size={24} />
          Save
        </SaveButton>
      </ButtonsContainer>

      {isLoading || !product ? (
        <Spinner />
      ) : (
        <Form>
          <PersonalInfoHeadingContainer>
            <PersonalInfoHeaderIcon size={24} />
            <PersonalInfoHeaderHeading variant="body_02">
              Product Details
            </PersonalInfoHeaderHeading>
          </PersonalInfoHeadingContainer>
          <Input
            label="Name"
            defaultValue={product?.name}
            {...register("name", {
              required: "Required Field",
            })}
          />
          <TextBox
            label="Description"
            placeholder="Enter a description for this product"
            defaultValue={product?.description}
            isMultiLine={true}
            {...register("description", {
              required: "Required Field",
            })}
          />
          {product.sizeChart && (
            <ProductFileUploader
              handleSizeChart={handleSizeChartUpload}
              imageurl={product.sizeChart?.url}
            />
          )}
          <ProductVariants
            register={register}
            unregister={unregister}
            watch={watch}
            setHasVariantToTrue={setHasVariantToTrue}
            hasVariants={product.hasVariants}
            variants={product.variants}
            setValue={setValue}
            deleteVariantsIdsRef={deleteVariantsIdsRef}
          />

          <ProductImageContainer uppyRef={uppyRef} images={product.images} />
        </Form>
      )}

      <CancelChangesDialog
        handleDialogClose={handleDialogClose}
        isDialogOpen={isCancelChangesModalOpen}
        handleExitDialog={handleCancelChanges}
      />
      <ExitWithoutSavingDialog
        handleDialogClose={handleDialogClose}
        isDialogOpen={isExitModalOpen}
        handleExitDialog={handleExitWithoutSaving}
      />
    </MainContainer>
  );
}
