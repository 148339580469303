import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { Button } from "../../../stitches/button/Button";
import { ChevronLeft, ChevronRight } from "react-feather";

const PaymentFooterLayout = styled("div", {
  height: "80px",
  width: "100%",
  backgroundColor: "$white_100",
  display: "flex",
  gap: "$space$space_16",
  alignItems: "center",
  boxShadow: "$space$space_0 -5px 22px rgba(0, 0, 0, 0.05)",
  position: "fixed",
  bottom: "$space$space_0",
  padding: "$space$space_16",
});

const NavigateToDeliveryDetailsButton = styled(Button, {
  height: "48px",
  width: "93px",
});

const NavigateOrderReviewButton = styled(Button, {
  height: "48px",
  width: "100%",
});

interface IDeliveryDetailsFooter {
  navigateToDeliveryDetails: () => void;
  navigateToOrderReview: () => void;
}

export default function PaymentFooter({
  navigateToDeliveryDetails,
  navigateToOrderReview,
}: IDeliveryDetailsFooter) {
  return (
    <PaymentFooterLayout>
      <NavigateToDeliveryDetailsButton
        variant="secondary"
        outlined="true"
        size="small"
        withIcon
        onClick={navigateToDeliveryDetails}
      >
        <ChevronLeft size={24} />
        Back
      </NavigateToDeliveryDetailsButton>
      <NavigateOrderReviewButton
        variant="secondary"
        size="small"
        withIcon
        onClick={navigateToOrderReview}
      >
        Next
        <ChevronRight size={24} />
      </NavigateOrderReviewButton>
    </PaymentFooterLayout>
  );
}
