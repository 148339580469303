import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { styled } from "../../../../stitches/stitches.config";
import {
  Heading,
  headings,
} from "../../../../stitches/typography/HeadingStyles";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { Button } from "../../../../stitches/button/Button";
import { TextBox } from "../../../../stitches/textbox/TextBox";
import UseWindowHook from "../../../../hooks/UseWindowHook";
import { WaitListSchema } from "../../resolvers/WaitList.resolver";
import { useNavigate } from "react-router-dom";

const BannerContainer = styled("div", {
  margin: "0 16px",
  width: "calc(100% -32px)",

  position: "relative",
  "@Mobile": {
    display: "flex",
    flexDirection: "column",
  },

  "@Tablet": {
    display: "flex",
    flexDirection: "column",
  },

  "@Desktop": {
    margin: "calc(152px - 46px) 16px 0 16px",
    display: "flex",
    columnGap: "80px",
    justifyContent: "space-evenly",
    paddingBottom: "234px",
  },
});

const BannerHeading = styled(Heading, {
  "@Desktop": {
    maxWidth: "701px",
    marginBottom: "40px !important",
  },
});

const BannerPunchLine = styled(Body, {
  marginTop: "16px",
});

const BannerPunchLineDesktop = styled(Heading, {
  marginTop: "16px",

  "@Desktop": {
    maxWidth: "678px",
  },
});

const StyledButton = styled(Button, {
  maxWidth: "212px",
  height: "65px",
  background: "linear-gradient(#3F98AB 0%, #4CBDD4 100%)",
  ...headings.heading_03,
  marginTop: "26px",

  "@Desktop": {
    marginTop: "40px",
  },
});

const ContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const StyledTextBoxContainer = styled("div", {
  marginTop: "46px",
  "@Desktop": {
    maxWidth: "524px",
  },
});

const StyledTextbox = styled(TextBox, {
  fontFamily: "Poppins",
});

const SplashShotBackground = styled("div", {
  background:
    "linear-gradient(180deg, rgba(74, 184, 207, 0.039) 0%, rgba(51, 156, 179, 0.1274) 100%)",
  borderRadius: "34px",

  "@Mobile": {
    width: "332px",
    height: "366px",
    right: "calc(-84px - 16px)",
    position: "absolute",
    bottom: "52px",
    zIndex: "-1",
  },

  "@Tablet": {
    width: "332px",
    height: "366px",
    right: "calc(-84px - 16px)",
    position: "absolute",
    bottom: "52px",
    zIndex: "-1",
  },

  "@Desktop": {
    width: "536px",
    height: "524px",
    columnGap: "80px",
  },
});

const SplashShot = styled("img", {
  position: "absolute",

  "@Mobile": {
    height: "452px",
    bottom: "0",
    right: "-16px",
  },

  "@Tablet": {
    height: "452px",
    bottom: "0",
    right: "-16px",
  },

  "@Desktop": {
    height: "706.09px",
    top: "-26px",
    right: "42px",
  },
});

const DesktopSplashShotContainer = styled("div", {
  position: "relative",

  "@Mobile": {
    height: "452px",
    width: "100%",
  },

  "@Tablet": {
    height: "452px",
    width: "100%",
  },
});

interface IBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Banner({ setIsLoading, ...props }: IBannerProps) {
  const { width } = UseWindowHook();

  const navigate = useNavigate();

  const isDesktopView: boolean = width >= 1200;

  const navigateToSignup = () => {
    navigate("/seller/signup");
  };

  interface WaitListForm {
    email: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<WaitListForm>({
    mode: "onSubmit",
    resolver: yupResolver(WaitListSchema),
  });

  const onSubmit = async (data: WaitListForm) => {
    try {
      await fetch(
        `https://docs.google.com/forms/d/e/1FAIpQLSeqLDlKN9zIlxWYu1GTPcIvsVvDKfEyVaQ-J7TjDUzmK9QeRQ/formResponse?entry.2040973235=${data.email}`,
        {
          method: "POST",
          mode: "no-cors",
        }
      );
      reset({ email: "" });
      toast.success("Thank you! We will reach out to you soon!", {
        theme: "colored",
      });
    } catch (error) {
      toast.error("Something went wrong, try again later", {
        theme: "colored",
      });
    }
  };

  return (
    <BannerContainer {...props}>
      <ContentContainer>
        <BannerHeading variant={isDesktopView ? "heading_07" : "heading_05"}>
          Aap ka apna online store, 2 min mein
        </BannerHeading>

        {isDesktopView ? (
          <BannerPunchLineDesktop variant="heading_03">
            Gahak App se aaj hee apna online store kholein, aur paein beshumaar
            features, aur krein apnay business ko powerup.
          </BannerPunchLineDesktop>
        ) : (
          <BannerPunchLine variant="body_01">
            Gahak App se aaj hee apna online store kholein, aur paein beshumaar
            features, aur krein apnay business ko powerup.
          </BannerPunchLine>
        )}
        <StyledButton onClick={navigateToSignup}>Get Started Now</StyledButton>
      </ContentContainer>
      <DesktopSplashShotContainer>
        <SplashShot src="/SplashShot.png" alt="SplashShot" />
        <SplashShotBackground />
      </DesktopSplashShotContainer>
    </BannerContainer>
  );
}
