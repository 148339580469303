import { styled } from "../stitches.config";

export const Display = styled("p", {
  fontFamily: "Poppins, sans-serif",

  variants: {
    variant: {
      display_sm_01: {
        fontSize: "42px",
        lineHeight: "50px",
        fontWeight: "$4",
      },
      display_sm_02: {
        fontSize: "42px",
        lineHeight: "50px",
        fontWeight: "$6",
      },
      display_sm_03: {
        fontSize: "42px",
        lineHeight: "50px",
        fontWeight: "$7",
      },
      display_md_01: {
        fontSize: "54px",
        lineHeight: "64px",
        fontWeight: "$4",
      },
      display_md_02: {
        fontSize: "54px",
        lineHeight: "64px",
        fontWeight: "$6",
      },
      display_md_03: {
        fontSize: "54px",
        lineHeight: "64px",
        fontWeight: "$7",
      },
      display_lg_01: {
        fontSize: "76px",
        lineHeight: "86px",
        fontWeight: "$4",
      },
      display_lg_02: {
        fontSize: "76px",
        lineHeight: "86px",
        fontWeight: "$6",
      },
      display_lg_03: {
        fontSize: "76px",
        lineHeight: "86px",
        fontWeight: "$7",
      },
      display_mx_01: {
        fontSize: "122px",
        lineHeight: "130px",
        fontWeight: "$3",
      },
      display_mx_02: {
        fontSize: "122px",
        lineHeight: "130px",
        fontWeight: "$5",
      },
      display_mx_03: {
        fontSize: "122px",
        lineHeight: "130px",
        fontWeight: "$7",
      },
    },
  },
});
