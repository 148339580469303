import * as React from "react";
import * as Toast from "@radix-ui/react-toast";
import { keyframes, styled } from "../stitches.config";

interface ToastProps extends Toast.ToastProps {
  children: React.ReactNode;
  viewport: React.ReactNode;
  swipeDirection?: Toast.SwipeDirection;
}

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateY(calc(-100% - ${25}px))` },
  to: { transform: "translateY(0)" },
});

const swipeOut = keyframes({
  from: { transform: "translateY(var(--radix-toast-swipe-end-x))" },
  to: { transform: `translateY(calc(-100% + ${25}px))` },
});

const Root = styled(Toast.Root, {
  '&[data-state="open"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${hide} 100ms ease-in`,
  },
  '&[data-swipe="move"]': {
    transform: "translateY(var(--radix-toast-swipe-move-x))",
  },
  '&[data-swipe="cancel"]': {
    transform: "translateY(0)",
    transition: "transform 200ms ease-out",
  },
  '&[data-swipe="end"]': {
    animation: `${swipeOut} 100ms ease-out`,
  },
});

const StyledToast = React.forwardRef<HTMLLIElement, ToastProps>(
  ({ children, swipeDirection, viewport, ...props }, ref) => {
    return (
      <Toast.Provider swipeDirection={swipeDirection || "up"}>
        <Root {...props} ref={ref}>
          {children}
        </Root>
        {viewport}
      </Toast.Provider>
    );
  }
);

export default StyledToast;
