import { useStoreState } from "pullstate";
import React, {
  useState,
  MouseEventHandler,
  useContext,
  useEffect,
} from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import OrderGenerator from "../../../admin/Orders/generator/Order.generator";
import { CustomerStore, resetCart } from "../../../pullstate/Customer";
import { CustomerFields } from "../../../types/CustomerFields";
import DeliveryDetails from "../DeliveryDetails/DeliveryDetails";
import OrderReview from "../OrderReview/OrderReview";
import Payment from "../Payment/Payment";
import { ApiClientContext } from "../../../context/ApiClientContext";
import { NewOrderRequest } from "@gahakapp/client";
import { useDeviceDimensions } from "../../../hooks/useDevice";
import CheckoutDesktop from "./CheckoutDesktop";
import { handleError } from "../../../util/ErrorHandler";
import { loadCartFromLocalStorage } from "../../Cart/CartUtil";

export const useCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useContext(ApiClientContext);
  const navigate = useNavigate();
  const { storeName } = useParams();
  const { total, cartDisplayMap: cartItems } = useStoreState(
    CustomerStore,
    (s) => s.cart
  );
  const deliveryDetails = useStoreState(
    CustomerStore,
    (s) => s.checkout.deliveryDetails
  );
  const steps = CustomerStore.useState(({ checkout }) => checkout.steps);
  const activeStep = CustomerStore.useState(
    ({ checkout }) => checkout.activeStep
  );

  useEffect(() => {
    loadCartFromLocalStorage(storeName);
  }, []);

  useEffect(() => {
    if (cartItems.size === 0) {
      navigate("/" + storeName);
    }
  }, [cartItems, storeName, navigate]);

  const onDeliveryDetailsSubmit: SubmitHandler<Omit<CustomerFields, "id">> = (
    data
  ) => {
    CustomerStore.update((s) => {
      s.checkout.activeStep = 1;
      s.checkout.deliveryDetails = data;
    });
  };

  const onPaymentSubmit = () => {
    CustomerStore.update((s) => {
      s.checkout.activeStep = 2;
    });
  };

  const onSubmitOrder = async () => {
    setIsLoading(true);
    const orderId = uuid();
    const orderGenerator = new OrderGenerator();
    const order = orderGenerator.generate(
      cartItems,
      total,
      orderId,
      deliveryDetails
    );

    const orderRequest: NewOrderRequest = {
      data: { ...order },
    };

    try {
      await apiClient.default.createOrder(storeName || "", orderRequest);
      resetCart(storeName || "");
      navigate("/" + storeName + "/order_confirmed");
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const onStepperClick = (
    idx: number
  ): MouseEventHandler<HTMLDivElement> | undefined => {
    if (idx < activeStep) {
      CustomerStore.update((s) => {
        s.checkout.activeStep = idx;
      });
    }
    return;
  };

  const navigateOneStepBack = () => {
    CustomerStore.update((s) => {
      s.checkout.activeStep -= 1;
    });
  };

  const { isDesktop } = useDeviceDimensions();

  const PageToBeRendered = () => {
    if (isDesktop) {
      return (
        <CheckoutDesktop
          onSubmitOrder={onSubmitOrder}
          navigateBackToDeliveryDetails={navigateOneStepBack}
          handleOnClick={onPaymentSubmit}
        ></CheckoutDesktop>
      );
    } else {
      switch (activeStep) {
        case 0:
          return <DeliveryDetails onSubmit={onDeliveryDetailsSubmit} />;
        case 1:
          return (
            <Payment
              navigateBackToDeliveryDetails={navigateOneStepBack}
              handleOnClick={onPaymentSubmit}
            />
          );
        case 2:
          return (
            <OrderReview
              navigateBackToPayment={navigateOneStepBack}
              handleOnClick={onSubmitOrder}
            />
          );
      }
    }
  };

  const navigateToStoreView = () => {
    navigate(`/${storeName}`);
  };

  return {
    activeStep,
    steps,
    isLoading,
    onStepperClick,
    PageToBeRendered,
    navigateToStoreView,
  };
};
