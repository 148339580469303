import { styled } from "../stitches/stitches.config";
import { Body } from "../stitches/typography/BodyStyles";

const Container = styled("div", {
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface IEmptyState {
  message: string;
}

export default function EmptyState({ message }: IEmptyState) {
  return (
    <Container>
      <Body>{message}</Body>
    </Container>
  );
}
