import { useStoreState } from "pullstate";
import { styled } from "../../../stitches/stitches.config";
import { Display } from "../../../stitches/typography/DisplayStyles";
import Menu from "../../Menu/Menu";
import { AdminStore } from "../../../pullstate/Admin";
import Product from "./Product";
import { Button } from "../../../stitches/button/Button";
import { Plus } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  margin: "auto",
  height: "100vh",
  padding: "32px",
  boxSizing: "border-box",
  columnGap: "32px",
});

const MenuContainer = styled("div", {});

const ContentContainer = styled("div", {
  borderRadius: "10px",
  border: "3px solid #F0F0F0",
  padding: "30px 26px",
  width: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
});

const DashboardHeading = styled(Display, {
  color: "$secondary_60",
  margin: "0",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "0 0 45px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
  justifyContent: "space-between",
});

const ProductsContainer = styled("div", {
  display: "grid",
  columnGap: "16px",
  rowGap: "$space$space_16",
  placeItems: "center center",
  gridTemplateColumns: "repeat(auto-fill, minmax(165px, 1fr))",
});

export default function DesktopProducts() {
  const products = useStoreState(AdminStore, (s) => s.products);
  const navigate = useNavigate();
  const { storeName } = useParams();
  const navigateToAddProduct = () => {
    navigate(`/${storeName}/seller/product/new`);
  };

  return (
    <MainContainer>
      <MenuContainer>
        <Menu />
      </MenuContainer>
      <ContentContainer>
        <HeaderContainer>
          <DashboardHeading variant="display_sm_01">Products</DashboardHeading>
          <Button
            variant="secondary"
            size="small"
            withIcon={true}
            onClick={navigateToAddProduct}
          >
            <Plus size={24} />
            Add Product
          </Button>
        </HeaderContainer>

        {products.length === 0 ? (
          <EmptyState message="No products added yet" />
        ) : (
          <ProductsContainer>
            {products.map((product, idx) => (
              <Product product={product} key={idx} />
            ))}
          </ProductsContainer>
        )}
      </ContentContainer>
    </MainContainer>
  );
}
