import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Body } from "../../../stitches/typography/BodyStyles";
import StyledToast from "../../../stitches/toast/Toast";
import { Product, Variant } from "../../../types/product/new/product.model";
import * as Toast from "@radix-ui/react-toast";
import { useProductCartToast } from "./useProductCartToast";
const ThumbnailContainer = styled("div", {
  height: "80px",
  width: "65px",
  flexGrow: 1,
  flexShrink: 0,
  border: "20px solid $grey_60",
});

const Thumbnail = styled("img", {
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "$space$space_10",
  border: "1px solid $grey_60",
});

const Layout = styled("div", {
  display: "flex",
  width: "100%",
  height: "111px",
  alignItems: "center",
  gap: "$space$space_10",
  borderRadius: "$space$space_10",
  padding: "0 $space$space_16",
  background: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(10px)",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
});

const TextContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  flexShrink: 1,
  flexGrow: 0,
});

const NamePriceContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const Price = styled(Heading, {
  color: "$secondary_60",
});

const StyledViewport = styled(Toast.Viewport, {
  position: "fixed",
  top: "80px",
  width: "calc(100% - 2*$space$space_16)",
  margin: "0 $space$space_16",
  listStyle: "none",
  zIndex: 1,
  padding: 0,
});

interface ProductCartToastProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: Product;
  variant: Variant;
  selectedQuantity: number;
}

export default function ProductCartToast({
  open,
  setOpen,
  product,
  variant,
  selectedQuantity,
}: ProductCartToastProps) {
  const { ref } = useProductCartToast(setOpen);

  return (
    <StyledToast
      swipeDirection="up"
      open={open}
      onOpenChange={setOpen}
      duration={5000}
      viewport={<StyledViewport />}
      ref={ref}
    >
      <Layout>
        <ThumbnailContainer>
          <Thumbnail src={product.images[0].url} />
        </ThumbnailContainer>
        <TextContainer>
          <NamePriceContainer>
            <Heading variant="heading_02">{product.name}</Heading>
            <Price variant="heading_02">
              {`Rs. ${variant?.price || product.variants[0].price}`}
            </Price>
          </NamePriceContainer>
          {product.hasVariants && (
            <Body variant="body_01">
              {variant?.attributes[0].value ||
                product.variants[0].attributes[0].value}
            </Body>
          )}
          <Body variant="body_01">Qty : {selectedQuantity}</Body>
        </TextContainer>
      </Layout>
    </StyledToast>
  );
}
