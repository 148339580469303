import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import UseAuth from "../../hooks/UseAuth";
import Spinner from "../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { Profile, FullName, FormValue } from "../../types/EditProfile";
import { ApiClientContext } from "../../context/ApiClientContext";
import { handleError } from "../../util/ErrorHandler";

export default function EditProfile() {
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [editing, setEditing] = useState<keyof FullName | null>(null);
  const { user } = UseAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { storeName } = useParams();
  const apiClient = useContext(ApiClientContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    unregister,
  } = useForm<FormValue>();

  useEffect(() => {
    // Fetch the seller profile data from the backend
    const fetchProfile = async () => {
      try {
        const profile = await apiClient.default.getProfile(
          storeName || "",
          user!.uid
        );
        setProfile(profile);
        setIsLoading(false);
      } catch (error: any) {
        handleError(error);
        setIsLoading(false);
      }
    };
    user && fetchProfile();
  }, [user]);

  const onSubmit = async ({ value }: FormValue, fieldName: keyof FullName) => {
    try {
      setIsLoading(true);
      setEditing(null);
      const data: { [field in "firstName" | "lastName"]?: string } = {
        [fieldName]: value,
      };

      await apiClient.default.updateProfile(storeName || "", user!.uid, {
        data,
      });

      setProfile((prevProfile) => {
        if (prevProfile)
          return {
            ...prevProfile,
            [fieldName]: value,
          };
        return prevProfile;
      });
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const onEdit = (field: keyof FullName) => {
    const values = getValues();
    if (values) unregister("value");
    setEditing(field);
  };

  const onCancel = () => {
    unregister("value");
    setEditing(null);
  };

  const renderField = (field: keyof FullName) => {
    if (!profile || isLoading) {
      return <Spinner />;
    }

    if (editing === field) {
      return (
        <div>
          <h3>{field}</h3>
          <form onSubmit={handleSubmit((data) => onSubmit(data, field))}>
            <input
              type="text"
              defaultValue={profile[field]}
              {...register("value", {
                required: "Cannot be empty!",
              })}
            />
            {errors.value && (
              <p className="Signup-error-message">{errors.value.message}</p>
            )}
            <button type="submit">Save</button>
            <button type="button" onClick={onCancel}>
              Cancel
            </button>
          </form>
        </div>
      );
    } else {
      return (
        <div>
          <h3>{field}</h3>
          <p onClick={() => onEdit(field)}>{profile[field]}</p>
        </div>
      );
    }
  };

  return (
    <div>
      <h2>Edit Profile</h2>
      <>
        {renderField("firstName")}
        {renderField("lastName")}
      </>
    </div>
  );
}
