import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IdentifiersForm,
  IdentifiersFormData,
} from "./Children/IdentifiersForm";
import {
  VerificationType,
  VerificationTypeSelection,
} from "./Children/VerificationTypeSelection";
import { VerifyCodeForm, VerifyCodeFormData } from "./Children/VerifyCodeForm";
import {
  NewPasswordForm,
  NewPasswordFormData,
} from "./Children/NewPasswordForm";
import { ApiClientContext } from "../../context/ApiClientContext";
import { CodeVerification } from "@gahakapp/client";
import { handleError } from "../../util/ErrorHandler";
import Spinner from "../../components/Spinner/Spinner";

export default function ResetPassword() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState<string>("");
  const [verificationType, setVerificationType] = useState<string>("");
  const navigate = useNavigate();
  const apiClient = useContext(ApiClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmitIdentifiers = async (data: IdentifiersFormData) => {
    try {
      setIsLoading(true);

      const credentialsExist = await apiClient.default.doCredentialsExist(
        data.email
      );

      if (credentialsExist) {
        setEmail(data.email);
        setStep(2);
      } else {
        handleError("There is no account associated with this email.");
      }
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const onVerificationTypeSelection = async (
    verificationTypeData: VerificationType
  ) => {
    try {
      setIsLoading(true);
      setVerificationType(verificationTypeData);
      const data = {
        email,
      };

      await apiClient.default.initiateVerification({ data });
      setStep(3);
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const onSubmitVerificationCode = async (
    verificationData: VerifyCodeFormData
  ) => {
    try {
      setIsLoading(true);
      const data: CodeVerification = {
        email,
        code: { email: verificationData.code },
      };
      await apiClient.default.verifyCode({ data });
      setStep(4);
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const onSubmitNewPassword = async (passwordData: NewPasswordFormData) => {
    try {
      setIsLoading(true);
      const data = {
        password: passwordData.password,
        email,
      };

      await apiClient.default.createNewPassword({ data });
      navigate("/seller/signin");
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const navigateToSignIn = () => {
    navigate(`/seller/signin`);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      <button onClick={navigateToSignIn}>Go to Sign In</button>
      <h2>Reset Password</h2>
      {step === 1 && <IdentifiersForm onSubmit={onSubmitIdentifiers} />}
      {step === 2 && (
        <VerificationTypeSelection
          onVerificationTypeSelection={onVerificationTypeSelection}
        />
      )}
      {step === 3 && (
        <VerifyCodeForm
          onSubmit={onSubmitVerificationCode}
          verificationType={verificationType}
        />
      )}
      {step === 4 && <NewPasswordForm onSubmit={onSubmitNewPassword} />}
    </div>
  );
}
