import { styled } from "../stitches.config";

export const bodyStyles = {
  body_compact_01: {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "$4",
    letterSpacing: "0.16px",
    fontFamily: "Poppins, sans-serif",
  },
  body_compact_02: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "$4",
    letterSpacing: "0.16px",
    fontFamily: "Poppins, sans-serif",
  },
  body_mini_01: {
    fontSize: "9px",
    lineHeight: "16px",
    fontWeight: "$4",
    letterSpacing: "0.16px",
    fontFamily: "Poppins, sans-serif",
  },
  body_mini_02: {
    fontSize: "10px",
    lineHeight: "16px",
    fontWeight: "$4",
    letterSpacing: "0.16px",
    fontFamily: "Poppins, sans-serif",
  },
  body_mini_03: {
    fontSize: "11px",
    lineHeight: "16px",
    fontWeight: "$4",
    letterSpacing: "0.16px",
    fontFamily: "Poppins, sans-serif",
  },
  body_01: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "$4",
    fontFamily: "Poppins, sans-serif",
  },
  body_02: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "$4",
    fontFamily: "Poppins, sans-serif",
  },
};

export const Body = styled("p", {
  margin: 0,
  variants: {
    variant: {
      ...bodyStyles,
    },
  },
});
