import React, { useState } from "react";
import { styled } from "@stitches/react";
import { DollarSign, Folder } from "react-feather";
import { PaymentType } from "./Payment";

interface SelectPaymentTypeProps {
  paymentTypes: string[];
}

const StyledCard = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$space$space_16",
  padding: "$space$space_20 $space$space_10",
  margin: "$space$space_10 $space$space_16",
  height: "64px",
  boxShadow: "0px 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
  cursor: "pointer",

  "@media (min-width: 1200px)": {
    margin: "$space$space_10 $space$space_0",
  }
});

const StyledRadioInput = styled("input", {
  position: "absolute",
  opacity: 0,
  width: 0,
  height: 0,
  "& + span": {
    position: "relative",
    display: "inline-block",
    width: "$space$space_16",
    height: "$space$space_16",
    borderRadius: "100%",
    border: "1px solid grey",
    cursor: "pointer",
    "&::after": {
      content: "",
      position: "absolute",
      top: "1.5px",
      left: "2px",
      width: "11px",
      height: "11px",
      borderRadius: "100%",
      backgroundColor: "$primary_80",
    },
  },
  "&:checked + span::after": {
    opacity: 1,
  },
});


const CODIcon = styled(DollarSign, {
  color: "$secondary_60",
});

const CreditCardIcon = styled(Folder, {
  color: "$secondary_60",
});

const SelectPaymentType: React.FC<SelectPaymentTypeProps> = ({
  paymentTypes,
}) => {
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<string>("Cash on Delivery");

  const handleCardClick = (paymentType: string) => {
    setSelectedPaymentType(paymentType);
  };

  return (
    <>
      {paymentTypes.map((paymentType) => {
        const isSelected = selectedPaymentType === paymentType;
        return (
          <StyledCard
            key={paymentType}
            onClick={() => handleCardClick(paymentType)}
          >
              <StyledRadioInput type="radio" checked={isSelected}/>
              <span></span>
            {paymentType === PaymentType.CASH_ON_DELIVERY ? (
              <CODIcon />
            ) : (
              <CreditCardIcon />
            )}
            <p>{paymentType}</p>
          </StyledCard>
        );
      })}
    </>
  );
};

export default SelectPaymentType;
