import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ProductFields } from "../../../types/ProductFields";
import { productValidationSchema } from "../resolver/Product.resolver";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiClientContext } from "../../../context/ApiClientContext";
import UseAuth from "../../../hooks/UseAuth";
import { compressImagesWithThumbnail } from "../../../util/CompressImage";
import { handleError } from "../../../util/ErrorHandler";
import {
  getAwsS3ImageUrls,
  getAwsS3ImageWithThumbnails,
} from "../../../util/s3Images.util";
import { Image } from "../../../types/product/new/product.model";
import Uppy from "@uppy/core";
import { AdminStore } from "../../../pullstate/Admin";
import { Product } from "@gahakapp/client";
import { useMutation, useQueryClient } from "react-query";
import { ProductQueryKeys } from "./ProductQuery.interface";

type FormattedDataType = Omit<ProductFields, "images" | "sizeChart"> & {
  images: Image[];
  sizeChart?: Image | undefined;
};

export const useProductContainer = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    unregister,
  } = useForm<ProductFields>({
    mode: "onSubmit",
    resolver: yupResolver(productValidationSchema),
    defaultValues: {
      hasVariants: false,
    },
  });
  const queryClient = useQueryClient();
  const { user } = UseAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const { storeName } = useParams();
  const apiClient = useContext(ApiClientContext);
  const watcher = watch([
    "variants",
    "description",
    "name",
    "images",
    "hasVariants",
  ]);

  const createProduct = async (data: FormattedDataType): Promise<Product> => {
    return apiClient.default.createProduct(storeName!, user!.uid, data);
  };

  const mutation = useMutation<
    Product,
    Error,
    FormattedDataType,
    { previousProducts: Product[] | undefined }
  >(createProduct, {
    onMutate: (newProduct) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(ProductQueryKeys.ADMIN_PRODUCTS);

      // Snapshot the previous value
      const previousProducts = queryClient.getQueryData<Product[]>([
        ProductQueryKeys.ADMIN_PRODUCTS,
        { storeName: storeName! },
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ProductQueryKeys.ADMIN_PRODUCTS,
        (old: Product[] | undefined) => {
          const newProductData: Product = {
            ...newProduct,
            id: "temporaryId",
            isDeleted: false,
            variants: newProduct.variants.map((variant) => ({
              ...variant,
              id: "temporaryId",
              isDeleted: false,
            })),
          };

          navigate("/" + storeName + "/seller/products");
          if (!old) return [newProductData];
          return [...old, newProductData];
        }
      );

      // Return a context object with the snapshotted value
      return { previousProducts };
    },
    onError: (_, __, context) => {
      // Roll back to the previous value if the mutation fails
      if (context) {
        queryClient.setQueryData(
          ProductQueryKeys.ADMIN_PRODUCTS,
          context.previousProducts
        );
      }
    },
    onSettled: () => {
      // Invalidate and refetch something when the mutation is completed
      queryClient.invalidateQueries(ProductQueryKeys.ADMIN_PRODUCTS);
    },
    onSuccess: (data) => {
      AdminStore.update((s) => {
        s.products = [...s.products, data];
      });
    },
  });

  const handleSizeChartUpload = (image: File | null) => {
    setValue("sizeChart", image ?? undefined);
  };

  const setHasVariantToTrue = () => {
    setValue("hasVariants", true);
  };

  const handleBackClick = () => {
    setIsExitModalOpen(true);
  };

  const handleDialogClose = () => {
    setIsExitModalOpen(false);
  };

  const handleExitDialog = () => {
    navigate("/" + storeName + "/seller/products");
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const [variants, description, name, images, hasVariants] = watcher;
    const isBaseEmpty = !description || !name || !!!images.length;
    if (hasVariants) {
      const isVariantEmpty = variants?.some(
        (variant) =>
          !variant.attributes ||
          !variant.attributes[0] ||
          !variant.attributes[0].value ||
          !variant.price ||
          !variant.quantity
      );
      setIsButtonDisabled(isBaseEmpty || isVariantEmpty);
    }

    if (!hasVariants) {
      const isVariantEmpty = variants?.some(
        (variant) => !variant.price || !variant.quantity
      );
      setIsButtonDisabled(isBaseEmpty || isVariantEmpty);
    }
  }, [watcher]);

  const uppyRef = useRef(new Uppy());

  useEffect(() => {
    register("images", { value: new DataTransfer().files });
  }, []);

  uppyRef.current.on("files-added", (file) => {
    if (file.length > 0) {
      handleImages(uppyRef.current.getFiles());
    } else {
    }
  });

  uppyRef.current.on("file-removed", (file) => {
    handleImages(uppyRef.current.getFiles());
  });

  const handleImages = (uppyFiles: any[]) => {
    if (uppyFiles.length > 0) {
      if (errors.images) {
        clearErrors("images");
      }
    } else {
      setError("images", {
        type: "custom",
        message: "Please enter Product Images",
      });
    }
    const dt = new DataTransfer();
    uppyFiles.forEach((uppyFile: any) => {
      dt.items.add(uppyFile.data);
    });
    setValue("images", dt.files);
  };

  const onSubmit = async (data: ProductFields) => {
    setIsLoading(true);

    const formattedData: Omit<ProductFields, "images" | "sizeChart"> & {
      images: Image[];
      sizeChart?: Image;
    } = {
      name: data.name,
      description: data.description,
      hasVariants: data.hasVariants,
      images: [],
      attributes: [],
      variants: [],
    };

    if (!data.hasVariants) {
      const variants = data.variants.map((variant) => {
        return {
          ...variant,
          attributes: [],
        };
      });

      formattedData.variants = variants;
    } else {
      const productAttributeValues: string[] = [];

      let hasDuplicate = false;
      const uniqueVariants = data.variants.filter((variant, idx) => {
        variant.attributes.filter((attribute) => {
          if (productAttributeValues.includes(attribute.value)) {
            setError(`variants.${idx}.attributes.${0}.value`, {
              type: "custom",
              message: "Duplicate value",
            });
            hasDuplicate = true;
            return false;
          }
          productAttributeValues.push(attribute.value);
        });
        return true;
      });

      if (hasDuplicate) {
        setIsLoading(false);
        return;
      }

      const variants = uniqueVariants.map((variant) => {
        const variantAttributes = variant.attributes.map((attribute) => {
          return {
            ...attribute,
            name: data.attributes[0].name,
          };
        });

        return {
          ...variant,
          attributes: variantAttributes,
        };
      });

      formattedData.variants = variants;

      const productAttributes = [
        { name: data.attributes.at(0)!.name, value: productAttributeValues },
      ];
      formattedData.attributes = productAttributes;
    }

    const compressedImages = await compressImagesWithThumbnail(data.images);
    const images: Image[] = await getAwsS3ImageWithThumbnails(
      storeName!,
      compressedImages
    );
    if (images.length !== data.images.length) {
      handleError(
        "Something went wrong with images addition. Please try again by re-adding the images."
      );
      setIsLoading(false);
      return;
    }
    formattedData.images = images;

    if (data.sizeChart) {
      const sizeChart = await getAwsS3ImageUrls(storeName!, [data.sizeChart]);
      if (sizeChart.length === 0) {
        handleError(
          "Something went wrong with size chart addition. Please try again by re-adding the size chart image."
        );
        setIsLoading(false);
        return;
      }
      formattedData.sizeChart = sizeChart[0];
    }

    try {
      setIsLoading(true);
      await mutation.mutateAsync(formattedData);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
    handleSubmit,
    register,
    unregister,
    watch,
    uppyRef,
    isButtonDisabled,
    setHasVariantToTrue,
    isLoading,
    handleBackClick,
    handleExitDialog,
    isExitModalOpen,
    handleDialogClose,
    handleSizeChartUpload,
  };
};
