import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { CustomerFields } from "../../../types/CustomerFields";
import { deliveryDetailsValidationSchema } from "./DeliveryDetails.resolver";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextBox } from "../../../stitches/textbox/TextBox";
import { CustomerStore } from "../../../pullstate/Customer";
import { styled } from "@stitches/react";
import DeliveryDetailsFooter from "./DeliveryDetailsFooter";

interface IDeliveryDetailsProps {
  onSubmit: SubmitHandler<Omit<CustomerFields, "id">>;
}

const Input = styled(TextBox, {
  width: "100%",
  marginBottom: "$space$space_16",
});

const Form = styled("form", {
  width: "100%",
  padding: "$space$space_16 $space$space_16 $space$space_0 $space$space_16",
  height: "calc(100% - 80px)",
  overflow: "auto",
});

export default function DeliveryDetails({ onSubmit }: IDeliveryDetailsProps) {
  const { firstName, lastName, email, phoneNumber, address } =
    CustomerStore.useState((s) => s.checkout.deliveryDetails);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<CustomerFields, "id">>({
    mode: "onChange",
    resolver: yupResolver(deliveryDetailsValidationSchema),
    defaultValues: {
      firstName,
      lastName,
      email,
      phoneNumber,
      address: {
        addressLine: address.addressLine,
        city: address.city,
        country: address.country,
      },
    },
  });

  return (
    <>
      <Form>
        <Input
          label="First Name"
          placeholder="First Name"
          {...register("firstName")}
          error={errors.firstName?.message}
        />
        <Input
          label="Last Name"
          placeholder="Last Name"
          {...register("lastName")}
          error={errors.lastName?.message}
        />
        <Input
          label="Email Address"
          placeholder="Email Address"
          {...register("email")}
          error={errors.email?.message}
        />
        <Input
          label="Phone Number"
          placeholder="For Example: 0342-2345678"
          {...register("phoneNumber")}
          error={errors.phoneNumber?.message}
        />
        <Input
          label="Address"
          placeholder="For Example: House#12, Street#1"
          {...register("address.addressLine")}
          error={errors.address?.addressLine?.message}
        />
        <Input
          label="City"
          placeholder="For Example: Karachi"
          {...register("address.city")}
          defaultValue={address.city}
          error={errors.address?.city?.message}
        />
        <Input
          label="Country"
          placeholder="For Example: Pakistan"
          {...register("address.country")}
          error={errors.address?.country?.message}
        />
      </Form>
      <DeliveryDetailsFooter
        navigateToPaymentSelection={handleSubmit(onSubmit)}
      />
    </>
  );
}
