import React, { useContext, useEffect, useState } from "react";
import { styled } from "../../../../stitches/stitches.config";
import IconBadge from "../../../../stitches/iconBadge/IconBadge";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerStore } from "../../../../pullstate/Customer";
import DesktopCart from "../../../Cart/DesktopCart";
import useCheckMobileScreen from "../../../../hooks/useDevice";
import { ApiClientContext } from "../../../../context/ApiClientContext";

type StoreHeaderProps = {
  showBackArrow?: boolean;
};

const Title = styled("p", {
  fontFamily: "Poppins, sans-serif",
  fontSize: "32px",
  lineHeight: "40px",
  fontWeight: "$8",
  fontStyle: "italic",
  margin: "$space$space_20 0 $space$space_20 0",

  "@Mobile": {
    fontSize: "26px",
  },
});

const StoreHeaderInner = styled("div", {
  width: "calc(100% - 32px)",
  margin: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const BackButtonAndStoreName = styled("div", {
  display: "flex",
});

export default function StoreHeader({
  showBackArrow = false,
}: StoreHeaderProps) {
  const apiClient = useContext(ApiClientContext);
  const content: number = CustomerStore.useState((s) => s.cart.numberOfItems);
  const navigate = useNavigate();
  const { storeName } = useParams();
  const isMobile = useCheckMobileScreen();
  const { storeDisplayName } = CustomerStore.useState((s) => s);

  const onCartClick = () => {
    CustomerStore.update((s) => {
      s.cart.isOpen = true;
    });
  };

  const showBackIcon = () => {
    return (
      showBackArrow && (
        <IconBadge
          onClick={() => {
            navigate(`/${storeName}`);
          }}
          iconName="ChevronLeft"
          size="medium"
          outlined={false}
          customCSS={{
            Icon: {
              color: "$black_100",
              marginLeft: "-8px",
            },
          }}
        />
      )
    );
  };

  return (
    <>
      <StoreHeaderInner>
        <BackButtonAndStoreName>
          {isMobile && showBackIcon()}
          <Title
            onClick={() => {
              navigate(`/${storeName}`);
            }}
          >
            {storeDisplayName}
          </Title>
        </BackButtonAndStoreName>
        <IconBadge
          onClick={onCartClick}
          iconName="ShoppingCart"
          size="medium"
          outlined={false}
          customCSS={{
            IconBadgeContainer: {
              margin: "$space$space_24 0 $space$space_16 0",
            },
            Icon: {
              color: "$black_100",
            },
          }}
          {...(content && { badgeValue: content })}
        />
      </StoreHeaderInner>
      <DesktopCart />
    </>
  );
}
