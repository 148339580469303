import { useDetectClickOutside } from "react-detect-click-outside";

export const useProductCartToast = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setOpen(false);
    },
    disableClick: true,
  });

  return { ref };
};
