import React from "react";
import { AdminStore } from "../../../../../pullstate/Admin";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";
import { styled } from "../../../../../stitches/stitches.config";
import IconBadge from "../../../../../stitches/iconBadge/IconBadge";
import { Box, Edit3, Save, Trash } from "react-feather";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import { useProductContainer } from "./useProductContainer";
import ProductVariants from "./ProductVariants";
import ProductImageContainer from "./ProductImageContainer";
import { Button } from "../../../../../stitches/button/Button";
import ProductExitDialog from "./ProductDeleteDialog";
import Spinner from "../../../../../components/Spinner/Spinner";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "auto",
});

const ProductsHeading = styled(Heading, {
  color: "$secondary_60",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "80px 0 32px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
  justifyContent: "space-between",
});

const ButtonsContainer = styled("div", {
  display: "flex",
  columnGap: "16px",
});

const IconAndHeadingContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const Form = styled("form", {
  width: "100%",
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const PersonalInfoHeaderIcon = styled(Box, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const DeleteButton = styled(Button, {
  backgroundColor: "$colors$danger_100 !important",
  width: "100%",

  "&:active": {
    backgroundColor: "$colors$danger_50 !important",
  },
});

const SaveButton = styled(Button, {
  width: "100%",
});

const Image = styled("img", {
  border: "1px solid $grey_50",
  borderRadius: "10px",
  maxWidth: "200px",
  maxHeight: "150px",
  objectFit: "cover",
});

export default function MobileProductContainer() {
  const {
    handleSubmit,
    onSubmit,
    register,
    unregister,
    uppyRef,
    watch,
    setHasVariantToTrue,
    isButtonDisabled,
    isLoading,
    handleBackClick,
    handleExitDialog,
    isDeleteModalOpen,
    handleConfirmDeletion,
    product,
    handleEditProduct,
    deleteProduct,
    handleDeleteProduct,
  } = useProductContainer();

  if (!product) {
    handleBackClick();
    return null;
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <IconAndHeadingContainer>
          <IconBadge
            iconName="ChevronLeft"
            outlined={false}
            size="large"
            customCSS={{
              Icon: {
                color: "secondary_60",
              },
            }}
            onClick={handleBackClick}
          />
          <ProductsHeading variant="heading_05">
            Product Details
          </ProductsHeading>
        </IconAndHeadingContainer>
      </HeaderContainer>
      <ButtonsContainer>
        <DeleteButton
          variant="secondary"
          size="small"
          withIcon="true"
          onClick={handleDeleteProduct}
          // disabled={isButtonDisabled}
        >
          <Trash size={24} />
          Delete
        </DeleteButton>
        <SaveButton
          variant="secondary"
          size="small"
          withIcon="true"
          onClick={handleEditProduct}
          // disabled={isButtonDisabled}
        >
          <Edit3 size={24} />
          Edit
        </SaveButton>
      </ButtonsContainer>

      {isLoading ? (
        <Spinner />
      ) : (
        <Form>
          <PersonalInfoHeadingContainer>
            <PersonalInfoHeaderIcon size={24} />
            <PersonalInfoHeaderHeading variant="body_02">
              Product Details
            </PersonalInfoHeaderHeading>
          </PersonalInfoHeadingContainer>
          <Input label="Name" disabled={true} value={product?.name} />
          <TextBox
            label="Description"
            placeholder="Enter a description for this product"
            disabled={true}
            value={product.description}
            isMultiLine={true}
          />
          {product.sizeChart && (
            <Image src={product.sizeChart?.url} alt="Uploaded Preview" />
          )}
          <ProductVariants
            register={register}
            unregister={unregister}
            watch={watch}
            setHasVariantToTrue={setHasVariantToTrue}
            hasVariants={product.hasVariants}
            variants={product.variants}
          />

          <ProductImageContainer uppyRef={uppyRef} images={product.images} />
        </Form>
      )}

      <ProductExitDialog
        isDialogOpen={isDeleteModalOpen}
        handleExitDialog={handleExitDialog}
        handleConfirmDeletion={handleConfirmDeletion}
      />
    </MainContainer>
  );
}
