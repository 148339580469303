import React, { useContext, useEffect, useState } from "react";
import {
  IOrderStatus,
  OrderOrigin,
  OrderWithReferenceId,
} from "../../../../types/OrderFields";
import { Product } from "../../../../types/product/new/product.model";
import UseAuth from "../../../../hooks/UseAuth";
import Spinner from "../../../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { AdminStore } from "../../../../pullstate/Admin";
import { ApiClientContext } from "../../../../context/ApiClientContext";
import { Button } from "../../../../stitches/button/Button";
import { styled } from "../../../../stitches/stitches.config";
import { Customer } from "@gahakapp/client";
import { handleError } from "../../../../util/ErrorHandler";
import { useDeviceDimensions } from "../../../../hooks/useDevice";
import DesktopOrderSummary from "./DesktopOrderSummary";
import MobileOrderSummary from "./MobileOrderSummary";

export type IOrder = {
  order: OrderWithReferenceId;
  navigateBack: () => void;
};

export type IOrderProduct = {
  product: Product | undefined;
  quantity: number;
};

export default function OrderSummary({
  order: initialOrder,
  navigateBack,
}: IOrder) {
  const { user } = UseAuth();
  const { storeName } = useParams();
  const [orderInfo, setOrderInfo] = useState<IOrderProduct[]>([]);
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [order, setOrder] = useState<OrderWithReferenceId>(initialOrder);
  const [isLoading, setIsLoading] = useState(true);
  const apiClient = useContext(ApiClientContext);

  const fetchOrderProducts = async () => {
    order.orderDetails.items.map(async (item) => {
      const matchedProduct = await apiClient.default.getProduct(
        storeName!,
        user!.uid,
        item.productID
      );

      let mappedProduct: IOrderProduct;
      if ("variants" in matchedProduct) {
        const matchedVariant = matchedProduct.variants.find(
          (variant) => variant.id === item.variantID
        );

        mappedProduct = matchedVariant
          ? {
              product: { ...matchedProduct, variants: [matchedVariant] },
              quantity: item.quantity,
            }
          : {
              product: { ...matchedProduct },
              quantity: item.quantity,
            };
      } else {
        mappedProduct = {
          product: { ...matchedProduct, variants: [matchedProduct.variant] },
          quantity: item.quantity,
        };
      }

      setOrderInfo((oldArray) => [...oldArray, mappedProduct]);
    });
  };

  const fetchCustomer = async () => {
    try {
      setIsLoading(true);
      const customerData = await apiClient.default.getCustomerDetails(
        storeName!,
        order.customerID
      );
      setCustomer(customerData);
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const changeOrderStatus = async (
    selectedOrderId: string,
    orderStatus: IOrderStatus
  ) => {
    try {
      setIsLoading(true);
      const updateStatusData = {
        id: selectedOrderId,
        status: orderStatus,
      };
      await apiClient.default.updateStatus(storeName || "", user!.uid, {
        data: updateStatusData,
      });

      AdminStore.update((state) => {
        const orderIndex = state.ordersStore.orders.findIndex(
          (orderFromArray) => selectedOrderId === orderFromArray.id
        );
        state.ordersStore.orders[orderIndex] = {
          ...state.ordersStore.orders[orderIndex],
          status: orderStatus,
        };
      });
      setOrder((prevOrder) => ({
        ...prevOrder,
        status: orderStatus,
      }));
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const deleteOrder = async (selectedOrder: OrderWithReferenceId) => {
    try {
      setIsLoading(true);

      await apiClient.default.deleteOrder(
        storeName || "",
        user!.uid,
        selectedOrder.id
      );

      AdminStore.update((state) => {
        state.ordersStore.orders = state.ordersStore.orders.filter(
          (prevOrder) => {
            return prevOrder.id !== selectedOrder.id;
          }
        );
      });
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (user) {
        await fetchOrderProducts();
        setIsLoading(false);
      }
    };
    fetch();
  }, [user]);

  const { isDesktop } = useDeviceDimensions();

  if (isLoading) {
    return <Spinner />;
  }

  return isDesktop ? (
    <DesktopOrderSummary
      order={order}
      navigateBack={navigateBack}
      orderInfo={orderInfo}
      changeOrderStatus={changeOrderStatus}
    />
  ) : (
    <MobileOrderSummary
      order={order}
      navigateBack={navigateBack}
      orderInfo={orderInfo}
      changeOrderStatus={changeOrderStatus}
    />
  );
}
