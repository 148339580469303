import { styled } from "../../../stitches/stitches.config";
import { bodyStyles } from "../../../stitches/typography/BodyStyles";

export const TextTop = styled("p", {
  margin: "0",
  ...bodyStyles.body_mini_03,
  color: "$white_100",
});

export const TextBottom = styled("p", {
  margin: "0",
  ...bodyStyles.body_mini_01,
  color: "$text_100",
});

export const FooterInner = styled("div", {
  backgroundColor: "$black_100",
  width: "100%",
  height: "74px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "$space$space_10",
  position: "absolute",
  bottom: "0",
});

export interface IFooterProps
  extends React.ComponentProps<typeof FooterInner> {}

export default function Footer({ ...props }: IFooterProps) {
  return (
    <FooterInner {...props}>
      <TextTop>Powered By GahakApp</TextTop>
      <TextBottom>Copyright © 2023, GahakApp. All Rights Reserved.</TextBottom>
    </FooterInner>
  );
}
