export type VerificationType = "email";

type VerificationTypeSelectionProps = {
  onVerificationTypeSelection: (type: VerificationType) => void;
};

export function VerificationTypeSelection({
  onVerificationTypeSelection,
}: VerificationTypeSelectionProps) {
  const handleEmailSelection = () => {
    onVerificationTypeSelection("email");
  };

  return (
    <div>
      <p>Select where to send the verification code</p>
      <button onClick={handleEmailSelection}>Email</button>
    </div>
  );
}
