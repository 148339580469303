import React from "react";
import { CartProduct } from "../../../../../types/CartFields";
import { useCartItem } from "./useCartItem";
import { styled } from "../../../../../stitches/stitches.config";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import CartRightPanel from "../../CartRightPanel";
import Counter, {
  CounterSize,
  CounterVariant,
} from "../../../../../components/Counter/Counter";

interface ICartItemProp {
  item: CartProduct;
}

const CartItemContainer = styled("div", {
  width: "100%",
});

const CartItemContent = styled("div", {
  width: "100%",
  height: "156px",
  boxSizing: "border-box",
  padding: "$space$space_16",
  display: "flex",
});

const CartItemImageContainer = styled("div", {
  height: "100%",
  borderRadius: "$space$space_10",
  paddingRight: "$space$space_16",
  boxSizing: "content-box",
  width: "94px",
});

const CartItemImage = styled("img", {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  border: "1px solid $grey_50",
  borderRadius: "$space$space_10",
});

const CartItemNameCounter = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "167px",
});

const CartItemName = styled(Heading, {
  marginBottom: "8px",
});

export default function CartItem({ item }: ICartItemProp) {
  const {
    name,
    variant: { price, attributes },
    images,
    hasVariants,
    changeCartItemQuantity,
    selectedQuantity,
  } = useCartItem(item);

  return (
    <CartItemContainer>
      <CartItemContent>
        <CartItemImageContainer>
          <CartItemImage src={images[0].url} />
        </CartItemImageContainer>
        <CartItemNameCounter>
          <CartItemName variant="heading_02">{name}</CartItemName>
          {hasVariants && (
            <Body variant="body_01">
              {attributes[0].name}: {attributes[0].value}
            </Body>
          )}
          <Counter
            variant={CounterVariant.CartPage}
            size={CounterSize.Small}
            availableQuantity={item.variant.quantity}
            selectedQuantity={item.selectedQuantity}
            changeItemQuantity={changeCartItemQuantity}
          />
        </CartItemNameCounter>
        <CartRightPanel
          price={price}
          quantity={selectedQuantity}
          removeCartItem={changeCartItemQuantity}
        />
      </CartItemContent>
    </CartItemContainer>
  );
}
