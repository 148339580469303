import { useContext, useEffect, useRef, useState } from "react";
import { Attribute } from "../../../types/product/new/product.model";
import {
  addProductToCart,
  loadCartFromLocalStorage,
} from "../../Cart/CartUtil";
import { CustomerStore } from "../../../pullstate/Customer";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { ApiClientContext } from "../../../context/ApiClientContext";
import { handleError } from "../../../util/ErrorHandler";
import { useStoreState } from "pullstate";

type CarouselImages = {
  original: string;
  thumbnail: string;
}[];

export const useProductFront = () => {
  const { selectedProduct: product, selectedVariant } =
    CustomerStore.useState();
  const { storeName, id: productId } = useParams();
  const variants = product?.variants ?? [];
  const attributes = product?.attributes ?? [];
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const navigate = useNavigate();
  const [productCartToastQuantity, setProductCartToastQuantity] =
    useState<number>(0);
  const apiClient = useContext(ApiClientContext);
  const carouselImages = useRef<CarouselImages>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const storeDisplayNameState = useStoreState(
    CustomerStore,
    (s) => s.storeDisplayName
  );
  const [storeFound, setStoreFound] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (storeDisplayNameState) {
      loadCartFromLocalStorage(storeName);
    }
  }, [storeDisplayNameState]);

  useEffect(() => {
    if (storeDisplayNameState) {
      if (product) {
        if (carouselImages.current.length === 0) {
          product.images.forEach((element) => {
            const carouselImage = {
              original: element.url,
              thumbnail: element.url,
              originalHeight: 272,
              thumbnailHeight: 75,
              thumbnailWidth: 75,
            };
            carouselImages.current.push(carouselImage);
          });
        }

        // if product is out of stock, set the default selected quantity to 0, else set it to 1
        const isVariantInStock: boolean = !!selectedVariant!.quantity;

        setSelectedQuantity(isVariantInStock ? 1 : 0);
      }
    }
  }, [product, storeDisplayNameState]);

  useEffect(() => {
    if (!storeDisplayNameState) {
      checkStoreExistence();
    } else {
      setStoreFound(true);
    }

    if (storeDisplayNameState) {
      if (product && selectedAttribute.length === attributes.length) {
        const foundVariant = variants.find((variant) => {
          const { attributes: variantAttribute } = variant;
          return _.isEqual(selectedAttribute, variantAttribute);
        });

        CustomerStore.update((s) => {
          s.selectedVariant = foundVariant || s.selectedProduct!.variants[0];
        });

        // Set default selected quantity of variant according to its availability
        if (foundVariant) {
          const isSelectedVariantInStock: boolean = !!foundVariant.quantity;
          setSelectedQuantity(isSelectedVariantInStock ? 1 : 0);
        } else {
          const isDefaultVariantInStock: boolean = !!variants[0].quantity;
          setSelectedQuantity(isDefaultVariantInStock ? 1 : 0);
        }
      }

      if (!product) {
        fetchProduct();
      }
    }
  }, [selectedAttribute, storeDisplayNameState]);

  const checkStoreExistence = async () => {
    try {
      setIsLoading(true);
      const storeDisplayName = await apiClient.default.getStore(
        storeName || ""
      );
      setStoreFound(!!storeDisplayName); // If storeExists is false, set storeNotFound to true
      CustomerStore.update((s) => {
        s.storeDisplayName = storeDisplayName || "";
      });
      if (!storeDisplayName) {
        setIsLoading(false);
      }
    } catch (error) {
      handleError(error);
      setIsLoading(false);
      setStoreFound(true); // Set storeNotFound to true in case of an error
    }
  };

  const fetchProduct = async () => {
    try {
      setIsLoading(true);
      const product = await apiClient.default.getCustomerProduct(
        storeName!,
        productId!
      );

      const selectedProduct =
        "variants" in product
          ? { ...product }
          : { ...product, variants: [product.variant] };

      // Update pullstate
      CustomerStore.update((s) => {
        // Add fetched product to pullstate
        s.selectedProduct = selectedProduct;
        s.products.push(selectedProduct);
        s.selectedVariant = selectedProduct.variants[0];
      });
      setIsLoading(false);
    } catch (error) {
      setStoreFound(false);
      setIsLoading(false);
    }
  };

  const handleOnAttributeClick = (
    attributeValue: string,
    attributeName: string
  ) => {
    setSelectedAttribute((prev) => {
      let currentAttributes = [...prev];
      const newAttribute = { name: attributeName, value: attributeValue };
      const doesAttributeExist = currentAttributes.findIndex((attribute) => {
        return attribute.name === attributeName;
      });
      doesAttributeExist === -1
        ? (currentAttributes = [...prev, newAttribute])
        : (currentAttributes[doesAttributeExist] = newAttribute);

      return currentAttributes;
    });
  };

  const addItemToCart = () => {
    addProductToCart(product!, selectedVariant!, selectedQuantity, storeName);

    const updatedAvailableQuantity =
      selectedVariant!.quantity - selectedQuantity;

    // Update pullstate
    CustomerStore.update((s) => {
      s.selectedVariant!.quantity = updatedAvailableQuantity;
    });

    // Update product cart toast quantity
    setProductCartToastQuantity(selectedQuantity);

    // Update selected quantity
    setSelectedQuantity(() => {
      if (updatedAvailableQuantity === 0) return 0;

      return 1;
    });

    setOpen(true);
  };

  const proceedToCheckout = () => {
    addItemToCart();
    navigate(`/${storeName}/checkout`);
  };

  return {
    product,
    attributes,
    variants,
    selectedAttribute,
    selectedVariant,
    handleOnAttributeClick,
    addItemToCart,
    proceedToCheckout,
    open,
    setOpen,
    selectedQuantity,
    setSelectedQuantity,
    productCartToastQuantity,
    carouselImages,
    isLoading,
    storeFound,
  };
};
