import { IOrderStatus } from "@gahakapp/client";
import { IAdminStore } from "../Admin";

export const adminStoreInitialState: IAdminStore = {
  selectedProduct: undefined,
  products: [],
  ordersStore: {
    filterSearchTerm: "",
    filteredOrders: [],
    orders: [],
    selectedOrder: undefined,
    selectedOrderOperation: undefined,
    selectedStatusFilter: undefined,
  },
  profile: {
    firstName: "",
    lastName: "",
    storeName: "",
    id: "",
  },
  isMenuOpen: false,
};
