import React from "react";
import { styled } from "../../../../stitches/stitches.config";

const NotFoundContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "calc(100vh - 94px)",
});

const NotFoundTitle = styled("h2", {
  fontFamily: "Poppins, sans-serif",
  fontSize: "32px",
  fontWeight: "bold",
  marginBottom: "$space_16",
});

const NotFoundMessage = styled("p", {
  fontSize: "$space_16",
});

const ResourceNotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundTitle>404: Resource Not Found</NotFoundTitle>
      <NotFoundMessage>
        The resource you are looking for does not exist.
      </NotFoundMessage>
    </NotFoundContainer>
  );
};

export default ResourceNotFound;
