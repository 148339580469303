import React, { useContext, useEffect, useState } from "react";
import UseAuth from "../../hooks/UseAuth";
import { IOrderStatus, OrderFields } from "../../types/OrderFields";
import Spinner from "../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { ApiClientContext } from "../../context/ApiClientContext";
import { handleError } from "../../util/ErrorHandler";
import { useAsync } from "react-use";
import { CustomerStore } from "../../pullstate/Customer";
import { ICardProps } from "../../components/Seller/Dashboard/Overview/Card";
import { useDeviceDimensions } from "../../hooks/useDevice";
import DesktopDashboard from "./Desktop/DesktopDashboard";
import MobileDashboard from "./Mobile/MobileDashboard";

const abbreviateNumber = (value: number): string => {
  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;

  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = Number(newValue.toPrecision(3));

  // Convert to string and remove trailing zeros and dot if not needed
  const valueString = newValue
    .toString()
    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1");

  return valueString + suffixes[suffixNum];
};

export default function Dashboard() {
  const { user } = UseAuth();
  const { storeName } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [statusMap, setStatusMap] = useState<Map<IOrderStatus, number>>(
    new Map()
  );
  const [completedOrderValue, setCompletedOrderValue] = useState<number>(0);
  const [totalCompletedOrders, setTotalCompletedOrders] = useState<number>(0);
  const apiClient = useContext(ApiClientContext);
  const { storeDisplayName } = CustomerStore.useState((s) => s);

  const fetchOrders = async () => {
    try {
      const orderData = await apiClient.default.getOrders(storeName || "");

      let tempOrderStatusMap = new Map<IOrderStatus, number>();
      Object.values(IOrderStatus).forEach((orderStatus, idx) => {
        tempOrderStatusMap.set(orderStatus, 0);
      });

      orderData.forEach((order: OrderFields, idx: number) => {
        tempOrderStatusMap.set(
          order.status,
          tempOrderStatusMap.get(order.status)! + 1
        );
        if (order.status === IOrderStatus.COMPLETED) {
          const completedOrderTotal: number = order.total;
          setCompletedOrderValue(
            (orderValue) => orderValue + completedOrderTotal
          );
          setTotalCompletedOrders(
            (totalCompletedOrders) => totalCompletedOrders + 1
          );
        }
      });
      setStatusMap(tempOrderStatusMap);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const { isDesktop } = useDeviceDimensions();

  useEffect(() => {
    if (user) {
      fetchOrders();
    }
  }, [user]);

  useAsync(async () => {
    if (!storeDisplayName) {
      const storeDisplayName = await apiClient.default.getStore(
        storeName || ""
      );
      CustomerStore.update((s) => {
        s.storeDisplayName = storeDisplayName;
      });
    }
  }, []);

  const overviewCards: ICardProps[] = [
    {
      iconName: "AlertCircle",
      iconColor: "$colors$caution_50",
      label: "In Progress",
      value: statusMap.get(IOrderStatus.IN_PROGRESS)!,
    },
    {
      iconName: "CheckCircle",
      iconColor: "$colors$success_50",
      label: "Completed",
      value: statusMap.get(IOrderStatus.COMPLETED)!,
    },
    {
      iconName: "BarChart2",
      iconColor: "$colors$secondary_60",
      label: "Sales",
      value: `Rs. ${abbreviateNumber(completedOrderValue)}`,
    },
    {
      iconName: "Package",
      iconColor: "$colors$secondary_60",
      label: "Avg Order Value",
      value:
        totalCompletedOrders === 0
          ? "Rs. 0"
          : `Rs. ${abbreviateNumber(
              completedOrderValue / totalCompletedOrders
            )}`,
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return isDesktop ? (
    <DesktopDashboard overviewCards={overviewCards} />
  ) : (
    <MobileDashboard overviewCards={overviewCards} />
  );
}
