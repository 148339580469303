import React from "react";
import { styled } from "../../stitches/stitches.config";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { Body } from "../../stitches/typography/BodyStyles";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "calc(100vh - 47.99px)",
  textAlign: "center",
});

const EmptyCartImage = styled("img", {
  marginTop: "139px",
  marginBottom: "16px",
  height: "80px",
  width: "80px",
});

const EmptyCartHeading = styled(Heading, {
  color: "$secondary_80",
  marginBottom: "6px",
});

const EmptyCartBody = styled(Body, {
  maxWidth: "349px",
});

export default function EmptyCart() {
  return (
    <Container>
      <EmptyCartImage src="/emptyCart.png" alt="empty cart" />
      <EmptyCartHeading variant="heading_04">
        Your cart is empty
      </EmptyCartHeading>
      <EmptyCartBody variant="body_02">
        Browse the catalogue and add products to your cart now
      </EmptyCartBody>
    </Container>
  );
}
