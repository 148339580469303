import { styled } from "../../stitches/stitches.config";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { CounterSize } from "./Counter";

export const CounterButtonContainer = styled("button", {
  borderStyle: "solid",
  borderColor: "$secondary_80",

  backgroundColor: "$white_100",
  "& > *": {
    color: "$text_80",
  },

  "@Desktop": {
    "&:hover": {
      "& > *": {
        color: "$secondary_40",
      },
    },
  },

  "&:active": {
    backgroundColor: "$secondary_60",
    "& > *": {
      color: "$white_100",
    },
  },
  "&:disabled": {
    backgroundColor: "$grey_50",
    "& > *": {
      color: "$text_100",
    },
  },

  variants: {
    size: {
      medium: {
        width: "50px",
        height: "40px",
      },
      small: {
        width: "42px",
        height: "32px",
      },
    },
    variant: {
      increment: {
        borderRadius:
          "$space$space_0 $space$space_10 $space$space_10 $space$space_0",
        borderWidth: "2px 2px 2px $space$space_0",
      },
      decrement: {
        borderRadius:
          "$space$space_10 $space$space_0 $space$space_0 $space$space_10",
        borderWidth: "2px $space$space_0 2px 2px",
      },
    },
  },
});

export enum CounterActions {
  DECREMENT = "decrement",
  INCREMENT = "increment",
}

export interface ICounterButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: CounterSize;
  variant: CounterActions;
  disabled?: boolean;
}

export default function CounterButton({
  size,
  variant,
  disabled,
  ...props
}: ICounterButtonProps) {
  const counterButtonText: string =
    variant === CounterActions.INCREMENT ? "+" : "-";

  return (
    <CounterButtonContainer
      disabled={disabled}
      {...props}
      size={size}
      variant={variant}
    >
      <Heading variant="heading_01">{counterButtonText}</Heading>
    </CounterButtonContainer>
  );
}
