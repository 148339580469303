import { Customer } from "@gahakapp/client";
import { styled } from "../../../../stitches/stitches.config";
import { IOrderProduct } from "./OrderSummary";
import OrderHeader from "../OrderHeader/OrderHeader";
import OrderTotal from "../OrderTotal/OrderTotal";
import OrderItems from "../OrderItems/OrderItems";
import "overlayscrollbars/overlayscrollbars.css";
import {
  IOrderStatus,
  OrderOrigin,
  OrderWithReferenceId,
} from "../../../../types/OrderFields";
import { Button } from "../../../../stitches/button/Button";
import OrderStatusButton from "../OrderStatus/OrderStatusButton";
import { useState } from "react";
import ItemsCard from "./ItemsCard";
import CustomerInfoCard from "../CustomerInformation/CustomerInformation";
import CustomerAddressCard from "../CustomerAddress/CustomerAddress";
import PaymentTypeCard from "../PaymentTypeCard/PaymentTypeCard";

interface IMobileOrderSummaryProps {
  order: OrderWithReferenceId;
  navigateBack: () => void;
  orderInfo: IOrderProduct[];
  changeOrderStatus: (
    selectedOrderId: string,
    orderStatus: IOrderStatus
  ) => Promise<void>;
}

const OrderStatusButtonContainer = styled("div", {
  position: "sticky",
  top: 0,
  zIndex: 1, // Adjust the z-index as needed
  background: "white", // Add background color if needed
  // Add other styles as needed
});

export default function MobileOrderSummary({
  order,
  navigateBack,
  orderInfo,
  changeOrderStatus,
}: IMobileOrderSummaryProps) {
  const [updatedOrderStatus, setUpdatedOrderStatus] = useState<IOrderStatus>(
    order.status
  );

  const address = order.orderDetails.address;

  const toggleOrderStatus = () => {
    const newStatus =
      updatedOrderStatus === IOrderStatus.COMPLETED
        ? IOrderStatus.IN_PROGRESS
        : IOrderStatus.COMPLETED;
    setUpdatedOrderStatus(newStatus);
    // Make a call to update the order status
    changeOrderStatus(order.id, newStatus);
  };

  return (
    <>
      <OrderStatusButtonContainer>
        <OrderHeader order={order} navigateBack={navigateBack} />
        <OrderStatusButton status={order.status} state={"display"} />
      </OrderStatusButtonContainer>
      <CustomerInfoCard order={order} />
      <CustomerAddressCard address={address}></CustomerAddressCard>
      <PaymentTypeCard></PaymentTypeCard>
      <ItemsCard
        items={orderInfo}
        orderTotal={order.total}
        orderStatus={order.status}
        orderId={order.id}
        changeOrderStatus={changeOrderStatus}
      ></ItemsCard>
    </>
  );
}
