import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Body } from "../../../stitches/typography/BodyStyles";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
  gap: "$space_space10",
});

const Subtotal = styled(Heading, {
  color: "$secondary_60",
});

const Price = styled(Heading, {
  color: "$text_100",
});

const Quantity = styled(Body, {
  padding: "$space$space_10",
  paddingLeft: "0px",
  borderRight: "2px dashed $text_100",
  color: "$text_80",
  letterSpacing: "0.16px",
  height: "49px",
  display: "flex",
  alignItems: "center",
});

interface ICartSubtotal {
  price: number;
  quantity: number;
}

export default function CartSubtotal({ price, quantity }: ICartSubtotal) {
  return (
    <Container>
      <Price variant="heading_02">Rs. {price}</Price>
      <Quantity variant="body_mini_03">x {quantity}</Quantity>
      <Subtotal variant="heading_02">Rs. {price * quantity}</Subtotal>
    </Container>
  );
}
