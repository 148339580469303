import { styled } from "../stitches.config";

export const headings = {
  heading_01: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "$6",
    letterSpacing: "0.16px",
    fontFamily: "Poppins, sans-serif",
  },
  heading_02: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "$6",
    fontFamily: "Poppins, sans-serif",
  },
  heading_03: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "$4",
    fontFamily: "Poppins, sans-serif",
  },
  heading_04: {
    fontSize: "28px",
    lineHeight: "36px",
    fontWeight: "$4",
    fontFamily: "Poppins, sans-serif",
  },
  heading_05: {
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: "$3",
    fontFamily: "Poppins, sans-serif",
  },
  heading_06: {
    fontSize: "42px",
    lineHeight: "50px",
    fontWeight: "$3",
    fontFamily: "Poppins, sans-serif",
  },
  heading_07: {
    fontSize: "54px",
    lineHeight: "64px",
    fontWeight: "$3",
    fontFamily: "Poppins, sans-serif",
  },
};

export const Heading = styled("p", {
  margin: 0,
  variants: {
    variant: {
      ...headings,
    },
  },
});
