import { toast } from "react-toastify";

export const handleError = (error: any): string | undefined => {
  if (typeof error === "string") {
    toast.error(error, {
      theme: "colored",
    });
    return;
  } else if (!error.body) {
    toast.error("Something went wrong, try again later", {
      theme: "colored",
    });
    return;
  }
  const { message } = error.body;
  toast.error(message, {
    theme: "colored",
  });
};
