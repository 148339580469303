import React, { useContext, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { SignupFields, SignupAttrType } from "../../types/SignupFields";
import Spinner from "../../components/Spinner/Spinner";
import "./Signup.css";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import { ApiClientContext } from "../../context/ApiClientContext";
import { handleError } from "../../util/ErrorHandler";
import { styled } from "@stitches/react";
import { Body } from "../../stitches/typography/BodyStyles";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { Button } from "../../stitches/button/Button";
import { useDeviceDimensions } from "../../hooks/useDevice";
import { TextBox, TextBoxIconPosition } from "../../stitches/textbox/TextBox";
import { EyeOff, Icon, Eye } from "react-feather";
import { useNavigate } from "react-router-dom";

const ImageContainer = styled("div", {
  width: "150px",
});

const Image = styled("img", {
  width: "100%",
  maxHeight: "90vh",
  margin: "auto",
});

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  height: "calc(100vh - 32px)",
  margin: "16px",
});

const TextContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const BodyText = styled(Body, {
  marginBottom: "32px",
  marginTop: "16px",
});

const Spacer = styled("div", {
  marginBottom: "32px",
});

const StyledButton = styled(Button, {
  marginBottom: "16px",
});

const PoweredBy = styled(Heading, {
  textAlign: "center",
  color: "$text_80",
});

const PoweredByMobile = styled(Body, {
  textAlign: "center",
  color: "$text_80",
});

const BigImageContainer = styled("div", {
  height: "100%",
  display: "flex",
  alignItems: "center",
});

interface SignupInputFormProps {
  setShowVerificationForm: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export default function Signup({
  setShowVerificationForm,
  setEmail,
}: SignupInputFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm<SignupFields>();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState<Icon>(EyeOff);
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] =
    useState<Icon>(EyeOff);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const apiClient = useContext(ApiClientContext);
  const { isDesktop } = useDeviceDimensions();
  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setPasswordIcon(passwordIcon === EyeOff ? Eye : EyeOff);
    setShowPassword((prevState) => !prevState);
  };

  const toggleShowPasswordConfirmation = () => {
    setPasswordConfirmationIcon(
      passwordConfirmationIcon === EyeOff ? Eye : EyeOff
    );
    setShowPasswordConfirmation((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<SignupFields> = async (formData) => {
    setIsLoading(true);
    try {
      const { passwordConfirmation, ...dataWithoutExtraPassword } = formData;
      const { email, password } = dataWithoutExtraPassword;
      const data = {
        email: email.toLowerCase(),
        password,
      };
      await apiClient.default.tempSignup({ data });

      setEmail(data.email);
      setShowVerificationForm(true);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Container>
      <TextContainer>
        <ImageContainer>
          <img src="/GahakHeader.png" width="100%" height="auto" />
        </ImageContainer>
        <div>
          <Heading variant={isDesktop ? "heading_06" : "heading_04"}>
            Get started now
          </Heading>
          <BodyText variant={isDesktop ? "body_02" : "body_01"}>
            Create an account and unlock the potential of e-commerce for your
            business
          </BodyText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextBox
              label="Email"
              placeholder="Enter your email address"
              {...register(SignupAttrType.email, {
                required: "Input Email",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email format",
                },
              })}
              error={errors.email?.message}
            />
            <Spacer />
            <TextBox
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              {...register(SignupAttrType.password, {
                required: "Input password",
                minLength: {
                  value: 6,
                  message: "Minimum of 6 characters required",
                },
                validate: () => {
                  errors.passwordConfirmation?.type === "validate" &&
                    trigger(SignupAttrType.passwordConfirmation);
                  return true;
                },
              })}
              error={errors.password?.message}
              icon={{
                position: TextBoxIconPosition.TRAILING,
                Icon: passwordIcon,
                onIconClick: toggleShowPassword,
              }}
            />
            <Spacer />
            <TextBox
              label="Retype Password"
              type={showPasswordConfirmation ? "text" : "password"}
              placeholder="Enter your password"
              {...register(SignupAttrType.passwordConfirmation, {
                required: "Input password",
                minLength: {
                  value: 6,
                  message: "Minimum of 6 characters required",
                },
                validate: (value) =>
                  value === watch(SignupAttrType.password) ||
                  "Passwords do not match",
              })}
              error={errors.passwordConfirmation?.message}
              icon={{
                position: TextBoxIconPosition.TRAILING,
                Icon: passwordConfirmationIcon,
                onIconClick: toggleShowPasswordConfirmation,
              }}
            />
            <Spacer />
            <StyledButton
              variant="secondary"
              size={isDesktop ? "large" : "small"}
              style={{ width: "100%" }}
              type="submit"
            >
              Create Account
            </StyledButton>
            <StyledButton
              onClick={() => navigate("/seller/signin")}
              variant="secondary"
              size={isDesktop ? "large" : "small"}
              outlined
              style={{ width: "100%" }}
              type="button"
            >
              Have an account already?
            </StyledButton>
          </form>
        </div>
        {isDesktop ? (
          <PoweredBy variant="heading_03">Powered by Kaajus 2023</PoweredBy>
        ) : (
          <PoweredByMobile variant="body_mini_03">
            Powered by Kaajus 2023
          </PoweredByMobile>
        )}
      </TextContainer>
      {isDesktop && (
        <BigImageContainer>
          <Image src="/login.png" />
        </BigImageContainer>
      )}
    </Container>
  );
}
