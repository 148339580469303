import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import UseAuth from "../../../hooks/UseAuth";
import { Step } from "../EditCredentials";
import { ApiClientContext } from "../../../context/ApiClientContext";
import { handleError } from "../../../util/ErrorHandler";
import { styled } from "../../../stitches/stitches.config";
import { TextBox } from "../../../stitches/textbox/TextBox";
import { Button } from "../../../stitches/button/Button";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import IconBadge from "../../../stitches/iconBadge/IconBadge";

type EditPasswordProps = {
  setStep: React.Dispatch<React.SetStateAction<Step>>;
};

type EditPasswordForm = {
  password: string;
  confirmPassword: string;
};

enum EditPasswordFormFields {
  password = "password",
  confirmPassword = "confirmPassword",
}

const Header = styled("div", {
  display: "flex",
  columnGap: "$space$space_10",
  width: "100%",
  padding: "24px $space$space_16 $space$space_16 $space$space_16",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  marginBottom: "10px",
});

const HeaderTitle = styled(Heading, {
  display: "flex",
  alignItems: "center",
});

const Input = styled(TextBox, {
  width: "100%",
  marginBottom: "26px",
});

const StyledButton = styled(Button, {
  margin: "20px 20px",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Form = styled("form", {
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  margin: "0 20px",
});

export default function EditPassword({ setStep }: EditPasswordProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { storeName } = useParams();
  const { user } = UseAuth();
  const apiClient = useContext(ApiClientContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
    watch,
  } = useForm<EditPasswordForm>();

  const submitNewPassword = async (data: EditPasswordForm) => {
    try {
      setIsLoading(true);
      const dataWithoutExtraPassword = {
        password: data.password,
      };

      await apiClient.default.updateCredentials(storeName || "", user!.uid, {
        data: dataWithoutExtraPassword,
      });

      setStep(Step.View);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Container>
      <Header>
        <IconBadge
          outlined={false}
          size="medium"
          iconName="ChevronLeft"
          customCSS={{
            Icon: {
              color: "$black_100",
            },
          }}
          onClick={() => setStep(Step.View)}
        />
        <HeaderTitle variant="heading_04">Back</HeaderTitle>
      </Header>
      <h2>Edit Password</h2>
      <>
        <Form id="passwordForm" onSubmit={handleSubmit(submitNewPassword)}>
          <Input
            label="New Password"
            placeholder="E.g. abc123"
            type="password"
            {...register(EditPasswordFormFields.password, {
              required: "Required Field",
              minLength: {
                value: 6,
                message: "Minimum of 6 characters required",
              },
              validate: () => {
                errors.confirmPassword?.type === "validate" &&
                  trigger(EditPasswordFormFields.confirmPassword);
                return true;
              },
            })}
            error={errors.password?.message}
          />
          <Input
            label="Confirm New Password"
            placeholder="E.g. abc123"
            type="password"
            {...register(EditPasswordFormFields.confirmPassword, {
              required: "Required Field",
              minLength: {
                value: 6,
                message: "Minimum of 6 characters required",
              },
              validate: (value) =>
                value === watch(EditPasswordFormFields.password) ||
                "Passwords do not match",
            })}
            error={errors.confirmPassword?.message}
          />
          <br />
        </Form>
        <StyledButton
          form="passwordForm"
          variant="secondary"
          size="large"
          type="submit"
        >
          Submit
        </StyledButton>
      </>
    </Container>
  );
}
