import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Cart from "./View/Cart";
import { useDeviceDimensions } from "../../hooks/useDevice";
import { CustomerStore } from "../../pullstate/Customer";

export default function DesktopCart() {
  const isCartOpen = CustomerStore.useState((s) => s.cart.isOpen);
  const { isTablet, isDesktop, isWideDesktop } = useDeviceDimensions();

  let size: string;
  if (isTablet) {
    size = "60vw";
  } else if (isDesktop || isWideDesktop) {
    size = "30vw";
  } else {
    size = "100vw";
  }
  return (
    <Drawer
      open={isCartOpen}
      onClose={() => {
        CustomerStore.update((s) => {
          s.cart.isOpen = false;
        });
      }}
      direction="right"
      size={size}
    >
      <Cart />
    </Drawer>
  );
}
