import { useEffect, useRef, useState } from "react";
import { RadioItemProps } from "../../stitches/radioButton/RadioButton";
import { StoreFilterProps } from "./StoreFilter";
import { StoreSearchProps } from "./StoreSearch";
import Fuse from "fuse.js";
import { CustomerStore } from "../../pullstate/Customer";
import _ from "lodash";
import { Product } from "../../types/product/new/product.model";

export enum StoreFilterOptions {
  LowestPrice = "Lowest Price",
  HighestPrice = "Highest Price",
}

export function useStoreSearchFilterBar() {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const products = CustomerStore.useState((s) => s.products);
  const fuseOptions = {
    keys: ["name"], // Search using product name
    threshold: 0.4,
  };
  const fuseRef = useRef<any>();
  const debouncedSearchRef = useRef<any>(); // the debounce delay
  const storeViewProducts = CustomerStore.useState((s) => s.storeViewProducts);
  const { isFiltered } = CustomerStore.useState();
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>();
  const onModalClose = () => setOpen(false);
  const onModalOpen = () => setOpen(true);

  useEffect(() => {
    // Reset state when a customer navigates to another page from storeview, and then navigates back to storeview
    if (!_.isEqual(storeViewProducts, products)) {
      CustomerStore.update((s) => {
        s.storeViewProducts = [...products];
        s.isFiltered = false;
        s.filter = undefined;
        s.storeViewProductsWithoutFiltering = [];
      });
    }
  }, []);

  useEffect(() => {
    fuseRef.current = new Fuse(products, fuseOptions);
    debouncedSearchRef.current = _.debounce(
      (
        searchText: string,
        isFiltered: boolean,
        selectedFilter: string | undefined
      ) => {
        if (searchText.length === 0) {
          if (isFiltered) {
            sortStoreViewProducts(products, selectedFilter!);
          } else {
            CustomerStore.update((s) => {
              s.storeViewProducts = [...products];
            });
          }
          return;
        }

        const searchResults: Product[] = fuseRef.current
          .search(searchText)
          .map((result) => {
            return {
              ...result.item,
            };
          });

        if (isFiltered) {
          sortStoreViewProducts(searchResults, selectedFilter!);
        } else {
          CustomerStore.update((s) => {
            s.storeViewProducts = searchResults;
          });
        }
      },
      500
    );
  }, [products]);

  const onRadioItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSelectedFilter(event.currentTarget.id);
  };

  const onFilterSubmit = () => {
    if (selectedFilter) {
      sortStoreViewProducts(undefined, selectedFilter);

      CustomerStore.update((s) => {
        s.isFiltered = true;
        s.filter = selectedFilter;
      });
    }

    setOpen(false);
  };

  const sortStoreViewProducts = (
    productsToFilter: Product[] = storeViewProducts,
    selectedFilter: string
  ) => {
    let sortedProducts: Product[];

    if (selectedFilter === StoreFilterOptions.LowestPrice) {
      // Sort products by lowest to highest price
      sortedProducts = [...productsToFilter].sort(
        (a, b) => a.variants[0].price - b.variants[0].price
      );
    } else {
      // Sort products by lowest to highest price
      sortedProducts = [...productsToFilter].sort(
        (a, b) => b.variants[0].price - a.variants[0].price
      );
    }

    CustomerStore.update((s) => {
      s.storeViewProducts = sortedProducts;
      s.storeViewProductsWithoutFiltering = [...productsToFilter];
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    debouncedSearchRef.current(inputValue, isFiltered, selectedFilter);
  };

  const radioItems: Omit<RadioItemProps, "onClick">[] = [
    {
      value: StoreFilterOptions.LowestPrice,
      label: StoreFilterOptions.LowestPrice,
      id: StoreFilterOptions.LowestPrice,
    },
    {
      value: StoreFilterOptions.HighestPrice,
      label: StoreFilterOptions.HighestPrice,
      id: StoreFilterOptions.HighestPrice,
    },
  ];

  const storeFilterProps: StoreFilterProps = {
    open,
    onModalClose,
    radioItems,
    onRadioItemClick,
    onSubmit: onFilterSubmit,
  };

  const storeSearchProps: StoreSearchProps = {
    value,
    onChange: onInputChange,
  };

  return {
    storeSearchProps,
    storeFilterProps,
    onModalOpen,
  };
}
