import { User } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";

export default function UseAuth() {
  const { currentUser, userStoreName } = useContext(AuthContext);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => setUser(currentUser), []);
  return { user, userStoreName };
}
