import { styled } from "@stitches/react";
import { bodyStyles } from "../typography/BodyStyles";
import * as FeatherIcons from "react-feather";

export interface ResponseIndicatorProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  variant: "success" | "caution" | "error";
}

export const TextboxResponseIndicator = styled("p", {
  ...bodyStyles.body_mini_03,
  padding: 0,
  margin: 0,
  variants: {
    variant: {
      success: {
        color: "$success_50",
      },
      caution: {
        color: "$caution_50",
      },
      error: {
        color: "$danger_50",
      },
    },
  },
});

export const ResponseIndicatorContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$space$space_10",
  paddingLeft: "$space$space_10",
});

export const ResponseIndicator = ({
  variant,
  ...props
}: ResponseIndicatorProps) => {
  let Icon: FeatherIcons.Icon;
  switch (variant) {
    case "success":
      Icon = FeatherIcons.CheckCircle;
      break;
    case "caution":
      Icon = FeatherIcons.AlertCircle;
      break;
    case "error":
      Icon = FeatherIcons.AlertOctagon;
      break;
    default:
      Icon = FeatherIcons.CheckCircle;
  }

  const IconContainer = styled(Icon, {
    variants: {
      variant: {
        success: {
          color: "$success_50",
        },
        caution: {
          color: "$caution_50",
        },
        error: {
          color: "$danger_50",
        },
      },
    },
  });
  return (
    <>
      <ResponseIndicatorContainer>
        <IconContainer variant={variant} size={16} />
        <TextboxResponseIndicator variant={variant} {...props} />
      </ResponseIndicatorContainer>
    </>
  );
};
