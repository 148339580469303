import { useParams } from "react-router-dom";
import {
  CustomerStore,
  ICustomerStore,
} from "../../../../../pullstate/Customer";
import { CartProduct } from "../../../../../types/CartFields";
import { saveCartToLocalStorage } from "../../../CartUtil";

export const useCartItem = (item: CartProduct) => {
  const {
    id,
    name,
    variant: { price, attributes },
    images,
    hasVariants,
    selectedQuantity,
  } = item;
  const { storeName } = useParams();

  const changeCartItemQuantity = (selectedQuantity: number) => {
    CustomerStore.update((s: ICustomerStore) => {
      // Get variant and product indexes to update pullstate
      const selectedVariantProductIndex = s.products.findIndex(
        (productFromArray) => productFromArray.id === item.id
      );

      const selectedVariantStoreViewProductIndex =
        s.storeViewProducts.findIndex(
          (productFromArray) => productFromArray.id === item.id
        );

      if (
        selectedVariantProductIndex === -1 ||
        selectedVariantStoreViewProductIndex === -1
      ) {
        return;
      }

      const selectedVariantIndex: number = s.products[
        selectedVariantProductIndex
      ].variants.findIndex((variant) => variant.id === item.variant.id);

      if (selectedVariantIndex === -1) {
        return;
      }

      const cartItem = s.cart.cartDisplayMap.get(id);

      if (cartItem) {
        const variant = cartItem.find(
          (cartItemVariant) => cartItemVariant.variant.id === item.variant.id
        );

        if (variant) {
          if (selectedQuantity === 0) {
            s.cart.total -= variant.variant.price * variant.selectedQuantity;
            s.cart.numberOfItems -= variant.selectedQuantity;
            const index = cartItem.findIndex(
              (cartItemVariant) =>
                cartItemVariant.variant.id === item.variant.id
            );
            if (index !== -1) {
              cartItem.splice(index, 1);

              // Update pullstate
              s.products[selectedVariantProductIndex].variants[
                selectedVariantIndex
              ].quantity += variant.selectedQuantity;

              s.storeViewProducts[
                selectedVariantStoreViewProductIndex
              ].variants[selectedVariantIndex].quantity +=
                variant.selectedQuantity;

              if (
                s.selectedProduct &&
                s.selectedProduct.variants[selectedVariantIndex]
              ) {
                s.selectedProduct.variants[selectedVariantIndex].quantity +=
                  variant.selectedQuantity;
              } else {
                console.error(
                  "Error updating selectedProduct's variant quantity"
                );
              }

              if (s.selectedVariant) {
                s.selectedVariant.quantity += variant.selectedQuantity;
              }
            }
          } else if (selectedQuantity > variant.selectedQuantity) {
            variant.selectedQuantity += 1;
            s.cart.total += variant.variant.price;
            s.cart.numberOfItems += 1;

            // Update pullstate
            s.products[selectedVariantProductIndex].variants[
              selectedVariantIndex
            ].quantity -= 1;

            s.storeViewProducts[selectedVariantStoreViewProductIndex].variants[
              selectedVariantIndex
            ].quantity -= 1;

            if (
              s.selectedProduct &&
              s.selectedProduct.variants[selectedVariantIndex] &&
              s.selectedVariant?.quantity
            ) {
              s.selectedProduct.variants[selectedVariantIndex].quantity -= 1;
              s.selectedVariant.quantity -= 1;
            } else {
              console.error(
                "Error updating selectedProduct's variant quantity"
              );
            }
          } else if (selectedQuantity < variant.selectedQuantity) {
            variant.selectedQuantity -= 1;
            s.cart.total -= variant.variant.price;
            s.cart.numberOfItems -= 1;

            // Update pullstate
            s.products[selectedVariantProductIndex].variants[
              selectedVariantIndex
            ].quantity += 1;

            s.storeViewProducts[selectedVariantStoreViewProductIndex].variants[
              selectedVariantIndex
            ].quantity += 1;

            if (
              s.selectedProduct &&
              s.selectedProduct.variants[selectedVariantIndex] &&
              s.selectedVariant?.quantity
            ) {
              s.selectedProduct.variants[selectedVariantIndex].quantity += 1;
              s.selectedVariant.quantity += 1;
            } else {
              console.error(
                "Error updating selectedProduct's variant quantity"
              );
            }
          }
        }
      }
    });

    const updatedCart = CustomerStore.getRawState().cart;
    saveCartToLocalStorage(updatedCart, storeName);
  };

  return {
    id,
    name,
    variant: { price, attributes },
    images,
    hasVariants,
    selectedQuantity,
    changeCartItemQuantity,
  };
};
