import { useState } from "react";
import Signup from "./Signup";
import Verification from "./Verification";

export default function SignupForm() {
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [email, setEmail] = useState("");

  if (showVerificationForm) {
    return <Verification email={email} />;
  }

  return (
    <Signup
      setShowVerificationForm={setShowVerificationForm}
      setEmail={setEmail}
    />
  );
}
