import React from "react";
import Search from "../../../components/Search/Search";

export default function SearchView({
  onChangeHandler,
  searchInput,
}: {
  onChangeHandler: (e: any) => void;
  searchInput: string;
}) {
  return (
    <Search
      onChangeHandler={onChangeHandler}
      searchInput={searchInput}
      placeholder={"Search By Order ID"}
    />
  );
}
