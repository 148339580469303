import React, { useEffect, useState } from "react";
import { Image as ImageType } from "../../../../../types/product/new/product.model";
import { styled } from "../../../../../stitches/stitches.config";
import { Blurhash } from "react-blurhash";
import { DEFAULT_BLURHASH } from "../../../../../constants/Constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Img = styled(LazyLoadImage, {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
  transition: "transform 0.5s ease-in-out",
  opacity: 0,
});

const ImageContainer = styled("div", {
  position: "relative",
  height: "216px",
  overflow: "hidden",
  borderRadius: "$space$space_10",
  border: "1px solid $grey_50",
});

interface IImageContainer {
  image: ImageType;
  width?: string;
  height?: string;
  borderRadius?: string;
}

export default function ProductImage({ image }: IImageContainer) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <ImageContainer>
      <Blurhash
        hash={DEFAULT_BLURHASH}
        width={400}
        height={216}
        resolutionX={32}
        resolutionY={32}
        punch={1}
        style={{
          opacity: imageLoaded ? 0 : 1,
        }}
      />
      <Img
        src={image.url}
        alt="image"
        loading="lazy"
        effect="blur"
        onLoad={() => setImageLoaded(true)}
        style={{ opacity: imageLoaded ? 1 : 0 }}
        width="100%"
        height="100%"
      />
    </ImageContainer>
  );
}
