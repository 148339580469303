import imageCompression, { Options } from "browser-image-compression";
export const compressImages = async (
  images: FileList,
  options?: Options
): Promise<File[]> => {
  const defaultOptions = {
    maxWidthOrHeight: 1600,
    useWebWorker: true,
  };

  const compressedImages = Array.from(images).map((image) => {
    return imageCompression(image, options ?? defaultOptions);
  });

  return Promise.all(compressedImages);
};

export const compressImagesWithThumbnail = async (
  images: FileList,
  fullSizeOptions?: Options,
  thumbnailOptions?: Options
): Promise<{ compressedImage: File; thumbnail: File }[]> => {
  // Default options for full-sized compressed image
  const defaultFullSizeOptions = {
    quality: 0.8,
    maxWidthOrHeight: 1600,
    useWebWorker: true,
  };

  // Default options for thumbnail
  const defaultThumbnailOptions = {
    quality: 0.2,
    maxWidthOrHeight: 1600,
    useWebWorker: true,
    maxSizeMB: 0.2,
  };

  const compressedImagesAndThumbnails = Array.from(images).map(
    async (image) => {
      // Create promises for full-sized compressed image and thumbnail
      const compressedImagePromise = imageCompression(
        image,
        fullSizeOptions ?? defaultFullSizeOptions
      );
      const thumbnailPromise = imageCompression(
        image,
        thumbnailOptions ?? defaultThumbnailOptions
      );

      // Return a promise that resolves to an object containing the compressed image and thumbnail
      return Promise.all([compressedImagePromise, thumbnailPromise])
        .then(([compressedImage, thumbnail]) => {
          return { compressedImage, thumbnail };
        })
        .catch((error) => {
          console.error("Error compressing image:", error);
          throw error;
        });
    }
  );

  return Promise.all(compressedImagesAndThumbnails);
};
