const errorCodes = {
  firstName: "Please enter first name",
  lastName: "Please enter last name",
  email: "Please enter email",
  phoneNumber: "Please enter phone number",
  addressLine: "Please enter address",
  city: "Please enter city",
  country: "Please enter country",
};

export default errorCodes;
