import _ from "lodash";
import { CustomerStore } from "../../pullstate/Customer";

export const useStoreFilterPanel = () => {
  const { isFiltered, filter } = CustomerStore.useState((s) => s);

  const onRemoveFilter = () => {
    CustomerStore.update((s) => {
      s.isFiltered = false;
      s.storeViewProducts = [...s.storeViewProductsWithoutFiltering];
    });
  };

  return {
    isFiltered,
    filter,
    onRemoveFilter,
  };
};
