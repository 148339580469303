import { styled } from "@stitches/react";
import React, { useRef, useState } from "react";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { Button } from "../../../stitches/button/Button";
import { Columns } from "react-feather";

const ImageContainer = styled("div", {
  margin: "$space$space_16 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Image = styled("img", {
  border: "1px solid $grey_50",
  borderRadius: "10px",
  maxWidth: "200px",
  maxHeight: "150px",
  objectFit: "cover",
});

const StyledButton = styled(Button, {
  position: "relative",
  display: "block",
  width: "100%",
});

const StyledInput = styled("input", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  opacity: 0,
  cursor: "pointer",
});

interface ProductFileUploaderProps {
  handleSizeChart: (image: File | null) => void;
  imageurl?: string;
}

export const ProductFileUploader = ({
  handleSizeChart,
  imageurl,
}: ProductFileUploaderProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>(imageurl ?? null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleSizeChart(file);
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    handleSizeChart(null);
    setImageUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div>
        <StyledButton
          variant="secondary"
          size="small"
          type="button"
          outlined
          disabled={!!imageUrl?.length}
          withIcon
        >
          <StyledInput
            type="file"
            onChange={handleImageChange}
            ref={fileInputRef}
            disabled={!!imageUrl?.length}
          />
          <Columns size={24} />
          Add size chart
        </StyledButton>
      </div>
      {imageUrl && (
        <ImageContainer>
          <Image src={imageUrl} alt="Uploaded Preview" />
          <IconBadge
            onClick={handleDeleteImage}
            iconName="Trash"
            outlined={false}
            size="medium"
            customCSS={{
              Icon: {
                color: "$danger_100",
              },
            }}
          />
        </ImageContainer>
      )}
    </>
  );
};

export default ProductFileUploader;
