import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Uppy from "@uppy/core";
import { AdminStore } from "../../../../../pullstate/Admin";
import { ProductFields } from "../../../../../types/ProductFields";
import { productValidationSchema } from "../../../../AddProduct/resolver/Product.resolver";
import UseAuth from "../../../../../hooks/UseAuth";
import { ApiClientContext } from "../../../../../context/ApiClientContext";
import { compressImages } from "../../../../../util/CompressImage";
import {
  deleteAwsS3Images,
  getAwsS3ImageUrls,
} from "../../../../../util/s3Images.util";
import { handleError } from "../../../../../util/ErrorHandler";
import { Image } from "../../../../../types/product/new/product.model";

export const useProductContainer = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    unregister,
  } = useForm<ProductFields>({
    mode: "onSubmit",
    resolver: yupResolver(productValidationSchema),
    defaultValues: {
      hasVariants: false,
    },
  });
  const product = AdminStore.useState((s) => s.selectedProduct)!;
  const { user } = UseAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { storeName } = useParams();
  const apiClient = useContext(ApiClientContext);
  const watcher = watch([
    "variants",
    "description",
    "name",
    "images",
    "hasVariants",
  ]);

  const setHasVariantToTrue = () => {
    setValue("hasVariants", true);
  };

  const handleBackClick = () => {
    // setIsDeleteModalOpen(true);
    navigate("/" + storeName + "/seller/products");
  };

  const handleExitDialog = () => {
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDeletion = () => {
    deleteProduct();
    setIsDeleteModalOpen(false);
  };

  const handleDeleteProduct = () => {
    setIsDeleteModalOpen(true);
  };

  const handleEditProduct = () => {
    navigate(`/${storeName}/seller/product/edit/${product.id}`);
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const [variants, description, name, images, hasVariants] = watcher;
    const isBaseEmpty = !description || !name || !!!images.length;
    if (hasVariants) {
      const isVariantEmpty = variants?.some(
        (variant) =>
          !variant.attributes[0].value || !variant.price || !variant.quantity
      );
      setIsButtonDisabled(isBaseEmpty || isVariantEmpty);
    }

    if (!hasVariants) {
      const isVariantEmpty = variants?.some(
        (variant) => !variant.price || !variant.quantity
      );
      setIsButtonDisabled(isBaseEmpty || isVariantEmpty);
    }
  }, [watcher]);

  const uppyRef = useRef(new Uppy());

  useEffect(() => {
    register("images", { value: new DataTransfer().files });
  }, []);

  uppyRef.current.on("files-added", (file) => {
    if (file.length > 0) {
      handleImages(uppyRef.current.getFiles());
    } else {
    }
  });

  uppyRef.current.on("file-removed", (file) => {
    handleImages(uppyRef.current.getFiles());
  });

  const handleImages = (uppyFiles: any[]) => {
    if (uppyFiles.length > 0) {
      if (errors.images) {
        clearErrors("images");
      }
    } else {
      setError("images", {
        type: "custom",
        message: "Please enter Product Images",
      });
    }
    const dt = new DataTransfer();
    uppyFiles.forEach((uppyFile: any) => {
      dt.items.add(uppyFile.data);
    });
    setValue("images", dt.files);
  };

  const deleteProduct = async () => {
    setIsLoading(true);
    try {
      await apiClient.default.deleteProduct(storeName!, user!.uid, product.id);

      AdminStore.update((s) => {
        const index = s.products.findIndex(
          (stateProduct) => stateProduct.id === product.id
        );

        s.products.splice(index, 1);

        s.selectedProduct = undefined;
      });
      await deleteProductImagesFromS3();
      navigate("/" + storeName + "/seller/products");
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const deleteProductImagesFromS3 = async () => {
    const imageNames = product.images.map((image) => {
      const name = image.url.substring(image.url.lastIndexOf("/") + 1);
      return name;
    });

    await deleteAwsS3Images(storeName!, imageNames);
  };

  const onSubmit = async (data: ProductFields) => {
    setIsLoading(true);

    const formattedData: Omit<ProductFields, "images"> & { images: Image[] } = {
      name: data.name,
      description: data.description,
      hasVariants: data.hasVariants,
      images: [],
      attributes: [],
      variants: [],
    };

    if (!data.hasVariants) {
      const variants = data.variants.map((variant) => {
        return {
          ...variant,
          attributes: [],
        };
      });

      formattedData.variants = variants;
    } else {
      const productAttributeValues: string[] = [];

      let hasDuplicate = false;
      const uniqueVariants = data.variants.filter((variant, idx) => {
        variant.attributes.filter((attribute) => {
          if (productAttributeValues.includes(attribute.value)) {
            setError(`variants.${idx}.attributes.${0}.value`, {
              type: "custom",
              message: "Duplicate value",
            });
            hasDuplicate = true;
            return false;
          }
          productAttributeValues.push(attribute.value);
        });
        return true;
      });

      if (hasDuplicate) {
        setIsLoading(false);
        return;
      }

      const variants = uniqueVariants.map((variant) => {
        const variantAttributes = variant.attributes.map((attribute) => {
          return {
            ...attribute,
            name: data.attributes[0].name,
          };
        });

        return {
          ...variant,
          attributes: variantAttributes,
        };
      });

      formattedData.variants = variants;

      const productAttributes = [
        { name: data.attributes.at(0)!.name, value: productAttributeValues },
      ];
      formattedData.attributes = productAttributes;
    }

    const compressedImages = await compressImages(data.images);
    const images: Image[] = await getAwsS3ImageUrls(
      storeName!,
      compressedImages
    );
    formattedData.images = images;
  };

  return {
    onSubmit,
    handleSubmit,
    register,
    unregister,
    watch,
    uppyRef,
    isButtonDisabled,
    setHasVariantToTrue,
    isLoading,
    handleBackClick,
    handleExitDialog,
    isDeleteModalOpen,
    handleConfirmDeletion,
    product,
    handleEditProduct,
    deleteProduct,
    handleDeleteProduct,
  };
};
