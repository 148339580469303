import React, { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { currentUser, userStoreName } = useContext(AuthContext);
  const { storeName } = useParams();

  const currentPath = window.location.pathname;
  const isProfileRoute = currentPath === "/seller/profile";

  // If a user is not logged in.
  if (!currentUser) {
    return <Navigate to={"/seller/signin"} />;
  }
  // if the user is logged in, but does not have a storeName yet (i.e. has not created a profile),
  // and the user is trying to access  a protected route other than the `create profile page`.
  else if (!!currentUser && !userStoreName && !isProfileRoute) {
    return <Navigate to={"/seller/profile"} />;
  }
  // if the user is logged in, has a storeName, and is trying to access a url related to a storeName
  else if (!!currentUser && userStoreName && storeName) {
    // if the user is trying to access a storeName that is not his.
    if (userStoreName !== storeName) {
      return <Navigate to={`/${userStoreName}/seller/dashboard`} />;
    }
  }
  return children;
};

export default ProtectedRoute;
