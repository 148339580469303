import React from "react";
import { useStoreSearchFilterBar } from "./useStoreSearchFilterBar";
import DesktopSearch from "./DesktopSearch";
import DesktopFilter from "./DesktopFilter";

export default function DesktopSearchFilterBar() {
  const { storeSearchProps, storeFilterProps } = useStoreSearchFilterBar();
  return (
    <>
      <DesktopSearch {...storeSearchProps} />
      <DesktopFilter {...storeFilterProps} />
    </>
  );
}
