import React from "react";
import { useParams } from "react-router-dom";
import { LineChartGenerator } from "../../../components/Chart/Generators/LineChartGenerator";
import ChartView from "../../../components/Chart/View/ChartView";

// Dummy Data
const labels = ["January", "February", "March", "April", "May", "June", "July"];

const dataProps = {
  props: [
    {
      label: "Data1",
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    {
      label: "Data2",
      data: [28, 48, 40, 19, 86, 27, 90],
    },
  ],
};

const optionsProps = {
  props: {
    title: "Chart.js Line Chart",
    position: "top" as const,
  },
};

// Dummy Data ends

const lineChartGenerator = new LineChartGenerator();
const options = lineChartGenerator.generateLineChartOptions(optionsProps);
const data = {
  labels,
  datasets: lineChartGenerator.generateDataset(dataProps),
};

export default function LineChart() {
  const { storeName } = useParams();
  return <ChartView type={"line"} data={data} options={options} />;
}
