import React from "react";
import styles from "./CartTotal.module.css";
import { useStoreState } from "pullstate";
import { CustomerStore } from "../../../../../pullstate/Customer";
import { styled } from "../../../../../stitches/stitches.config";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";

const CartTotalContainer = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  padding: "$space_16 $space_16 $space_0 $space_16",
});

const CartTotalLabelHeading = styled(Heading, {
  marginTop: "12px",
});

const CartTotalValueHeading = styled(Heading, {
  color: "$secondary_60",
});

export default function CartTotal() {
  const total = useStoreState(CustomerStore, (s) => s.cart.total);

  return (
    <CartTotalContainer>
      <CartTotalLabelHeading variant="heading_02">{`Total(incl tax)`}</CartTotalLabelHeading>
      <CartTotalValueHeading variant="heading_04">
        Rs. {total}
      </CartTotalValueHeading>
    </CartTotalContainer>
  );
}
