import { useStoreState } from "pullstate";
import { useParams } from "react-router-dom";
import { CustomerStore } from "../../../../pullstate/Customer";
import { Product } from "../../../../types/product/new/product.model";
import { useAsync } from "react-use";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../context/ApiClientContext";
import { handleError } from "../../../../util/ErrorHandler";
import { loadCartFromLocalStorage } from "../../../Cart/CartUtil";
import _ from "lodash";

export const useStoreview = () => {
  const { storeName } = useParams();
  const storeViewProducts = useStoreState(
    CustomerStore,
    (s) => s.storeViewProducts
  );
  const storeDisplayNameState = useStoreState(
    CustomerStore,
    (s) => s.storeDisplayName
  );
  const apiClient = useContext(ApiClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [storeFound, setStoreFound] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    loadCartFromLocalStorage(storeName!);
  }, []);

  const checkStoreExistence = async () => {
    try {
      setIsLoading(true);
      const storeDisplayName = await apiClient.default.getStore(
        storeName || ""
      );
      setStoreFound(!!storeDisplayName); // If storeExists is false, set storeNotFound to true
      CustomerStore.update((s) => {
        s.storeDisplayName = storeDisplayName || "";
      });
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
      setStoreFound(true); // Set storeNotFound to true in case of an error
    }
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const data = await apiClient.default.getCustomerProducts(storeName || "");
      CustomerStore.update((s) => {
        // If the customer visited a product page before visiting the store.
        if (s.products.length === 1) {
          const existingProduct: Product = s.products[0];
          data.forEach((product) => {
            if (product.id !== existingProduct.id) s.products.push(product);
          });
        }
        // if the customer visited the store first.
        else if (storeViewProducts.length === 0) {
          const cart = loadCartFromLocalStorage();
          const productsWithCartData: Product[] = [];

          // Making sure that the products in the store view have the correct quantity.
          // If the customer added a product to the cart, the quantity of that product
          // should be subtracted from the quantity of the product in the store view.
          for (const [
            productId,
            cartProducts,
          ] of cart?.cartDisplayMap.entries() || []) {
            const product = data.find((p) => p.id === productId);
            if (product) {
              for (const variant of product.variants) {
                const cartProduct = cartProducts.find(
                  (cartProd) => cartProd.variant.id === variant.id
                );
                if (cartProduct) {
                  variant.quantity -= cartProduct.selectedQuantity;
                }
              }
              productsWithCartData.push(product);
            }
          }

          const mergedData =
            productsWithCartData.length > 0
              ? _.unionBy(productsWithCartData, data, "id")
              : data;
          s.products = mergedData;
          s.storeViewProducts = mergedData;
        }
      });
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const { loading } = useAsync(async () => {
    if (!storeDisplayNameState) await checkStoreExistence();
    else setStoreFound(true);
    if (storeViewProducts.length <= 1) await fetchProducts();
  }, []);

  return {
    loading,
    storeViewProducts,
    storeName,
    storeFound,
    isLoading,
  };
};
