import React from "react";
import { IOrder } from "../OrderSummary/OrderSummary";
import { styled } from "@stitches/react";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import IconBadge from "../../../../stitches/iconBadge/IconBadge";
export type IOrderHeader = IOrder;

const Header = styled("div", {
  display: "flex",
  alignItems: "center",
  padding: "20px",
  marginTop: "20px",
  gap: "16px",
});

const DeliverySection = styled("div", {
  width: "50%",
  marginLeft: "3%",
});

const DeliveryHeader = styled("h4", {
  marginTop: 0,
});

const OrderID = styled("h4", {
  marginRight: "2%",
});

const OrderDetailsHeading = styled(Heading, {
  color: "$secondary_60",
});

export default function OrderHeader({ order, navigateBack }: IOrderHeader) {
  const { referenceId } = order;

  return (
    <>
      <Header>
        <IconBadge
          onClick={navigateBack}
          iconName="ChevronLeft"
          size="medium"
          outlined={false}
          customCSS={{
            Icon: {
              color: "$secondary_60",
            },
          }}
        />
        <OrderDetailsHeading variant="heading_05">
          Order ID-{referenceId}
        </OrderDetailsHeading>
      </Header>
    </>
  );
}
