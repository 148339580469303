import React, { useContext, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ProfileFields, ProfileAttrType } from "../../../types/ProfileFields";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import "react-phone-number-input/style.css";
import UseAuth from "../../../hooks/UseAuth";
import { auth } from "../../../firebase/firebase.config";
import { AdminStore } from "../../../pullstate/Admin";
import { ApiClientContext } from "../../../context/ApiClientContext";
import { handleError } from "../../../util/ErrorHandler";
import { Profile as ProfileModel } from "@gahakapp/client";
import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Body } from "../../../stitches/typography/BodyStyles";
import { TextBox } from "../../../stitches/textbox/TextBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidationSchema } from "./Profile.resolver";
import { Button } from "../../../stitches/button/Button";
import UseWindowHook from "../../../hooks/UseWindowHook";

const Container = styled("div", {
  position: "relative",

  "@Mobile": {
    minHeight: "calc(100vh)",
    margin: "58px $space_16 0 $space_16",
  },

  "@Tablet": {
    minHeight: "calc(100vh)",
    margin: "58px $space_16 0 $space_16",
  },

  "@Desktop": {
    margin: "36px $space_16 36px $space_16",
    display: "flex",
    justifyContent: "center",
    columnGap: "56px",
    minHeight: "calc(100vh - 72px)",
  },
});

const ImageContainer = styled("div", {
  width: "573px",
  height: "760px",
  position: "relative",
});

const InformationContainer = styled("div", {
  position: "relative",
  height: "100%",
  maxWidth: "539px",

  "@Mobile": {
    margin: "auto",
  },

  "@Tablet": {
    margin: "auto",
  },
});

const Image = styled("img", {
  width: "100%",
  height: "100%",
});

const GahakLogo = styled("img", {
  "@Mobile": {
    width: "104.135px",
    height: "59px",
    marginBottom: "36px",
  },

  "@Tablet": {
    width: "104.135px",
    height: "59px",
    marginBottom: "36px",
  },

  "@Desktop": {
    width: "130px",
    height: "74px",
    marginBottom: "56.35px",
  },
});

const Input = styled(TextBox, {
  width: "100%",
  marginBottom: "26px",
});

const StoreNameInput = styled(TextBox, {
  width: "100%",
  marginBottom: "46px",
});

const ProfileHeading = styled(Heading, {
  marginBottom: "$space_16",
});

const ProfileBodyText = styled(Body, {
  marginBottom: "36px",
});

const SubmitButton = styled(Button, {
  width: "100%",
});

const ImageURL = styled(Heading, {
  position: "absolute",
  top: "308px",
  left: "115px",
});

const CopyrightTextContainer = styled("div", {
  width: "100%",
  position: "absolute",
  textAlign: "center",
  bottom: "16px",
});

const Form = styled("form", {
  "@Mobile": {
    paddingBottom: "216px",
  },

  "@Tablet": {
    paddingBottom: "216px",
  },
});

export default function Profile() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = UseAuth();
  const apiClient = useContext(ApiClientContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ProfileFields>({
    mode: "onChange",
    resolver: yupResolver(profileValidationSchema),
  });

  const onSubmit: SubmitHandler<ProfileFields> = async (data) => {
    try {
      setIsLoading(true);

      const { firstName, lastName, storeName } = data;
      const sanitizeStoreName = storeName
        .replace(/\s/g, "") // Remove spaces
        .replace(/[^a-zA-Z0-9]/g, "") // Remove non-alphanumeric characters
        .toLowerCase();

      const request: ProfileModel = {
        id: user!.uid,
        firstName,
        lastName,
        storeName: sanitizeStoreName,
        storeDisplayName: storeName,
      };

      await apiClient.default.createProfile({
        data: request,
      });

      await auth.currentUser!.getIdTokenResult(true), // Refresh id token
        AdminStore.update((s) => {
          s.profile = { id: user!.uid, ...data };
        });

      navigate("/" + data.storeName + "/seller/dashboard", {
        state: { user: user!.uid },
      });
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const { width } = UseWindowHook();
  const isDesktopView: boolean = width >= 1200;
  const watchedStoreName = watch(ProfileAttrType.storeName);

  const formattedStoreName =
    watchedStoreName &&
    (watchedStoreName as string).replace(/\s+/g, "").toLowerCase();

  return isLoading ? (
    <Spinner />
  ) : (
    <Container>
      <InformationContainer>
        <GahakLogo src="/GahakHeader.png" alt="GahakHeader" />
        <ProfileHeading variant={isDesktopView ? "heading_06" : "heading_04"}>
          Create your store
        </ProfileHeading>
        <ProfileBodyText variant={isDesktopView ? "body_02" : "body_01"}>
          Choose a unique name for your Gahak store and finish setting up your
          seller profile
        </ProfileBodyText>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="First Name"
            placeholder="Enter your first name"
            {...register(ProfileAttrType.firstName)}
            error={errors.firstName?.message}
          />
          <Input
            label="Last Name"
            placeholder="Enter your last name"
            {...register(ProfileAttrType.lastName)}
            error={errors.lastName?.message}
          />
          <StoreNameInput
            label="Store Name"
            placeholder="Enter your store name"
            {...register(ProfileAttrType.storeName)}
            error={errors.storeName?.message}
          />
          <SubmitButton size="small" variant="secondary">
            Create Store
          </SubmitButton>
        </Form>
      </InformationContainer>

      {!isDesktopView && (
        <CopyrightTextContainer>
          <Body variant="body_mini_03">Copyright © Kaajus 2023</Body>
        </CopyrightTextContainer>
      )}

      {isDesktopView && (
        <ImageContainer>
          <Image src="/Profile.png" alt="img" />
          <ImageURL variant="heading_02">
            {`gahakapp.com/` + formattedStoreName}
          </ImageURL>
        </ImageContainer>
      )}
    </Container>
  );
}
