import React from "react";
import Order from "../../Order/View/Order";
import { AdminStore } from "../../../../pullstate/Admin";
import { useStoreState } from "pullstate";

export default function OrderList() {
  const { orders, filteredOrders, filterSearchTerm } = useStoreState(
    AdminStore,
    (s) => s.ordersStore
  );

  const orderSet = filterSearchTerm.length > 0 ? filteredOrders : orders;
  return (
    <>
      {orderSet.map((order, idx) => (
        <Order order={order} key={idx} />
      ))}
    </>
  );
}
