// ApiClientContext.js

import { ApiClient } from "@gahakapp/client";
import React, { createContext, useEffect, useState } from "react";

export const ApiClientContext = createContext<ApiClient>(new ApiClient());

export const ApiClientProvider = ({ children }) => {
  const apiClient = new ApiClient({
    BASE: process.env.REACT_APP_API_URL,
    HEADERS: {
      "Content-Type": "application/json",
    },
  });

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
};
