import React, { useContext, useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import { SigninFields, SignupAttrType } from "../../types/SignupFields";

import "./Signin.css";
import { useNavigate } from "react-router-dom";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../firebase/firebase.config";
import { ApiClientContext } from "../../context/ApiClientContext";
import { handleError } from "../../util/ErrorHandler";
import UseAuth from "../../hooks/UseAuth";
import { TextBox, TextBoxIconPosition } from "../../stitches/textbox/TextBox";
import { styled } from "@stitches/react";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { Body } from "../../stitches/typography/BodyStyles";
import { Button } from "../../stitches/button/Button";
import { useDeviceDimensions } from "../../hooks/useDevice";
import Spinner from "../../components/Spinner/Spinner";
import { Eye, EyeOff, Icon } from "react-feather";

const ImageContainer = styled("div", {
  width: "150px",
});

const Image = styled("img", {
  width: "100%",
  maxHeight: "90vh",
  margin: "auto",
});

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  height: "calc(100vh - 32px)",
  margin: "16px",
});

const TextContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const BodyText = styled(Body, {
  marginBottom: "32px",
  marginTop: "16px",
});

const Spacer = styled("div", {
  marginBottom: "32px",
});

const StyledButton = styled(Button, {
  marginBottom: "16px",
});

const PoweredBy = styled(Heading, {
  textAlign: "center",
  color: "$text_80",
});

const PoweredByMobile = styled(Body, {
  textAlign: "center",
  color: "$text_80",
});

const BigImageContainer = styled("div", {
  height: "100%",
  display: "flex",
  alignItems: "center",
});

export default function Signin() {
  const navigate = useNavigate();
  const { userStoreName } = UseAuth();

  useEffect(() => {
    if (userStoreName) navigate(`/${userStoreName}/seller/dashboard`);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [Icon, setIcon] = useState<Icon>(EyeOff);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninFields>();
  const apiClient = useContext(ApiClientContext);

  const { isDesktop } = useDeviceDimensions();

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setIcon(Icon === EyeOff ? Eye : EyeOff);
    setShowPassword((prevState) => !prevState);
  };

  const redirectToSignup = () => {
    navigate("/seller/signup");
  };

  const ResetPassword = () => {
    navigate("/seller/password/reset");
  };

  const onSubmit: SubmitHandler<SigninFields> = async (data) => {
    setIsLoading(true);
    try {
      const token = await apiClient.default.signin({
        data: {
          password: data.password,
          email: data.email.toLowerCase(),
        },
      });

      const userCredential = await signInWithCustomToken(auth, token);

      const storeName = await apiClient.default.getStoreNameById(
        userCredential.user.uid
      );

      if (!storeName) {
        navigate("/seller/profile");
      } else {
        navigate("/" + storeName + "/seller/dashboard");
      }
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Container>
      <TextContainer>
        <ImageContainer>
          <img src="/GahakHeader.png" width="100%" height="auto" />
        </ImageContainer>
        <div>
          <Heading variant={isDesktop ? "heading_06" : "heading_04"}>
            Login to Seller Portal
          </Heading>
          <BodyText variant={isDesktop ? "body_02" : "body_01"}>
            Seller Portal by Gahak allows you to manage all things related to
            your online store
          </BodyText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextBox
              label="Email"
              placeholder="Enter your email address"
              {...register(SignupAttrType.email, {
                required: "Input Email",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email format",
                },
              })}
              error={errors.email?.message}
            />
            <Spacer />
            <TextBox
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              {...register(SignupAttrType.password, {
                required: "Input password",
              })}
              error={errors.password?.message}
              icon={{
                position: TextBoxIconPosition.TRAILING,
                Icon,
                onIconClick: toggleShowPassword,
              }}
            />
            <Spacer />
            <StyledButton
              variant="secondary"
              size={isDesktop ? "large" : "small"}
              style={{ width: "100%" }}
              type="submit"
            >
              Login
            </StyledButton>
            <StyledButton
              variant="secondary"
              size={isDesktop ? "large" : "small"}
              outlined
              style={{ width: "100%" }}
              onClick={redirectToSignup}
            >
              Don't have an account?
            </StyledButton>
          </form>
        </div>
        {isDesktop ? (
          <PoweredBy variant="heading_03">Powered by Kaajus 2023</PoweredBy>
        ) : (
          <PoweredByMobile variant="body_mini_03">
            Powered by Kaajus 2023
          </PoweredByMobile>
        )}
      </TextContainer>
      {isDesktop && (
        <BigImageContainer>
          <Image src="/login.png" />
        </BigImageContainer>
      )}
    </Container>
  );
}
