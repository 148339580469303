import { Button } from "../../../../../stitches/button/Button";
import { Box, Edit3, Save, Trash, X } from "react-feather";
import { Display } from "../../../../../stitches/typography/DisplayStyles";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import Menu from "../../../../Menu/Menu";
import IconBadge from "../../../../../stitches/iconBadge/IconBadge";
import { useProductContainer } from "./useEditProductContainer";
import ProductVariants from "./ProductVariants";
import ProductImageContainer from "./ProductImageContainer";
import Spinner from "../../../../../components/Spinner/Spinner";
import { styled } from "../../../../../stitches/stitches.config";
import ExitWithoutSavingDialog from "./ExitWithoutSavingDialog";
import ProductFileUploader from "../../../../AddProduct/ProductContainer/ProductFileUploader";
import CancelChangesDialog from "./CancelChangesDialog";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  margin: "auto",
  height: "100vh",
  padding: "32px",
  boxSizing: "border-box",
  columnGap: "32px",
});

const MenuContainer = styled("div", {});

const ContentContainer = styled("div", {
  borderRadius: "10px",
  border: "3px solid #F0F0F0",
  padding: "30px  26px 0 26px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const HeaderContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ButtonsContainer = styled("div", {
  display: "flex",
  columnGap: "16px",
});

const HeaderTextIcon = styled("div", {
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
});

const HeaderText = styled(Display, {
  color: "$secondary_60",
  margin: "0",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const Form = styled("form", {
  width: "100%",
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const PersonalInfoHeaderIcon = styled(Box, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const Container = styled("div", {
  display: "flex",
  width: "100%",
  padding: "42px $space_10 0 $space_10",
  gap: "13px",
  justifyContent: "center",
  overflow: "hidden",
  height: "100%",
});

const CustomerInfoColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  padding: "0 20px 20px 0",
  width: "100%",
});

const ItemsInfoColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

const DeleteButton = styled(Button, {
  backgroundColor: "$colors$danger_100 !important",

  "&:hover": {
    backgroundColor: "$colors$danger_150 !important",
  },
  "&:active": {
    backgroundColor: "$colors$danger_50 !important",
  },
});

export default function EditProductContainer() {
  const {
    handleSubmit,
    onSubmit,
    register,
    unregister,
    uppyRef,
    watch,
    setHasVariantToTrue,
    isButtonDisabled,
    isLoading,
    navigateToProducts,
    handleExitWithoutSaving,
    handleCancelChanges,
    isExitModalOpen,
    isCancelChangesModalOpen,
    handleDialogClose,
    product,
    handleExitEditMode,
    setValue,
    deleteVariantsIdsRef,
    openExitWithoutSavingDialog,
    openCancelChangesDialog,
    handleSizeChartUpload,
  } = useProductContainer();

  if (!product) {
    navigateToProducts();
    return null;
  }

  return (
    <MainContainer>
      <MenuContainer>
        <Menu />
      </MenuContainer>
      {isLoading ? (
        <Spinner />
      ) : (
        <ContentContainer>
          <HeaderContainer>
            <HeaderTextIcon>
              <IconBadge
                onClick={openExitWithoutSavingDialog}
                iconName="ChevronLeft"
                size="large"
                outlined={false}
                customCSS={{
                  Icon: {
                    color: "$secondary_60",
                  },
                }}
              />
              <HeaderText variant="display_sm_01">Edit Mode</HeaderText>
            </HeaderTextIcon>

            <ButtonsContainer>
              <Button
                variant="secondary"
                size="small"
                withIcon="true"
                onClick={openCancelChangesDialog}
              >
                <X size={24} />
                Cancel
              </Button>

              <Button
                variant="primary"
                size="small"
                withIcon="true"
                onClick={handleSubmit(onSubmit)}
                disabled={isButtonDisabled}
              >
                <Save size={24} />
                Save
              </Button>
            </ButtonsContainer>
          </HeaderContainer>

          <Form>
            <Container>
              <CustomerInfoColumn>
                <PersonalInfoHeadingContainer>
                  <PersonalInfoHeaderIcon size={24} />
                  <PersonalInfoHeaderHeading variant="body_02">
                    Product Details
                  </PersonalInfoHeaderHeading>
                </PersonalInfoHeadingContainer>
                <Input
                  label="Name"
                  // defaultValue={product?.name}
                  {...register("name", {
                    required: "Required Field",
                  })}
                />
                <TextBox
                  label="Description"
                  // defaultValue={product?.description}
                  isMultiLine={true}
                  {...register("description", {
                    required: "Required Field",
                  })}
                />
                {product.sizeChart && (
                  <ProductFileUploader
                    handleSizeChart={handleSizeChartUpload}
                    imageurl={product.sizeChart?.url}
                  />
                )}
                <ProductVariants
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  setHasVariantToTrue={setHasVariantToTrue}
                  hasVariants={product.hasVariants}
                  variants={product.variants}
                  setValue={setValue}
                  deleteVariantsIdsRef={deleteVariantsIdsRef}
                />
              </CustomerInfoColumn>

              <ItemsInfoColumn>
                <ProductImageContainer
                  uppyRef={uppyRef}
                  images={product.images}
                />
              </ItemsInfoColumn>
            </Container>
          </Form>
        </ContentContainer>
      )}
      <CancelChangesDialog
        handleDialogClose={handleDialogClose}
        isDialogOpen={isCancelChangesModalOpen}
        handleExitDialog={handleCancelChanges}
      />
      <ExitWithoutSavingDialog
        handleDialogClose={handleDialogClose}
        isDialogOpen={isExitModalOpen}
        handleExitDialog={handleExitWithoutSaving}
      />
    </MainContainer>
  );
}
