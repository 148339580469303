import React from "react";
import "react-modern-drawer/dist/index.css";
import { useDeviceDimensions } from "../../hooks/useDevice";
import { AdminStore, resetAdminStore } from "../../pullstate/Admin";
import { styled } from "../../stitches/stitches.config";
import IconBadge from "../../stitches/iconBadge/IconBadge";
import Tab from "../../stitches/Tab/Tab";
import { Button } from "../../stitches/button/Button";
import { LogOut } from "react-feather";
import { useNavigate, useParams } from "react-router";
import { signOut } from "firebase/auth";
import { useLocation } from "react-router-dom";

import { auth } from "../../firebase/firebase.config";

const MenuContainer = styled("div", {
  background:
    "linear-gradient(180deg, rgba(74, 184, 207, 0.04) 0%, rgba(51, 156, 178, 0.13) 100%), #FFF",
  borderRadius: "10px",
  padding: "26px 16px 0 16px",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transform: "translateZ(0)",
});

const HeaderContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "46px",
});

const HeaderTextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const HeaderText1 = styled("span", {
  fontFamily: "Poppins",
  fontSize: "18.719px",
  fontStyle: "normal",
  fontWeight: 600,
  background: "$gradients$secondary_gradient",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  lineHeight: "1",
});

const HeaderText2 = styled("span", {
  fontFamily: "Poppins",
  fontSize: "32.758px",
  fontStyle: "normal",
  fontWeight: 400,
  color: "$text_60",
  lineHeight: "1",
});

const GahakLogo = styled("img", {
  width: "104.135px",
  height: "59px",
});

const LogoAndTextContainer = styled("div", {
  display: "flex",
  columnGap: "15px",
});

const TabsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  rowGap: "14px",
  overflowY: "auto",
  marginBottom: "calc(24px + 26px + 80px)",
});

const LogOutButton = styled(Button, {
  position: "fixed",
  bottom: "26px",
  width: "calc(100% - 32px)",
  left: "16px",
});

export default function Menu() {
  const { isMobile, isDesktop } = useDeviceDimensions();
  const { storeName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const initiateSignOut = async () => {
    signOut(auth).then(() => {
      resetAdminStore();
      navigate(`/seller/signin`);
    });
  };

  const navigateToDashboard = () => {
    if (!isDesktop) {
      AdminStore.update((s) => {
        s.isMenuOpen = false;
      });
    }
    navigate(`/${storeName}/seller/dashboard`);
  };

  const navigateToOrders = () => {
    if (!isDesktop) {
      AdminStore.update((s) => {
        s.isMenuOpen = false;
      });
    }
    navigate(`/${storeName}/seller/order`);
  };

  const navigateToProducts = () => {
    if (!isDesktop) {
      AdminStore.update((s) => {
        s.isMenuOpen = false;
      });
    }
    navigate(`/${storeName}/seller/products`);
  };

  const navigateToEditCredentials = () => {
    if (!isDesktop) {
      AdminStore.update((s) => {
        s.isMenuOpen = false;
      });
    }
    navigate(`/${storeName}/seller/credentials/edit`);
  };

  const navigateToViewStore = () => {
    if (!isDesktop) {
      AdminStore.update((s) => {
        s.isMenuOpen = false;
      });
    }
    window.open(`https://www.gahakapp.com/${storeName}`, "_blank");
  };

  return (
    <MenuContainer>
      <HeaderContainer>
        <LogoAndTextContainer>
          <GahakLogo src="/GahakHeader.png" />
          <HeaderTextContainer>
            <HeaderText1>Seller</HeaderText1>
            <HeaderText2>Portal</HeaderText2>
          </HeaderTextContainer>
        </LogoAndTextContainer>
        {!isDesktop && (
          <IconBadge
            outlined={false}
            size="medium"
            iconName="X"
            customCSS={{
              Icon: {
                color: "secondary_60",
              },
              IconBadgeContainer: {
                background: "transparent",
              },
            }}
            onClick={() => {
              AdminStore.update((s) => {
                s.isMenuOpen = false;
              });
            }}
          />
        )}
      </HeaderContainer>

      <TabsContainer>
        <Tab
          iconName="Monitor"
          size="small"
          onClick={navigateToDashboard}
          selected={location.pathname === `/${storeName}/seller/dashboard`}
        >
          Dashboard
        </Tab>
        <Tab
          iconName="Truck"
          size="small"
          onClick={navigateToOrders}
          selected={location.pathname === `/${storeName}/seller/order`}
        >
          Orders
        </Tab>
        <Tab
          iconName="BookOpen"
          size="small"
          onClick={navigateToProducts}
          selected={location.pathname === `/${storeName}/seller/products`}
        >
          Products
        </Tab>
        <Tab
          iconName="User"
          size="small"
          onClick={navigateToEditCredentials}
          selected={
            location.pathname === `/${storeName}/seller/credentials/edit`
          }
        >
          Change Password
        </Tab>
        <Tab iconName="ShoppingBag" size="small" onClick={navigateToViewStore}>
          View Store
        </Tab>
      </TabsContainer>

      <LogOutButton
        variant="primary"
        outlined
        size="small"
        withIcon
        onClick={initiateSignOut}
      >
        <LogOut size={24} />
        Log out
      </LogOutButton>
    </MenuContainer>
  );
}
