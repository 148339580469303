export enum SignupAttrType {
  email = "email",
  password = "password",
  passwordConfirmation = "passwordConfirmation",
}

export interface SignupFields {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export interface VerificationCodeFields {
  email: number;
}

export type Signup = {
  statusCode: number;
  message?: string;
  user?: string;
};

export interface SigninFields {
  password: string;
  email: string;
}

export type SigninDetails = {
  statusCode: number;
  message?: string;
  user: string | undefined;
};
