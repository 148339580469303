import { styled } from "../stitches.config";
import { bodyStyles } from "../typography/BodyStyles";
import { CSSProperties, useEffect, useState } from "react";
import * as FeatherIcons from "react-feather";

export const BadgeText = styled("p", {
  ...bodyStyles.body_mini_03,
  margin: "0",
  textAlign: "center",
  color: "$secondary_20",
});

export const Badge = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  right: "0",
  bottom: "0",
  background: "$primary_100",
  borderRadius: "$radii$ROUND",
  width: "20px",
  height: "20px",
});

const getIcon = (IconName: string) => {
  // Retrieve the Icon Component using the Icon name
  const Icon: FeatherIcons.Icon = FeatherIcons[IconName];

  return styled(Icon, {
    color: "$colors$secondary_40",

    variants: {
      size: {
        small: {
          width: "24px",
          height: "24px",
        },
        medium: {
          width: "31.99px",
          height: "31.99px",
        },
        large: {
          width: "40px",
          height: "40px",
        },
      },
      disabled: {
        true: {
          color: "$colors$grey_50",
        },
      },
    },
  });
};

export const IconContainer = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "$space$space_10",
  boxSizing: "border-box",

  variants: {
    outlined: {
      true: {
        border: "2px solid $colors$grey_50",
      },
    },
    size: {
      small: {
        width: "44px",
        height: "44px",
      },
      medium: {
        width: "47.99px",
        height: "47.99px",
      },
      large: {
        width: "56px",
        height: "56px",
      },
    },
  },
});

export const IconBadgeContainer = styled("button", {
  position: "relative",
  width: "fit-content",
  borderRadius: "$space$space_10",
  border: "none",
  padding: "0",
  backgroundColor: "$white_100",

  "&:active": { backgroundColor: "$grey_50" },
  "&:disabled": { backgroundColor: "$grey_100" },

  "@Desktop": {
    "&:hover": { backgroundColor: "$grey_100" },
    "&:active": { backgroundColor: "$grey_50" }, //TODO For some reason I have to do this again to make it work in Desktop view.
  },
});

type ValidCSSProperties = keyof CSSProperties;

interface IconBadgeCSS {
  IconBadgeContainer?: {
    [key in ValidCSSProperties]?: CSSProperties[key];
  };
  Icon?: {
    [key in ValidCSSProperties]?: CSSProperties[key];
  };
}

export interface IIconBadgeProps
  extends React.ComponentProps<typeof IconBadgeContainer> {
  badgeValue?: number | undefined;
  outlined: boolean;
  size: "small" | "medium" | "large";
  iconName: string;
  disabled?: boolean;
  customCSS?: IconBadgeCSS;
}

export default function IconBadge({
  badgeValue,
  iconName,
  outlined,
  size,
  disabled,
  customCSS,
  ...props
}: IIconBadgeProps) {
  const [Icon, setIcon] = useState<ReturnType<typeof getIcon> | undefined>();

  useEffect(() => {
    setIcon(getIcon(iconName));
  }, []);

  if (!Icon) {
    return null;
  }

  return (
    <IconBadgeContainer
      css={{ ...customCSS?.IconBadgeContainer }}
      disabled={disabled}
      {...props}
    >
      <IconContainer outlined={outlined} size={size}>
        <Icon css={{ ...customCSS?.Icon }} size={size} disabled={disabled} />
      </IconContainer>
      {badgeValue && (
        <Badge>
          <BadgeText>{badgeValue}</BadgeText>
        </Badge>
      )}
    </IconBadgeContainer>
  );
}
