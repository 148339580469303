import React from "react";
import AddressCard from "./AddressCard/AddressCard";
import ItemsCard from "./ItemsCard/ItemsCard";
import { Footer } from "./Footer/Footer";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import PaymentTypeCard from "./PaymentTypeCard/PaymentTypeCard";
import PersonalInfoCard from "./PersonalInfoCard/PersonalInfoCard";
import { styled } from "../../../stitches/stitches.config";

interface IOrderReviewProps {
  handleOnClick: () => void;
  navigateBackToPayment: () => void;
}

const OrderReviewHeading = styled(Heading, {
  marginBottom: "20px !important",
});

const OrderReviewBody = styled("div", {
  width: "100%",
  padding: "$space$space_16 $space$space_16 $space$space_16 $space$space_16",
  height: "calc(100% - 142px)",
  overflow: "auto",
});

export default function OrderReview({
  handleOnClick,
  navigateBackToPayment,
}: IOrderReviewProps) {
  return (
    <>
      <OrderReviewBody>
        <OrderReviewHeading variant="heading_02">
          Review Order
        </OrderReviewHeading>
        <PersonalInfoCard/>
        <AddressCard />
        <PaymentTypeCard />
        <ItemsCard />
      </OrderReviewBody>
      <Footer
        onClick={handleOnClick}
        navigateBackToPayment={navigateBackToPayment}
      />
    </>
  );
}
