import React, { useEffect } from "react";
import ProductItemAttributeList from "./ProductItemAttributeList";
import { useProductFront } from "./useProductFront";
import StoreHeader from "../StoreHeader/View/StoreHeader";
import Spinner from "../../../components/Spinner/Spinner";
import ProductTitle from "./ProductTitle";
import ProductDescription from "./ProductDescription";
import ProductFooter from "./ProductFooter";
import { StoreLayout } from "../StoreLayout";
import ProductCartToast from "./ProductCartToast";
import ProductQuantity from "./ProductQuantity";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ImageGallery.css";
import {
  useCheckTabScreen,
  useDeviceDimensions,
} from "../../../hooks/useDevice";
import { styled } from "../../../stitches/stitches.config";
import ProductSizeChart from "./ProductSizeChart";
import StoreNotFound from "../Storeview/view/StoreNotFoundview";

const ProductContent = styled("div", {
  flex: "1",
  padding: "0 16px 0 16px",
});

const ImageGalleryContainer = styled("div", {
  flex: "1",
  padding: "0 16px 0 16px",
  marginBottom: "20px",
});

const ProductQuantityContainer = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
});

export default function ProductFront() {
  const {
    product,
    attributes,
    selectedVariant,
    handleOnAttributeClick,
    addItemToCart,
    proceedToCheckout,
    open,
    setOpen,
    selectedQuantity,
    setSelectedQuantity,
    productCartToastQuantity,
    carouselImages,
    isLoading,
    storeFound,
  } = useProductFront();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isTabScreen = useCheckTabScreen();

  const thumbnailPosition = isTabScreen ? "right" : "bottom";

  const { isDesktop: isDesktopView, isMobile } = useDeviceDimensions();

  if (isLoading || storeFound === undefined) return <Spinner />;

  if (!storeFound) {
    return <StoreNotFound />;
  }

  return !product || !selectedVariant ? null : (
    <>
      <StoreHeader showBackArrow={true} />
      <StoreLayout>
        <ImageGalleryContainer>
          <ImageGallery
            items={carouselImages.current}
            showPlayButton={false}
            useBrowserFullscreen={false}
            autoPlay={false}
            thumbnailPosition={thumbnailPosition}
            showNav={isMobile ? false : true}
            disableThumbnailScroll={false}
            disableSwipe={false}
            showFullscreenButton={true}
            showIndex={true}
          />
        </ImageGalleryContainer>
        <ProductContent>
          <ProductTitle title={product.name} price={selectedVariant.price} />
          <ProductItemAttributeList
            attributes={attributes}
            selectedAttributes={selectedVariant.attributes}
            handleOnClick={handleOnAttributeClick}
          />
          <ProductQuantityContainer>
            <ProductQuantity
              selectedQuantity={selectedQuantity}
              selectedVariant={selectedVariant}
              setSelectedQuantity={setSelectedQuantity}
            ></ProductQuantity>
            {!!product?.sizeChart && (
              <ProductSizeChart sizeChart={product.sizeChart} />
            )}
          </ProductQuantityContainer>
          <ProductDescription>{product.description}</ProductDescription>
          {isDesktopView && (
            <ProductFooter
              addItemToCart={addItemToCart}
              proceedToCheckout={proceedToCheckout}
            />
          )}
        </ProductContent>
      </StoreLayout>
      {!isDesktopView && (
        <ProductFooter
          addItemToCart={addItemToCart}
          proceedToCheckout={proceedToCheckout}
        />
      )}
      <ProductCartToast
        open={open}
        setOpen={setOpen}
        product={product}
        variant={selectedVariant}
        selectedQuantity={productCartToastQuantity}
      />
    </>
  );
}
