import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import UseAuth from "../../../hooks/UseAuth";
import { Step } from "../EditCredentials";
import { ApiClientContext } from "../../../context/ApiClientContext";
import { UpdateCredentialsVerification } from "@gahakapp/client";
import { handleError } from "../../../util/ErrorHandler";
import { styled } from "../../../stitches/stitches.config";
import { Button } from "../../../stitches/button/Button";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { TextBox } from "../../../stitches/textbox/TextBox";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { Body } from "../../../stitches/typography/BodyStyles";

type EditEmailProps = {
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

type EmailForm = {
  email: string;
};

type VCodeForm = {
  vCode: string;
};

const Header = styled("div", {
  display: "flex",
  columnGap: "$space$space_10",
  width: "100%",
  padding: "24px $space$space_16 $space$space_16 $space$space_16",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  marginBottom: "10px",
});

const HeaderTitle = styled(Heading, {
  display: "flex",
  alignItems: "center",
});

const Input = styled(TextBox, {
  width: "100%",
});

const Form = styled("form", {
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  margin: "0 20px",
});

const StyledButton = styled(Button, {
  margin: "20px 20px",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const PreviousEmailText = styled(Body, {
  paddingBottom: "20px",
});

export default function EditEmail({
  setStep,
  email,
  setEmail,
}: EditEmailProps) {
  const {
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
    register: registerEmail,
  } = useForm<EmailForm>({});

  const {
    handleSubmit: handleSubmitVCode,
    formState: { errors: errorsVCode },
    register: registerVCode,
  } = useForm<VCodeForm>();

  const [showVerification, setShowVerification] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { storeName } = useParams();
  const { user } = UseAuth();
  const apiClient = useContext(ApiClientContext);
  const newEmailRef = useRef<string>("");

  const onSubmitEmail = async (data: EmailForm) => {
    try {
      setIsLoading(true);

      if (email === data.email) {
        setStep(Step.View);
        return;
      }

      await apiClient.default.updateCredentials(storeName || "", user!.uid, {
        data,
      });

      newEmailRef.current = data.email;
      setShowVerification(true);
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const onVerifyCode = async (vCodeData: VCodeForm) => {
    try {
      setIsLoading(true);

      const data: UpdateCredentialsVerification = {
        code: {
          email: Number(vCodeData.vCode),
        },
      };

      await apiClient.default.verifyNewCredentials(storeName || "", user!.uid, {
        data,
      });

      setEmail(newEmailRef.current);
      setStep(Step.View);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Container>
      <Header>
        <IconBadge
          outlined={false}
          size="medium"
          iconName="ChevronLeft"
          customCSS={{
            Icon: {
              color: "$black_100",
            },
          }}
          onClick={() => setStep(Step.View)}
        />
        <HeaderTitle variant="heading_04">Back</HeaderTitle>
      </Header>
      <h2>Edit Email</h2>
      {showVerification ? (
        <>
          <Form
            id="verificationCodeForm"
            onSubmit={handleSubmitVCode(onVerifyCode)}
          >
            <Input
              label="Verification Code"
              placeholder="E.g. 1234"
              {...registerVCode("vCode", {
                required: "Required Field",
              })}
              type="number"
              error={errorsVCode.vCode?.message}
            />
          </Form>
          <StyledButton
            form="verificationCodeForm"
            variant="secondary"
            size="large"
            type="submit"
          >
            Verify
          </StyledButton>
        </>
      ) : (
        <>
          <PreviousEmailText variant="body_01">
            <strong>Current email:</strong> {email}
          </PreviousEmailText>

          <Form id="emailForm" onSubmit={handleSubmitEmail(onSubmitEmail)}>
            <Input
              label="New Email"
              placeholder="E.g. abc@gmail.com"
              {...registerEmail("email", {
                required: "Required field",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email format",
                },
              })}
              error={errorsEmail.email?.message}
            />
          </Form>
          <StyledButton
            form="emailForm"
            variant="secondary"
            size="large"
            type="submit"
          >
            Save
          </StyledButton>
        </>
      )}
    </Container>
  );
}
