import React from "react";
import { Image as FeatherImage } from "react-feather";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./Uppy.css";
import { Image } from "../../../../../types/product/new/product.model";
import ProductImage from "./ProductImage";
import { styled } from "../../../../../stitches/stitches.config";

const StickyButtonContainer = styled("div", {
  bottom: "50px",
  backgroundColor: "$white_100",
  filter: "drop-shadow($space$space_0 5px 22px rgba(0, 0, 0, 0.1))",
  borderRadius: "10px",
  overflowY: "auto",
  width: "100%",

  "@Desktop": {
    width: "374px",
  },
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const ImageHeaderIcon = styled(FeatherImage, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

interface IDesktopProductImageProps {
  uppyRef: React.MutableRefObject<Uppy>;
  images?: Image[];
}

const ProductImages = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(165px, 1fr))",
  gap: "10px",
  padding: "0 10px 10px 10px",
});

const ImageContainer = styled("div", {
  padding: "0 10px 10px 10px",
});

export default function ProductImageContainer({
  uppyRef,
  images,
}: IDesktopProductImageProps) {
  if (!images) {
    return null;
  }
  const hasMultipleImages = images.length > 1;

  return (
    <StickyButtonContainer>
      <PersonalInfoHeadingContainer>
        <ImageHeaderIcon size={24} />
        <PersonalInfoHeaderHeading variant="body_02">
          Images
        </PersonalInfoHeaderHeading>
      </PersonalInfoHeadingContainer>
      {hasMultipleImages ? (
        <ProductImages>
          {images?.map((image, idx) => (
            <ProductImage image={image} key={idx} />
          ))}
        </ProductImages>
      ) : (
        <ImageContainer>
          <ProductImage image={images[0]} />
        </ImageContainer>
      )}
    </StickyButtonContainer>
  );
}
