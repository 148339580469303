import React from "react";
import { useStoreState } from "pullstate";
import { CustomerStore } from "../../../../pullstate/Customer";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import { styled } from "@stitches/react";

const OrderTotalContainer = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  padding: "0 $space$space_0 $space$space_0 $space$space_0",
});

const OrderTotalLabelHeading = styled(Heading, {
  marginTop: "12px !important",
});

const OrderTotalValueHeading = styled(Heading, {
  color: "$secondary_60",
});

interface IOrderTotal {
  orderTotal: number;
}

export default function OrderTotal({ orderTotal }: IOrderTotal) {
  return (
    <OrderTotalContainer>
      <OrderTotalLabelHeading variant="heading_02">{`Total(incl tax)`}</OrderTotalLabelHeading>
      <OrderTotalValueHeading variant="heading_04">
        Rs. {orderTotal}
      </OrderTotalValueHeading>
    </OrderTotalContainer>
  );
}
