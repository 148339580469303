import React from "react";
import StoreProduct from "./StoreProduct";
import { useStoreState } from "pullstate";
import { CustomerStore } from "../../../../pullstate/Customer";
import { styled } from "../../../../stitches/stitches.config";
import { Product } from "../../../../types/product/new/product.model";

const Grid = styled("div", {
  display: "grid",
  columnGap: "32px",
  rowGap: "$space$space_16",

  "@Mobile": {
    gridTemplateColumns: "repeat(2, minmax(0,1fr))",
  },
  "@Tablet": {
    gridTemplateColumns: "repeat(3, minmax(0,1fr))",
  },
  "@Desktop": {
    gridTemplateColumns: "repeat(4,minmax(0,1fr))",
  },
  "@Wide": {
    gridTemplateColumns: "repeat(5,minmax(0,1fr))",
  },
});

interface StoreProductListProps {
  products: Product[];
}
export default function StoreProductList({ products }: StoreProductListProps) {
  return (
    <Grid>
      {products.map((product, idx) => (
        <StoreProduct product={product} key={idx} />
      ))}
    </Grid>
  );
}
