import { Image } from "../types/product/new/product.model";
import s3 from "../aws/aws.config";
import { v4 as uuid } from "uuid";

export async function getAwsS3ImageUrls(
  storeName: string,
  images: File[]
): Promise<Image[]> {
  const cloudFrontImages: Promise<Image>[] = images.map(async (image) => {
    const imageKey = generateImageKey(storeName, image);
    const uploadParams: AWS.S3.PutObjectRequest = {
      Bucket: "mygahakappbucket",
      Key: imageKey,
      Body: image,
      ContentType: "image/jpeg",
    };

    try {
      await s3.upload(uploadParams).promise();
      const cloudFrontDistributionDomain =
        process.env.REACT_APP_CLOUDFRONT_NAME;
      const url = `https://${cloudFrontDistributionDomain}/${imageKey}`;
      const alt = "product Image";
      return {
        url,
        alt,
      };
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  });

  return Promise.all(cloudFrontImages);
}

export async function getAwsS3ImageWithThumbnails(
  storeName: string,
  images: {
    compressedImage: File;
    thumbnail: File;
  }[]
): Promise<Image[]> {
  const cloudFrontImages: Promise<Image>[] = images.map(
    async ({ compressedImage, thumbnail }) => {
      const imageKey = generateImageKey(storeName, compressedImage);
      const thumbnailKey = generateImageKey(storeName, thumbnail);

      const uploadParamsImage: AWS.S3.PutObjectRequest = {
        Bucket: "mygahakappbucket",
        Key: imageKey,
        Body: compressedImage,
        ContentType: "image/jpeg",
      };

      const uploadParamsThumbnail: AWS.S3.PutObjectRequest = {
        Bucket: "mygahakappbucket",
        Key: thumbnailKey,
        Body: thumbnail,
        ContentType: "image/jpeg",
      };

      return Promise.all([
        s3.upload(uploadParamsImage).promise(),
        s3.upload(uploadParamsThumbnail).promise(),
      ])
        .then(() => {
          const cloudFrontDistributionDomain =
            process.env.REACT_APP_CLOUDFRONT_NAME;
          const url = `https://${cloudFrontDistributionDomain}/${imageKey}`;
          const thumbnail = `https://${cloudFrontDistributionDomain}/${thumbnailKey}`;
          const alt = "product Image";

          return {
            url,
            alt,
            thumbnail,
          };
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          throw error;
        });
    }
  );

  return Promise.all(cloudFrontImages);
}

const generateImageKey = (storeName: string, image: File): string => {
  const imageType = image.type.split("/")[1];
  const correctedImageType = imageType === "jpg" ? "jpeg" : imageType;
  const imageKey = `${storeName}/${generateUniqueImageID(
    image.name.split(".")[0]
  )}.${correctedImageType}`; // Define your own key structure

  return imageKey;
};

export async function deleteAwsS3Images(
  storeName: string,
  imagesNamesWithExtension: string[]
): Promise<void> {
  const deletePromises = imagesNamesWithExtension.map(
    (imageNameWithExtension) => {
      const imageKey = `${storeName}/${imageNameWithExtension}`;

      const deleteParams: AWS.S3.DeleteObjectRequest = {
        Bucket: "mygahakappbucket",
        Key: imageKey,
      };

      return s3.deleteObject(deleteParams).promise();
    }
  );

  try {
    await Promise.all(deletePromises);
  } catch (error) {
    console.error("Error deleting images:", error);
  }
}

const generateUniqueImageID = (imageName: string): string => {
  return `${imageName}-${uuid()}`;
};
