import { styled } from "../../stitches/stitches.config";

export const StoreLayout = styled("div", {
  margin: "0 $space$space_16",

  "@Tablet": {
    padding: "0 46px",
  },

  "@Desktop": {
    display: "flex",
    flexDirection: "row",
  }
});
