import React from "react";
import {
  UseFormRegister,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { ProductFields } from "../../../../../types/ProductFields";
import { styled } from "@stitches/react";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { Archive, Copy, Plus, Trash } from "react-feather";
import { Button } from "../../../../../stitches/button/Button";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import ProductVariantDialog from "./ProductVariantDialog";
import _ from "lodash";
import { Variant } from "../../../../../types/product/new/product.model";

interface IDesktopProductVariantsProps {
  register: UseFormRegister<ProductFields>;
  unregister: UseFormUnregister<ProductFields>;
  watch: UseFormWatch<ProductFields>;
  setHasVariantToTrue: () => void;
  hasVariants: boolean;
  variants?: Variant[];
}

const InputWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "$space$space_16",
  alignItems: "center",
  marginBottom: "$space$space_20",
});

const DeleteIcon = styled(Trash, {
  width: "24px",
  height: "24px",
  color: "$danger_100",
  transform: "translateY(10px)",
  cursor: "pointer",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const VariantHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  alignItems: "center",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
  width: "100%",
});

const VariantHeadingIconContainer = styled(Archive, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const VariantHeadingHeader = styled(Body, {
  color: "$secondary_60",
});

const StyledTextBox = styled(TextBox, {
  width: "100%",
});

export default function ProductVariants({
  register,
  unregister,
  watch,
  setHasVariantToTrue,
  hasVariants,
  variants,
}: IDesktopProductVariantsProps) {
  const [indexes, setIndexes] = React.useState<number[]>([0]);
  const [counter, setCounter] = React.useState(1);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isVariantFieldSelected, setIsVariantFieldSelected] =
    React.useState(false);
  const attributeLabel = variants?.[0].attributes[0]?.name;

  const addVariant = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeVariant = (index: number) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
    if (counter - 1 === 0) {
      setIsVariantFieldSelected(false);
    }
    unregister(`variants.${index}.attributes.${0}.value`);
    unregister(`variants.${index}.quantity`);
    unregister(`variants.${index}.price`);
  };

  const handleVariantButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSuccessDialogClose = () => {
    setIsDialogOpen(false);
    setIsVariantFieldSelected(true);
    setCounter(1);
    setIndexes([0]);
    setHasVariantToTrue();
  };

  return (
    <>
      <VariantHeadingContainer>
        <VariantHeadingIconContainer size={24} />
        <VariantHeadingHeader variant="body_02">
          Inventory Details
        </VariantHeadingHeader>
      </VariantHeadingContainer>
      {hasVariants ? (
        <>
          {variants?.map((variant, index) => (
            <InputWrapper key={index}>
              <StyledTextBox
                label={_.capitalize(attributeLabel)}
                placeholder="Value"
                disabled={true}
                value={variant.attributes[0].value}
              />
              <StyledTextBox
                label="Price (PKR)"
                placeholder="Enter price"
                disabled={true}
                value={variant.price}
              />
              <StyledTextBox
                label="Quantity"
                placeholder="Enter quantity"
                disabled={true}
                value={variant.quantity}
              />
              {/* <DeleteIcon onClick={removeVariant(index)}>
                Remove Variant
              </DeleteIcon> */}
            </InputWrapper>
          ))}
          {/* <Button
            type="button"
            onClick={addVariant}
            variant="secondary"
            size="small"
            withIcon
          >
            <Plus size={24} />
            Add Variant
          </Button> */}
        </>
      ) : (
        <InputWrapper>
          <Input
            label="Price (PKR)"
            placeholder="Enter Price"
            disabled={true}
            value={variants?.[0].price}
            type="number"
          />
          <Input
            label="Quantity"
            placeholder="Enter quantity"
            disabled={true}
            value={variants?.[0].quantity}
            type="number"
          />
        </InputWrapper>
      )}

      {/* <ProductVariantDialog
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleDialogClose}
        register={register}
        unregister={unregister}
        handleSuccessDialogClose={handleSuccessDialogClose}
        isEmpty={!!attributeLabel?.length}
      /> */}
    </>
  );
}
