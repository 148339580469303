import React from "react";
import Profile from "./admin/Profile/view/Profile";
import Signin from "./admin/Signin/Signin";
import SignupForm from "./admin/Signup/SignupForm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Storeview from "./customer/StoreFront/Storeview/view/Storeview";
import ProtectedRoute from "./util/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import LineChart from "./admin/Sales/View/LineChart";
import Orders from "./admin/Orders/View/Orders";
import DashboardProducts from "./admin/Products/View/Products";
import Checkout from "./customer/Checkout/View/Checkout";
import Dashboard from "./admin/Dashboard/Dashboard";
import OrderPlaced from "./customer/Checkout/OrderPlaced/OrderPlaced";
import ProductContainer from "./admin/AddProduct/ProductContainer/ProductContainer";
import Modal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditCredentials from "./admin/EditCredentials/EditCredentials";
import EditProfile from "./admin/EditProfile/EditProfile";
import ResetPassword from "./admin/ResetPassword/ResetPassword";
import ProductFront from "./customer/StoreFront/ProductFront/ProductFront";
import OrderDetails from "./admin/Orders/OrderDetails/OrderDetails";
import { ApiClientProvider } from "./context/ApiClientContext";
import AdminProductDetails from "./admin/Products/Product/ProductDetails/View/ProductContainer";
import { styled } from "./stitches/stitches.config";
import Landing from "./common/landing/main/Landing";
import ResourceNotFound from "./customer/StoreFront/Storeview/view/StoreNotFoundview";
import DocumentTitleUpdater from "./util/DocumentTitleUpdater";
import AdminLayout from "./admin/AdminLayout/AdminLayout";
import EditProduct from "./admin/Products/Product/ProductDetails/EditProduct/EditProductContainer";
import { QueryClient, QueryClientProvider } from "react-query";

Modal.setAppElement("#root"); // set the app element to the root div

const StyledToastContainer = styled(ToastContainer, {
  "--toastify-font-family": "Poppins, sans-serif",
});

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ApiClientProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <DocumentTitleUpdater>
                <Routes>
                  <Route path="/" element={<Landing />} />

                  <Route
                    path="/seller/*"
                    element={
                      <Routes>
                        <Route path="signup" element={<SignupForm />} />
                        <Route path="signin" element={<Signin />} />
                        <Route
                          path="password/reset"
                          element={<ResetPassword />}
                        />
                        <Route
                          path="profile"
                          element={
                            <ProtectedRoute>
                              <Profile />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="*" element={<ResourceNotFound />} />
                      </Routes>
                    }
                  />

                  <Route
                    path="/:storeName/seller/*"
                    element={
                      <ProtectedRoute>
                        <AdminLayout>
                          <Routes>
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route
                              path="credentials/edit"
                              element={<EditCredentials />}
                            />
                            <Route
                              path="products"
                              element={<DashboardProducts />}
                            />
                            <Route
                              path="product/:id"
                              element={<AdminProductDetails />}
                            />
                            <Route
                              path="product/edit/:id"
                              element={<EditProduct />}
                            />
                            <Route path="profile" element={<Profile />} />
                            <Route
                              path="profile/edit"
                              element={<EditProfile />}
                            />
                            <Route
                              path="product/new"
                              element={<ProductContainer />}
                            />
                            <Route path="sales" element={<LineChart />} />
                            <Route path="order" element={<Orders />} />
                            <Route
                              path="order/:orderId"
                              element={<OrderDetails />}
                            />
                            <Route path="*" element={<ResourceNotFound />} />
                          </Routes>
                        </AdminLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/:storeName/*"
                    element={
                      <Routes>
                        <Route index element={<Storeview />} />
                        <Route path="product/:id" element={<ProductFront />} />
                        <Route path="checkout" element={<Checkout />} />
                        <Route
                          path="order_confirmed"
                          element={<OrderPlaced />}
                        />
                        <Route path="*" element={<ResourceNotFound />} />
                      </Routes>
                    }
                  />
                </Routes>
              </DocumentTitleUpdater>
            </Router>
            <StyledToastContainer />
          </QueryClientProvider>
        </ApiClientProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
