import React, { useState } from "react";
import { CartProduct } from "../../../../types/CartFields";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { styled } from "@stitches/react";
import { IOrderProduct } from "./OrderSummary";
import { Blurhash } from "react-blurhash";
import { DEFAULT_BLURHASH } from "../../../../constants/Constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ItemContainer = styled("div", {
  width: "100%",
  height: "144px",
  boxSizing: "border-box",
  padding: "$space$space_10",
  display: "flex",
  columnGap: "$space$space_16",
});

const ItemImageContainer = styled("div", {
  height: "100%",
  position: "relative",
  overflow: "hidden",
  borderRadius: "$space$space_10",
  boxSizing: "content-box",
  width: "94px",

  "@media (min-width: 1200px)": {
    width: "200px",
  },
});

const ItemImage = styled(LazyLoadImage, {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "transform 0.5s ease-in-out",
  opacity: 0,
});

const ItemNameQuantityPrice = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "167px",
  rowGap: "8px",
});

const ItemPriceHeading = styled(Heading, {
  color: "$secondary_60",
});

interface IItemProp {
  item: IOrderProduct;
}
export default function Item({ item }: IItemProp) {
  const { product, quantity: selectedQuantity } = item;
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!product) {
    return null;
  }

  const { name, images, hasVariants, variants } = product;
  const { attributes, price } = variants[0];
  return (
    <ItemContainer>
      <ItemImageContainer>
        <Blurhash
          hash={DEFAULT_BLURHASH}
          width={200}
          height={124}
          resolutionX={32}
          resolutionY={32}
          punch={1}
          style={{
            opacity: imageLoaded ? 0 : 1,
          }}
        />
        <ItemImage
          src={images[0].url}
          alt="image"
          loading="lazy"
          effect="blur"
          onLoad={() => setImageLoaded(true)}
          style={{ opacity: imageLoaded ? 1 : 0 }}
          width="100%"
          height="100%"
        />
      </ItemImageContainer>
      <ItemNameQuantityPrice>
        <Heading variant="heading_02">{name}</Heading>
        {hasVariants && (
          <Body variant="body_01">
            {attributes[0].name}: {attributes[0].value}
          </Body>
        )}
        <Body variant="body_01">Qty: {selectedQuantity}</Body>
        <ItemPriceHeading variant="heading_02">Rs. {price}</ItemPriceHeading>
      </ItemNameQuantityPrice>
    </ItemContainer>
  );
}
