import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Image } from "@gahakapp/client";
import { Blurhash } from "react-blurhash";
import { DEFAULT_BLURHASH } from "../../../constants/Constants";
import { styled } from "@stitches/react";
import { Body } from "../../../stitches/typography/BodyStyles";
import { Button } from "../../../stitches/button/Button";
import { useDeviceDimensions } from "../../../hooks/useDevice";
interface IProductSizeChartProps {
  sizeChart: Image;
}

export const StoreImg = styled("img", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
  transition: "transform 0.5s ease-in-out",
  opacity: 0,
});

const StoreImageContainer = styled("div", {
  position: "relative",
  borderRadius: "$space$space_10",
  border: "1px solid $grey_50",
  overflowX: "hidden",
});

const StyledBody = styled(Body, {
  color: "$secondary_60",
});

export default function ProductSizeChart({
  sizeChart,
}: IProductSizeChartProps) {
  const [isSizeChartOpen, setIsSizeChartOpen] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { isDesktop, isTablet } = useDeviceDimensions();
  return (
    <>
      {isDesktop || isTablet ? (
        <Button
          variant="secondary"
          onClick={() => setIsSizeChartOpen(true)}
          size="small"
          outlined
          style={{ marginTop: "26px" }}
        >
          Size Chart
        </Button>
      ) : (
        <StyledBody
          variant="body_02"
          onClick={() => setIsSizeChartOpen(true)}
          style={{
            marginTop: "26px",
          }}
        >
          Size Chart
        </StyledBody>
      )}

      <Dialog
        open={isSizeChartOpen}
        keepMounted
        onClose={() => setIsSizeChartOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
      >
        <DialogTitle>
          <Heading variant="heading_02">Size Chart</Heading>
        </DialogTitle>
        <DialogContent>
          <StoreImageContainer>
            <Blurhash
              hash={DEFAULT_BLURHASH}
              width="80vw"
              {...((isDesktop || isTablet) && { height: "80vh" })}
              resolutionX={32}
              resolutionY={32}
              punch={1}
              style={{
                opacity: imageLoaded ? 0 : 1,
              }}
            />
            <StoreImg
              src={sizeChart.url}
              alt="size chart"
              loading="eager"
              onLoad={() => setImageLoaded(true)}
              style={{ opacity: imageLoaded ? 1 : 0 }}
              width="100%"
              height="100%"
            />
          </StoreImageContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
