import "./Signup.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { VerificationCodeFields } from "../../types/SignupFields";
import { useContext, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { auth } from "../../firebase/firebase.config";
import { signInWithCustomToken } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ApiClientContext } from "../../context/ApiClientContext";
import { handleError } from "../../util/ErrorHandler";
import { styled } from "@stitches/react";
import { Body } from "../../stitches/typography/BodyStyles";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { Button } from "../../stitches/button/Button";
import { useDeviceDimensions } from "../../hooks/useDevice";
import { TextBox } from "../../stitches/textbox/TextBox";
const ImageContainer = styled("div", {
  width: "150px",
});

const Image = styled("img", {
  width: "100%",
  maxHeight: "90vh",
  margin: "auto",
});

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  height: "calc(100vh - 32px)",
  margin: "16px",
});

const TextContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const BodyText = styled(Body, {
  marginBottom: "32px",
  marginTop: "16px",
  maxWidth: "500px",
});

const Spacer = styled("div", {
  marginBottom: "32px",
});

const StyledButton = styled(Button, {
  marginBottom: "16px",
  width: "100%",
});

const PoweredBy = styled(Heading, {
  textAlign: "center",
  color: "$text_80",
});

const PoweredByMobile = styled(Body, {
  textAlign: "center",
  color: "$text_80",
});

const BigImageContainer = styled("div", {
  height: "100%",
  display: "flex",
  alignItems: "center",
});
interface VerificationFormProps {
  email: string;
}

export default function Verification({ email }: VerificationFormProps) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<VerificationCodeFields>();
  const { isDesktop } = useDeviceDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useContext(ApiClientContext);

  const onSubmit: SubmitHandler<VerificationCodeFields> = async (data) => {
    setIsLoading(true);
    try {
      const token = await apiClient.default.verification({
        data: { email, code: data },
      });

      await signInWithCustomToken(auth, token);
      navigate("/seller/profile");
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Container>
      <TextContainer>
        <ImageContainer>
          <img src="/GahakHeader.png" width="100%" height="auto" />
        </ImageContainer>
        <div>
          <Heading variant={isDesktop ? "heading_06" : "heading_04"}>
            Get started now
          </Heading>
          <BodyText variant={isDesktop ? "body_02" : "body_01"}>
            We need to verify your email address associated with your account in
            order to provide you with all services
          </BodyText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextBox
              label="Email address"
              placeholder="Enter code received via email address"
              {...register("email", {
                required: "Input email verification code",
                valueAsNumber: true,
              })}
              error={errors.email?.message}
            />
            <Spacer />
            <StyledButton
              variant="secondary"
              size={isDesktop ? "large" : "small"}
              type="submit"
            >
              Verify
            </StyledButton>
          </form>
        </div>
        {isDesktop ? (
          <PoweredBy variant="heading_03">Powered by Kaajus 2023</PoweredBy>
        ) : (
          <PoweredByMobile variant="body_mini_03">
            Powered by Kaajus 2023
          </PoweredByMobile>
        )}
      </TextContainer>
      {isDesktop && (
        <BigImageContainer>
          <Image src="/login.png" />
        </BigImageContainer>
      )}
    </Container>
  );
}
