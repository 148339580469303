import React from "react";
import CartList from "./CartList/View/CartList";
import CartTotal from "./CartTotal/View/CartTotal";
import { useNavigate, useParams } from "react-router-dom";

import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { Button } from "../../../stitches/button/Button";

import { ChevronRight } from "react-feather";
import { CustomerStore } from "../../../pullstate/Customer";
import EmptyCart from "../EmptyCart";
import useCheckMobileScreen from "../../../hooks/useDevice";

const CartContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
});

const CartCheckoutButton = styled(Button, {
  width: "calc(100% - 32px)",
  margin: "auto",
  marginTop: "26px",
});

const CartHeader = styled("div", {
  display: "flex",
  columnGap: "$space$space_10",
  width: "100%",
  padding: "24px $space$space_16 $space$space_16 $space$space_16",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
});

const CartTotalAndCheckout = styled("div", {
  width: "100%",
  position: "sticky",
  bottom: "0",
  boxShadow: "0px -5px 22px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  height: "142px",
});

const CartHeaderTitle = styled(Heading, {
  display: "flex",
  alignItems: "center",
});

export default function Cart() {
  const { storeName } = useParams();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const proceedToCheckout = () => {
    CustomerStore.update((s) => {
      s.cart.isOpen = false;
    });
    navigate("/" + storeName + "/checkout");
  };

  const navigateToStoreView = () => {
    navigate("/" + storeName);
  };

  const { numberOfItems, isOpen } = CustomerStore.useState((s) => s.cart);
  return (
    <CartContainer>
      <CartHeader>
        <IconBadge
          outlined={false}
          size="medium"
          iconName="X"
          customCSS={{
            Icon: {
              color: "$black_100",
            },
          }}
          onClick={() => {
            CustomerStore.update((s) => {
              s.cart.isOpen = false;
            });
          }}
        />
        <CartHeaderTitle variant="heading_04">Cart</CartHeaderTitle>
      </CartHeader>
      {!numberOfItems && isOpen ? (
        <EmptyCart />
      ) : (
        <>
          <CartList />
          <CartTotalAndCheckout>
            <CartTotal />
            <CartCheckoutButton
              variant="secondary"
              withIcon={true}
              size="small"
              onClick={proceedToCheckout}
            >
              Proceed to checkout <ChevronRight size={24} />
            </CartCheckoutButton>
          </CartTotalAndCheckout>
        </>
      )}
    </CartContainer>
  );
}
