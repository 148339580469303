import React from "react";
import { styled } from "../../stitches/stitches.config";
import StoreSearch from "./StoreSearch";
import StoreFilter from "./StoreFilter";
import { useStoreSearchFilterBar } from "./useStoreSearchFilterBar";
import IconBadge from "../../stitches/iconBadge/IconBadge";
import { useDeviceDimensions } from "../../hooks/useDevice";
import DesktopSearchFilterBar from "./DesktopSearchFilterBar";
const Container = styled("div", {
  display: "flex",
  width: "100%",
  alignItems: "center",
  columnGap: "$space$space_16",
  marginBottom: "26px",
});

export default function StoreSearchFilterBar() {
  const { isDesktop, isWideDesktop } = useDeviceDimensions();
  const { storeFilterProps, storeSearchProps, onModalOpen } =
    useStoreSearchFilterBar();

  return isDesktop || isWideDesktop ? (
    <DesktopSearchFilterBar />
  ) : (
    <Container>
      <StoreSearch {...storeSearchProps} />
      <IconBadge
        iconName="Sliders"
        outlined={false}
        size="medium"
        disabled={false}
        customCSS={{
          IconBadgeContainer: {
            marginRight: "$space$space_16",
          },
          Icon: {
            color: "$black_100",
          },
        }}
        onClick={onModalOpen}
      />
      <StoreFilter {...storeFilterProps} />
    </Container>
  );
}
