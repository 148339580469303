import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { Button } from "../../../stitches/button/Button";
import { Plus } from "react-feather";
import { Tag } from "react-feather";
import { headings } from "../../../stitches/typography/HeadingStyles";

const ProductFooterLayout = styled("div", {
  height: "80px",
  width: "100%",
  backgroundColor: "$white_100",
  display: "flex",
  gap: "$space$space_16",
  alignItems: "center",
  boxShadow: "0px -5px 22px rgba(0, 0, 0, 0.05)",
  position: "fixed",
  bottom: 0,
  padding: "$space$space_16",

  "@Desktop": {
    position: "sticky"
  }
});

const StyledButton = styled(Button, {
  height: "48px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  ...headings.heading_02,
});

interface IProductFooter {
  addItemToCart: () => void;
  proceedToCheckout: () => void;
}

export default function ProductFooter({
  addItemToCart,
  proceedToCheckout,
}: IProductFooter) {
  return (
    <ProductFooterLayout>
      <StyledButton
        variant="secondary"
        outlined="true"
        withIcon
        onClick={addItemToCart}
      >
        <Plus size={24} />
        Add to cart
      </StyledButton>
      <StyledButton variant="secondary" onClick={proceedToCheckout}>
        <Tag size={24} />
        Buy now
      </StyledButton>
    </ProductFooterLayout>
  );
}
