import { useNavigate, useParams } from "react-router-dom";
import { Product } from "../../../../types/product/new/product.model";
import { CustomerStore } from "../../../../pullstate/Customer";

export const useStoreProduct = (product: Product) => {
  const { name, variants, images } = product;
  const { price, quantity } = variants[0];
  const numOfVariants: number = variants.length;
  const { storeName } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    CustomerStore.update((s) => {
      s.selectedProduct = product;
      s.selectedVariant = product.variants[0];
    });
    navigate(`/${storeName}/product/${product.id}`);
  };

  const outOfStock = !!!variants.find((variant) => variant.quantity > 0);

  return {
    name,
    price,
    quantity,
    numOfVariants,
    images,
    handleClick,
    outOfStock,
  };
};
