import { styled } from "../../../stitches/stitches.config";
import { CheckOutStep } from "../Types/Checkout";
import {
  CheckoutProgressIndicator,
  CheckoutProgressIndicatorState,
} from "./CheckoutProgressIndicator";

interface IStepperProps {
  activeStep: number;
  steps: CheckOutStep[];
  handleClick(idx: number): React.MouseEventHandler<HTMLDivElement> | undefined;
}

const Stepper = styled("div", {
  display: "flex",
  width: "calc(100%- 72px)",
  height: "30px",
  boxSizing: "content-box",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "$space$space_10 36px $space$space_10 36px",
});

const LineBetweenSteps = styled("div", {
  flexGrow: "1",
  height: "6px",

  variants: {
    state: {
      Complete: {
        backgroundColor: "$secondary_80",
      },
      Incomplete: {
        backgroundColor: "$grey_50",
      },
    },
  },
});

export default function StepperView({
  activeStep,
  steps,
  handleClick,
}: IStepperProps) {
  return (
    <Stepper>
      {steps.map((step, idx) => {
        const stepState =
          activeStep >= idx
            ? CheckoutProgressIndicatorState.COMPLETE
            : CheckoutProgressIndicatorState.INCOMPLETE;

        const lineKey = `line-${idx}`; // Unique key for LineBetweenSteps
        const indicatorKey = `indicator-${idx}`; // Unique key for CheckoutProgressIndicator

        return (
          <>
            {idx !== 0 && <LineBetweenSteps key={lineKey} state={stepState} />}
            <CheckoutProgressIndicator
              key={indicatorKey}
              iconName={step.iconName}
              state={stepState}
              onClick={() => {
                handleClick(idx);
              }}
            />
          </>
        );
      })}
    </Stepper>
  );
}
