import * as Yup from "yup";

const errorCodes = {
  firstName: "Please enter first name",
  lastName: "Please enter last name",
  storeName: "Please enter store name",
};

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(errorCodes.firstName),
  lastName: Yup.string().required(errorCodes.lastName),
  storeName: Yup.string().required(errorCodes.storeName),
});
