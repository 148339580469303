import React, { ReactNode } from "react";
import Drawer from "../Sidebar/Sidebar";

interface IAdminLayoutProps {
  children: ReactNode;
}

const AdminLayout = ({ children }: IAdminLayoutProps) => {
  return (
    <>
      <Drawer />
      {children}
    </>
  );
};

export default AdminLayout;
