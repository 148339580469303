import { address } from "./CustomerFields";

export enum IOrderStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export type IOrderProps = {
  id: number;
  date: string;
  customerID: number;
  status: IOrderStatus;
  items: number;
  total: number;
};

export type IOrders = {
  orders: OrderFields[];
};

export type OrderItems = {
  productID: string;
  variantID: string;
  quantity: number;
};
export type OrderDetails = {
  items: OrderItems[];
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  address: address;
};

export type OrderFields = {
  id: string;
  date: string;
  customerID: string;
  status: IOrderStatus;
  total: number;
  origin: OrderOrigin;
  orderDetails: OrderDetails;
};

export enum OrderOrigin {
  API = "API",
  MANUAL = "MANUAL",
}

export type OrderWithReferenceId = OrderFields & { referenceId: string };
