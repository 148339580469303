import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../../components/Button/Button";
import styles from "./OrderPlaced.module.css";
import OrderTransition from "./OrderTransition";
import { useNavigate, useParams } from "react-router-dom";

export default function OrderPlaced() {
  const navigate = useNavigate();
  const { storeName } = useParams();
  const handleOnSubmit = () => {
    navigate("/" + storeName);
  };
  return (
    <div className={styles.container}>
      <OrderTransition>
        <CheckCircleIcon className={styles.icon} />
      </OrderTransition>
      <h1>Order Placed</h1>
      <Button
        onSubmit={handleOnSubmit}
        backgroundColor="white"
        color="black"
        margin="0"
      >
        Continue Shopping
      </Button>
    </div>
  );
}
