import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { X } from "react-feather";
import CartSubtotal from "./CartSubtotal";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "11px",
  marginLeft: "auto",
});

interface ICartRightPanelProps {
  price: number;
  quantity: number;
  removeCartItem: (selectedQuantity: number) => void;
}

export default function CartRightPanel({
  price,
  quantity,
  removeCartItem,
}: ICartRightPanelProps) {
  return (
    <Container>
      <X
        size={16}
        onClick={() => {
          removeCartItem(0);
        }}
      />
      <CartSubtotal price={price} quantity={quantity} />
    </Container>
  );
}
