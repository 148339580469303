import { useForm } from "react-hook-form";
export type NewPasswordFormData = {
  password: string;
  confirmPassword: string;
};

enum NewPasswordFormDataFields {
  password = "password",
  confirmPassword = "confirmPassword",
}

type NewPasswordProps = {
  onSubmit: (data: NewPasswordFormData) => void;
};

export function NewPasswordForm({ onSubmit }: NewPasswordProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
    watch,
  } = useForm<NewPasswordFormData>();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p>New password</p>
          <input
            className="Signup-Form-Input"
            type="password"
            {...register(NewPasswordFormDataFields.password, {
              required: "Input password",
              minLength: {
                value: 6,
                message: "Minimum of 6 characters required",
              },
              validate: () => {
                errors.confirmPassword?.type === "validate" &&
                  trigger(NewPasswordFormDataFields.confirmPassword);
                return true;
              },
            })}
            placeholder="Enter your password here."
          />

          {errors.password && <p>{errors.password.message}</p>}
        </div>
        <p>Confirm New Password</p>
        <input
          className="Signup-Form-Input"
          type="password"
          {...register(NewPasswordFormDataFields.confirmPassword, {
            required: "Input password",
            minLength: {
              value: 6,
              message: "Minimum of 6 characters required",
            },
            validate: (value) =>
              value === watch(NewPasswordFormDataFields.password) ||
              "Passwords do not match",
          })}
          placeholder="Re-enter your password here."
        />

        {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
