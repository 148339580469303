import React from "react";
import { styled } from "@stitches/react";
import { Body } from "../../stitches/typography/BodyStyles";
import { StoreSearchProps } from "./StoreSearch";
import { TextBox, TextBoxIconPosition } from "../../stitches/textbox/TextBox";
import { Search } from "react-feather";

const SearchContainer = styled("div", {
  width: "224px",
  height: "auto",
  marginBottom: "$space$space_16",
  marginLeft: "$space$space_0",
  padding: "20px $space$space_10 20px $space$space_10",
  display: "flex",
  flexDirection: "column",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
});

const SearchHeadingContainer = styled("div", {
  display: "flex",
  marginBottom: "$space$space_16",
  borderBottom: "2px solid $grey_100",
  paddingBottom: "8px",
});

const SearchHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

export default function DesktopSearch({ ...props }: StoreSearchProps) {
  const icon = {
    position: TextBoxIconPosition.LEADING,
    Icon: Search,
  };
  return (
    <>
      <SearchContainer>
        <SearchHeadingContainer>
          <SearchHeaderHeading variant="body_02">
            Search Products
          </SearchHeaderHeading>
        </SearchHeadingContainer>
        <TextBox placeholder="Search" icon={icon} {...props} />
      </SearchContainer>
    </>
  );
}
