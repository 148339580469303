const errorCodes = {
  name: "Please enter Product Title",
  description: "Please enter Product Description",
  images: "Please enter Product Images",
  price: "Please enter Price",
  quantity: "Please enter Quantity",
  variantType: "Please enter Variant Type",
  variantValue: "Please enter Variant Value",
};

export default errorCodes;
