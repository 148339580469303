import { Customer, IOrderStatus } from "@gahakapp/client";
import { styled } from "../../../../stitches/stitches.config";
import { OrderWithReferenceId } from "../../../../types/OrderFields";
import { IOrderProduct } from "./OrderSummary";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import Menu from "../../../Menu/Menu";
import { Display } from "../../../../stitches/typography/DisplayStyles";
import IconBadge from "../../../../stitches/iconBadge/IconBadge";
import OrderStatusChip from "../../../Dashboard/Desktop/OrderStatusChip";
import Payment, {
  PaymentType,
} from "../../../../customer/Checkout/Payment/Payment";
import { DollarSign, Home, User } from "react-feather";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { TextBox } from "../../../../stitches/textbox/TextBox";
import SelectPaymentType from "../../../../customer/Checkout/Payment/SelectPaymentType";
import ItemsCard from "./ItemsCard";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";

interface IDesktopOrderSummaryProps {
  order: OrderWithReferenceId;
  navigateBack: () => void;
  orderInfo: IOrderProduct[];
  changeOrderStatus: (
    selectedOrderId: string,
    orderStatus: IOrderStatus
  ) => Promise<void>;
}

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  margin: "auto",
  height: "100vh",
  padding: "32px",
  boxSizing: "border-box",
  columnGap: "32px",
});

const MenuContainer = styled("div", {});

const ContentContainer = styled("div", {
  borderRadius: "10px",
  border: "3px solid #F0F0F0",
  padding: "30px  26px 0 26px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const HeaderContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const HeaderButtonAndOrderID = styled("div", {
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
});

const HeaderText = styled(Display, {
  color: "$secondary_60",
  margin: "0",
});

// ----
// ----
const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const Form = styled("form", {
  width: "100%",
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const PersonalInfoHeaderIcon = styled(User, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const HomeAddressHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const HomeAddressHeaderIcon = styled(Home, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const HomeAddressHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const Container = styled("div", {
  display: "flex",
  width: "100%",
  padding: "42px $space_10 0 $space_10",
  gap: "13px",
  justifyContent: "center",
  overflow: "hidden",
});

const CustomerInfoColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  //   flex: "0 0 calc(50% - 23px)",
  overflowY: "auto",
  padding: "0 20px 20px 0",
  width: "100%",
});

const ItemsInfoColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

const PaymentTypeHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10",
});

const PaymentTypeHeaderIcon = styled(DollarSign, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const PaymentTypeHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const InputWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "$space$space_16",
});

export default function DesktopOrderSummary({
  order,
  navigateBack,
  orderInfo,
  changeOrderStatus,
}: IDesktopOrderSummaryProps) {
  return (
    <MainContainer>
      <MenuContainer>
        <Menu />
      </MenuContainer>
      <ContentContainer>
        <HeaderContainer>
          <HeaderButtonAndOrderID>
            <IconBadge
              onClick={navigateBack}
              iconName="ChevronLeft"
              size="large"
              outlined={false}
              customCSS={{
                Icon: {
                  color: "$secondary_60",
                },
              }}
            />
            <HeaderText variant="display_sm_01">
              Order ID - {order.referenceId}
            </HeaderText>
          </HeaderButtonAndOrderID>

          <OrderStatusChip status={order.status} />
        </HeaderContainer>

        <Form>
          <Container>
            <OverlayScrollbarsComponent style={{ width: "100%" }}>
              <CustomerInfoColumn>
                <PersonalInfoHeadingContainer>
                  <PersonalInfoHeaderIcon size={24} />
                  <PersonalInfoHeaderHeading variant="body_02">
                    Customer Information
                  </PersonalInfoHeaderHeading>
                </PersonalInfoHeadingContainer>
                <InputWrapper>
                  <Input
                    label="First Name"
                    placeholder="First Name"
                    value={order?.orderDetails.firstName}
                    disabled={true}
                  />
                  <Input
                    label="Last Name"
                    placeholder="Last Name"
                    value={order?.orderDetails.lastName}
                    disabled={true}
                  />
                </InputWrapper>
                <Input
                  label="Email Address"
                  placeholder="Email Address"
                  value={order?.orderDetails.email}
                  disabled={true}
                />
                <Input
                  label="Phone Number"
                  placeholder="0342-2345678"
                  value={order?.orderDetails.phoneNumber}
                  disabled={true}
                />
                <HomeAddressHeadingContainer>
                  <HomeAddressHeaderIcon size={24} />
                  <HomeAddressHeaderHeading variant="body_02">
                    Delivery Address
                  </HomeAddressHeaderHeading>
                </HomeAddressHeadingContainer>
                <Input
                  label="Address"
                  placeholder="House#12, Street#1"
                  value={order?.orderDetails.address.addressLine}
                  disabled={true}
                />
                <InputWrapper>
                  <Input
                    label="City"
                    placeholder="Karachi"
                    value={order?.orderDetails.address.city}
                    disabled={true}
                  />
                  <Input
                    label="Country"
                    placeholder="Pakistan"
                    value={order?.orderDetails.address.country}
                    disabled={true}
                  />
                </InputWrapper>
                <PaymentTypeHeadingContainer>
                  <PaymentTypeHeaderIcon size={24} />
                  <PaymentTypeHeaderHeading variant="body_02">
                    Payment Method
                  </PaymentTypeHeaderHeading>
                </PaymentTypeHeadingContainer>
                <SelectPaymentType
                  paymentTypes={[PaymentType.CASH_ON_DELIVERY]}
                />
              </CustomerInfoColumn>
            </OverlayScrollbarsComponent>

            <ItemsInfoColumn>
              <ItemsCard
                items={orderInfo}
                orderTotal={order.total}
                orderStatus={order.status}
                orderId={order.id}
                changeOrderStatus={changeOrderStatus}
              />
            </ItemsInfoColumn>
          </Container>
        </Form>
      </ContentContainer>
    </MainContainer>
  );
}
