import React from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useCheckout } from "./useCheckout";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import StepperView from "../Stepper/StepperView";
import { styled } from "../../../stitches/stitches.config";
import { useDeviceDimensions } from "../../../hooks/useDevice";

const CheckoutHeader = styled("div", {
  width: "100%",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",

  position: "fixed",
  top: "$space$space_0",
  zIndex: "1",
  backgroundColor: "$white_100",

  "@Desktop": {
    boxShadow: "none",
  },
});

const CheckoutHeaderButtonAndTitle = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  padding: "24px $space$space_16 $space$space_16 $space$space_16",
});

const CheckoutHeaderTitle = styled(Heading, {
  display: "flex",
  alignItems: "center",

  "@Desktop": {
    width: "1130px",
    height: "36px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
});

const CheckoutBody = styled("div", {
  marginTop: "138px",
  // marginBottom: "80px",
  // paddingTop: "16px",
  height: "calc(100vh - 138px)",

  "@Desktop": {
    marginTop: "calc(88px)",
    height: "calc(100vh - 88px)",
  },
  overflow: "auto",
});

export default function Checkout() {
  const {
    activeStep,
    steps,
    isLoading,
    onStepperClick,
    PageToBeRendered,
    navigateToStoreView,
  } = useCheckout();

  const { isDesktop } = useDeviceDimensions();

  return (
    <>
      <CheckoutHeader>
        <CheckoutHeaderButtonAndTitle>
          <CheckoutHeaderTitle variant="heading_04">
            Checkout
          </CheckoutHeaderTitle>
          <IconBadge
            outlined={false}
            size="medium"
            iconName="X"
            customCSS={{
              Icon: {
                color: "$black_100",
              },
            }}
            onClick={navigateToStoreView}
          />
        </CheckoutHeaderButtonAndTitle>
        {!isDesktop && (
          <StepperView
            activeStep={activeStep}
            steps={steps}
            handleClick={onStepperClick}
          />
        )}
      </CheckoutHeader>
      <CheckoutBody>
        {isLoading ? <Spinner /> : PageToBeRendered()}
      </CheckoutBody>
    </>
  );
}
