import { styled } from "../stitches.config";
import * as FeatherIcons from "react-feather";
import { bodyStyles } from "../typography/BodyStyles";
import { headings } from "../typography/HeadingStyles";

const Container = styled("button", {
  background: "transparent",
  border: "none",
  borderRadius: "10px",
  color: "$secondary_60",
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
  width: "100%",

  "&:active": {
    background:
      "linear-gradient(180deg, rgba(74, 184, 207, 0.08) 0%, rgba(51, 156, 178, 0.25) 100%)",
  },

  "@Desktop": {
    "&:hover": {
      background:
        "linear-gradient(180deg, rgba(74, 184, 207, 0.04) 0%, rgba(51, 156, 178, 0.13) 100%)",
      cursor: "pointer",
    },
    "&:active": {
      background:
        "linear-gradient(180deg, rgba(74, 184, 207, 0.08) 0%, rgba(51, 156, 178, 0.25) 100%)",
    }, //TODO For some reason I have to do this again to make it work in Desktop view.
  },

  variants: {
    size: {
      small: {
        ...bodyStyles.body_01,
        padding: "10px",
      },
      medium: {
        ...headings.heading_02,
        padding: "16px",
      },
    },
    selected: {
      true: {
        background:
          "linear-gradient(180deg, rgba(74, 184, 207, 0.04) 0%, rgba(51, 156, 178, 0.13) 100%)",
        "&:disabled, &:disabled:hover, &:disabled:active": {
          background:
            "linear-gradient(180deg, rgba(74, 184, 207, 0.04) 0%, rgba(51, 156, 178, 0.13) 100%)",
          cursor: "default",
        },
      },
    },
  },
});

export interface ITabProps
  extends React.ComponentPropsWithoutRef<typeof Container> {
  iconName: keyof typeof FeatherIcons;
  size: "small" | "medium";
  iconColor?: string;
}

const getStyledIcon = (icon: FeatherIcons.Icon, iconColor?: string) => {
  return styled(icon, {
    color: iconColor || "$colors$secondary_60",

    variants: {
      size: {
        small: {
          width: "16px",
          height: "16px",
        },
        medium: {
          width: "24px",
          height: "24px",
        },
      },
    },
  });
};

export default function Tab({
  iconName,
  size,
  children,
  iconColor,
  ...props
}: ITabProps) {
  const Icon = getStyledIcon(FeatherIcons[iconName], iconColor);
  const { selected } = props;

  return (
    <Container size={size} disabled={!!selected} {...props}>
      <Icon size={size} />
      {children}
    </Container>
  );
}
