import React from "react";
import DrawerContainer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDeviceDimensions } from "../../hooks/useDevice";
import { AdminStore } from "../../pullstate/Admin";
import Menu from "../Menu/Menu";

export default function Sidebar() {
  const isMenuOpen = AdminStore.useState((s) => s.isMenuOpen);
  const { isTablet, isDesktop } = useDeviceDimensions();

  let size: string;
  if (isTablet) {
    size = "60vw";
  } else if (isDesktop) {
    size = "30vw";
  } else {
    size = "100vw";
  }
  return isDesktop ? null : (
    <DrawerContainer
      open={isMenuOpen}
      onClose={() => {
        AdminStore.update((s) => {
          s.isMenuOpen = false;
        });
      }}
      direction="left"
      size={size}
    >
      <Menu />
    </DrawerContainer>
  );
}
