import { Store, registerInDevtools } from "pullstate";
import { enableMapSet } from "immer";
import { OrderWithReferenceId } from "../types/OrderFields";
import { Product } from "../types/product/new/product.model";
import { adminStoreInitialState } from "./initialState/AdminStoreInitialState";
import { Profile } from "../types/EditProfile";
import { IOrderStatus } from "@gahakapp/client";

enableMapSet();

export enum OrderOperationEnum {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export interface IOrdersStore {
  orders: OrderWithReferenceId[];
  selectedOrder: OrderWithReferenceId | undefined;
  selectedOrderOperation: OrderOperationEnum | undefined;
  filteredOrders: OrderWithReferenceId[];
  filterSearchTerm: string;
  selectedStatusFilter?: IOrderStatus;
}

export interface IAdminStore {
  ordersStore: IOrdersStore;
  products: Product[];
  profile: Profile;
  selectedProduct: Product | undefined;
  isMenuOpen: boolean;
}

export const AdminStore = new Store<IAdminStore>({
  ...adminStoreInitialState,
});

export const resetOrdersStore = () =>
  AdminStore.update((s) => {
    s.ordersStore = {
      ...adminStoreInitialState.ordersStore,
    };
  });

export const resetAdminStore = () =>
  AdminStore.update(() => {
    return adminStoreInitialState;
  });

registerInDevtools({
  AdminStore,
});
