import { CartProduct } from "../../../types/CartFields";
import { CustomerFields } from "../../../types/CustomerFields";
import {
  IOrderStatus,
  OrderFields,
  OrderItems,
  OrderOrigin,
} from "../../../types/OrderFields";

export default class OrderGenerator {
  generate = (
    cartItems: Map<string, CartProduct[]>,
    total: number,
    orderId: string,
    deliveryDetails: Omit<CustomerFields, "id">,
    origin: OrderOrigin = OrderOrigin.API
  ): Omit<OrderFields, "customerID"> => {
    return {
      id: orderId,
      date: this.prepareOrderDate(),
      status: IOrderStatus.IN_PROGRESS,
      total,
      origin,
      orderDetails: {
        items: this.prepareOrderItems(cartItems),
        ...deliveryDetails,
      },
    };
  };

  private prepareOrderItems = (
    cartItems: Map<string, CartProduct[]>
  ): OrderItems[] => {
    const orderItems: OrderItems[] = [];
    cartItems.forEach((cartItem, productId) => {
      const mappedOrderItems = cartItem.map((item) => {
        return {
          productID: productId,
          variantID: item.variant.id,
          quantity: item.selectedQuantity,
        };
      });

      orderItems.push(...mappedOrderItems);
    });

    return orderItems;
  };

  private prepareOrderDate = (): string => new Date().toLocaleDateString();
}
