import React from "react";
import { CartProduct } from "../../../../../types/CartFields";
import CartItem from "../../CartItem/View/CartItem";
import { useStoreState } from "pullstate";
import { CustomerStore } from "../../../../../pullstate/Customer";
import { styled } from "../../../../../stitches/stitches.config";

const CartListContainer = styled("div", {
  height: "calc(100vh - 87.99px - 142px)",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export default function CartList() {
  const items: Map<string, CartProduct[]> = useStoreState(
    CustomerStore,
    (s) => s.cart.cartDisplayMap
  );

  return (
    <CartListContainer>
      {Array.from(items.entries()).map(([key, value]) =>
        value.map((item, idx) => <CartItem item={item} key={idx} />)
      )}
    </CartListContainer>
  );
}
