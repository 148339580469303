import errorCodes from "../error/Error";
import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const productValidationSchema = Yup.object().shape({
  name: Yup.string().required(errorCodes.name),
  description: Yup.string().required(errorCodes.description),
  hasVariants: Yup.boolean(),
  attributes: Yup.array().when("hasVariants", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(errorCodes.variantType),
      })
    ),
  }),
  variants: Yup.array().when("hasVariants", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        attributes: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().required(errorCodes.variantValue),
          })
        ),
        quantity: Yup.number().required().typeError(errorCodes.quantity),
        price: Yup.number().required().typeError(errorCodes.price),
      })
    ),
    otherwise: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number().required().typeError(errorCodes.quantity),
        price: Yup.number().required().typeError(errorCodes.price),
      })
    ),
  }),
  images: Yup.mixed()
    .required(errorCodes.images)
    .test("filePresent", errorCodes.images, (value) => {
      return value && value.length > 0;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      return Object.values(value).every(testImageFormats);
    }),
});

function testImageFormats(value: any) {
  return value && SUPPORTED_FORMATS.includes(value.type);
}
