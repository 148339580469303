import React from "react";
import { CustomerStore } from "../../../../pullstate/Customer";
import { useStoreState } from "pullstate";
import { DollarSign } from "react-feather";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { styled } from "../../../../stitches/stitches.config";

const PaymentTypeContainer = styled("div", {
  width: "100%",
  padding: "20px $space$space_10 20px $space$space_10",
  marginBottom: "$space$space_16",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
});

const PaymentTypeHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  paddingBottom: "8px",
});

const PaymentTypeHeaderIcon = styled(DollarSign, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const PaymentTypeHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

export default function PaymentTypeCard() {
  const paymentType = useStoreState(
    CustomerStore,
    (s) => s.checkout.paymentDetails.mode
  );

  return (
    <PaymentTypeContainer>
      <PaymentTypeHeadingContainer>
        <PaymentTypeHeaderIcon size={24} />
        <PaymentTypeHeaderHeading variant="body_02">
          Payment Method
        </PaymentTypeHeaderHeading>
      </PaymentTypeHeadingContainer>
      <Body variant="body_01">{paymentType}</Body>
    </PaymentTypeContainer>
  );
}
