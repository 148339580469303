import { styled } from "../../stitches/stitches.config";
import Chip from "../../stitches/chip/Chip";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { useStoreFilterPanel } from "./useStoreFilterPanel";

const Container = styled("div", {
  width: "100%",
  display: "flex",
  columnGap: "$space$space_10",
  marginBottom: "26px",
});

const StoreFilterPanelLabelHeading = styled(Heading, {
  marginLeft: "$space$space_16",
});

const StoreFilterPanelChip = styled(Chip, {
  backgroundColor: "$grey_50",
});

export default function StoreFilterPanel() {
  const { isFiltered, filter, onRemoveFilter } = useStoreFilterPanel();

  return !isFiltered ? null : (
    <Container>
      <StoreFilterPanelLabelHeading variant="heading_03">
        Filtered By:
      </StoreFilterPanelLabelHeading>
      <StoreFilterPanelChip
        text={filter!}
        size="small"
        iconData={{ name: "X", position: "trailing" }}
        onClick={onRemoveFilter}
      />
    </Container>
  );
}
