import React, { useContext } from "react";
import UseAuth from "../../../hooks/UseAuth";
import Spinner from "../../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { AdminStore } from "../../../pullstate/Admin";
import { ApiClientContext } from "../../../context/ApiClientContext";
import { useDeviceDimensions } from "../../../hooks/useDevice";
import DesktopProducts from "./DesktopProducts";
import MobileProducts from "./MobileProducts";
import { useQuery } from "react-query";
import { Product } from "@gahakapp/client";
import { ProductQueryKeys } from "../../AddProduct/ProductContainer/ProductQuery.interface";

export default function DashboardProducts() {
  const { user } = UseAuth();
  const { storeName } = useParams();
  const apiClient = useContext(ApiClientContext);
  const { isLoading } = useQuery<Product[]>(
    ProductQueryKeys.ADMIN_PRODUCTS,
    async () => {
      return apiClient.default.getProducts(storeName || "", user!.uid);
    },
    {
      enabled: !!user,
      onSuccess: (data: Product[]) => {
        AdminStore.update((s) => {
          s.products = data;
        });
      },
    }
  );

  const { isDesktop } = useDeviceDimensions();

  if (isLoading) {
    return <Spinner />;
  }

  return isDesktop ? <DesktopProducts /> : <MobileProducts />;
}
