import React, { useRef } from "react";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { UpdateProductFields } from "../../../../../types/ProductFields";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { Archive, Copy, Plus, Trash } from "react-feather";
import { Button } from "../../../../../stitches/button/Button";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import ProductVariantDialog from "./ProductVariantDialog";
import _ from "lodash";
import { Variant } from "../../../../../types/product/new/product.model";
import { styled } from "../../../../../stitches/stitches.config";

interface IDesktopProductVariantsProps {
  register: UseFormRegister<UpdateProductFields>;
  unregister: UseFormUnregister<UpdateProductFields>;
  watch: UseFormWatch<UpdateProductFields>;
  setHasVariantToTrue: () => void;
  hasVariants: boolean;
  variants?: Variant[];
  setValue: UseFormSetValue<UpdateProductFields>;
  deleteVariantsIdsRef: React.MutableRefObject<Set<string>>;
}

const InputWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "$space$space_16",
  alignItems: "center",
  marginBottom: "$space$space_20",
});

const DeleteIcon = styled(Trash, {
  width: "24px",
  height: "24px",
  color: "$danger_100",
  transform: "translateY(10px)",
  cursor: "pointer",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const VariantHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  alignItems: "center",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
  width: "100%",
});

const VariantHeadingIconContainer = styled(Archive, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const VariantHeadingHeader = styled(Body, {
  color: "$secondary_60",
});

const StyledTextBox = styled(TextBox, {
  flex: "1",
});

export default function ProductVariants({
  register,
  unregister,
  watch,
  setHasVariantToTrue,
  hasVariants,
  variants,
  setValue,
  deleteVariantsIdsRef,
}: IDesktopProductVariantsProps) {
  if (!variants) {
    return null;
  }

  const [indexes, setIndexes] = React.useState<number[]>(
    variants.map((_, index) => index)
  );
  const [counter, setCounter] = React.useState(variants.length);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isVariantFieldSelected, setIsVariantFieldSelected] =
    React.useState(hasVariants);
  const attributeLabel = watch("attributes.0.name");

  const addVariant = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeVariant = (index: number) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
    if (counter - 1 === 0) {
      setIsVariantFieldSelected(false);
      setValue("hasVariants", false);
    }

    unregister(`variants.${index}`);
    deleteVariantsIdsRef.current.add(variants[index].id);
  };

  const handleVariantButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setValue("attributes.0.name", variants[0].attributes[0].name, {
      shouldValidate: true,
    });
    setIsDialogOpen(false);
  };

  const handleSuccessDialogClose = () => {
    setIsDialogOpen(false);
    setIsVariantFieldSelected(true);
    setCounter(1);
    setIndexes([0]);
    setHasVariantToTrue();
  };

  return (
    <>
      <VariantHeadingContainer>
        <VariantHeadingIconContainer size={24} />
        <VariantHeadingHeader variant="body_02">
          Inventory Details
        </VariantHeadingHeader>
        <Button
          variant="secondary"
          outlined
          withIcon
          type="button"
          onClick={handleVariantButtonClick}
          style={{ marginLeft: "auto" }}
          disabled={isVariantFieldSelected}
        >
          <Copy size={24} />
          Variant
        </Button>
      </VariantHeadingContainer>
      {isVariantFieldSelected ? (
        <>
          {indexes.map((index) => {
            return (
              <InputWrapper key={index}>
                <StyledTextBox
                  label={_.capitalize(attributeLabel)}
                  placeholder="Value"
                  // defaultValue={variants[index]?.attributes[0]?.value}
                  {...register(`variants.${index}.attributes.${0}.value`, {
                    required: "Required Field",
                  })}
                />
                <StyledTextBox
                  label="Price (PKR)"
                  placeholder="Enter price"
                  // defaultValue={variants[index]?.price}
                  {...register(`variants.${index}.price`, {
                    required: "Required Field",
                  })}
                  inputMode="numeric"
                  type="number"
                />
                <StyledTextBox
                  label="Quantity"
                  placeholder="Enter quantity"
                  // defaultValue={variants[index]?.quantity}
                  {...register(`variants.${index}.quantity`, {
                    required: "Required Field",
                  })}
                  inputMode="numeric"
                  type="number"
                />
                <DeleteIcon onClick={removeVariant(index)} />
              </InputWrapper>
            );
          })}
          <Button
            type="button"
            onClick={addVariant}
            variant="secondary"
            size="small"
            withIcon
          >
            <Plus size={24} />
            Add Variant
          </Button>
        </>
      ) : (
        <InputWrapper>
          <Input
            label="Price (PKR)"
            placeholder="Enter Price"
            defaultValue={variants[0]?.price}
            {...register(`variants.${0}.price`, {
              required: "Required Field",
            })}
            type="number"
          />
          <Input
            label="Quantity"
            placeholder="Enter quantity"
            defaultValue={variants[0]?.quantity}
            {...register(`variants.${0}.quantity`, {
              required: "Required Field",
            })}
            type="number"
          />
        </InputWrapper>
      )}

      <ProductVariantDialog
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleDialogClose}
        register={register}
        unregister={unregister}
        handleSuccessDialogClose={handleSuccessDialogClose}
        isEmpty={!!attributeLabel?.length}
        typeName={variants[0]?.attributes[0]?.name}
      />
    </>
  );
}
