import { styled } from "../../stitches/stitches.config";
import { Heading } from "../../stitches/typography/HeadingStyles";
import CounterButton, { CounterActions } from "./CounterButton";
import { useCounter } from "./useCounter";

const CounterValue = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderStyle: "solid",
  borderColor: "$secondary_80",
  borderWidth: "2px $space$space_0",
  variants: {
    size: {
      medium: {
        width: "47px",
        height: "40px",
      },
      small: {
        width: "35px",
        height: "32px",
      },
    },
  },
});

const Container = styled("div", {
  display: "flex",

  variants: {
    variant: {
      cartPage: {
        position: "absolute",
        bottom: "$space$space_0",
      },
      productPage: {
        marginTop: "$space$space_16",
      },
    },
  },
});

export enum CounterVariant {
  ProductPage = "productPage",
  CartPage = "cartPage",
}

export enum CounterSize {
  Small = "small",
  Medium = "medium",
}

interface ICounterProps {
  size: CounterSize;
  availableQuantity: number;
  selectedQuantity: number;
  changeItemQuantity: (selectedQuantity: number) => void;
  variant: CounterVariant;
}

export default function Counter({
  size,
  availableQuantity,
  selectedQuantity,
  changeItemQuantity,
  variant,
}: ICounterProps) {
  const { decrementSelectedQuantity, incrementSelectedQuantity } = useCounter(
    selectedQuantity,
    changeItemQuantity
  );

  return (
    <Container variant={variant}>
      <CounterButton
        size={size}
        variant={CounterActions.DECREMENT}
        onClick={decrementSelectedQuantity}
        disabled={
          selectedQuantity <= 1 && variant === CounterVariant.ProductPage
        }
      />
      <CounterValue size={size}>
        <Heading variant="heading_01">{selectedQuantity}</Heading>
      </CounterValue>
      <CounterButton
        size={size}
        variant={CounterActions.INCREMENT}
        onClick={incrementSelectedQuantity}
        disabled={selectedQuantity >= availableQuantity}
      />
    </Container>
  );
}
