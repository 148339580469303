import React from "react";
import { CustomerStore } from "../../../../pullstate/Customer";
import { useStoreState } from "pullstate";
import { CheckCircle, ShoppingBag } from "react-feather";
import { Body } from "../../../../stitches/typography/BodyStyles";
import Item from "./Item";
import { Button } from "../../../../stitches/button/Button";
import { useDeviceDimensions } from "../../../../hooks/useDevice";
import OrderTotal from "../Footer/OrderTotal";
import { styled } from "../../../../stitches/stitches.config";

const ItemsContainer = styled("div", {
  width: "100%",
  padding: "20px $space$space_10 0 $space$space_10",
  display: "flex",
  flexDirection: "column",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
  "@Desktop": {
    overflow: "auto",
    borderRadius: "$space$space_10 $space$space_10 0 0",
  },
});

const ItemsHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  paddingBottom: "18px",
});

const ItemsHeaderIcon = styled(ShoppingBag, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const ItemsHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const CompleteOrderbutton = styled(Button, {
  width: "100%",
  height: "48px",
});

const StickyButtonContainer = styled("div", {
  bottom: "50px",
  padding: "20px",
  backgroundColor: "$white_100",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "0 0 10px 10px",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",

  "@Desktop": {
    maxWidth: "469px",
    maxHeight: "662px",
  },
});

const ItemsListContainer = styled("div", {
  "@Desktop": {
    overflow: "auto",
  },
});

export default function ItemsCard({
  onSubmitOrder,
}: {
  onSubmitOrder?: () => void;
}) {
  const { cartDisplayMap: cartItems } = useStoreState(
    CustomerStore,
    (s) => s.cart
  );

  const { isDesktop } = useDeviceDimensions();
  return (
    <Container>
      <ItemsContainer>
        <ItemsHeadingContainer>
          <ItemsHeaderIcon size={24} />
          <ItemsHeaderHeading variant="body_02">
            Order Details
          </ItemsHeaderHeading>
        </ItemsHeadingContainer>
        <ItemsListContainer>
          {Array.from(cartItems.entries()).map(([key, CartProducts]) =>
            CartProducts.map((cartProduct, idx) => {
              return <Item key={idx} item={cartProduct} />;
            })
          )}
        </ItemsListContainer>
      </ItemsContainer>
      {isDesktop && (
        <StickyButtonContainer>
          <OrderTotal />
          <CompleteOrderbutton
            variant="secondary"
            withIcon
            size="small"
            onClick={onSubmitOrder}
          >
            Complete order
            <CheckCircle size={24} />
          </CompleteOrderbutton>
        </StickyButtonContainer>
      )}
    </Container>
  );
}
