export const useCounter = (
  selectedQuantity: number,
  changeItemQuantity: (selectedQuantity: number) => void
) => {
  const decrementSelectedQuantity = () => {
    changeItemQuantity(selectedQuantity - 1);
  };

  const incrementSelectedQuantity = () => {
    changeItemQuantity(selectedQuantity + 1);
  };

  return {
    decrementSelectedQuantity,
    incrementSelectedQuantity,
  };
};
