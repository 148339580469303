import React from "react";
import { CustomerStore } from "../../../../pullstate/Customer";
import { useStoreState } from "pullstate";
import { Home } from "react-feather";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { styled } from "@stitches/react";
import { Address } from "@gahakapp/client";

interface CustomerAddressCardProps {
  address: Address | undefined;
}

const HomeAddressContainer = styled("div", {
  width: "90%",
  margin: "0 auto",
  marginTop: "20px",
  marginBottom: "$space$space_16",
  padding: "20px 10px",
  display: "flex",
  rowGap: "8px",
  flexDirection: "column",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
});

const HomeAddressHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  paddingBottom: "8px",
});

const HomeAddressHeaderIcon = styled(Home, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const HomeAddressHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

export default function CustomerAddressCard({
  address,
}: CustomerAddressCardProps) {
  const addressLine = address?.addressLine;
  const city = address?.city;
  const country = address?.country;

  return (
    <HomeAddressContainer>
      <HomeAddressHeadingContainer>
        <HomeAddressHeaderIcon size={24} />
        <HomeAddressHeaderHeading variant="body_02">
          Delivery Address
        </HomeAddressHeaderHeading>
      </HomeAddressHeadingContainer>
      <Body variant="body_01">
        {addressLine}, {city} , {country}
      </Body>
    </HomeAddressContainer>
  );
}
