import { useEffect, useState } from "react";
import * as FeatherIcons from "react-feather";
import { styled } from "../../../stitches/stitches.config";

export const Container = styled("div", {
  width: "30px",
  height: "30px",
  borderRadius: "$ROUND",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  variants: {
    state: {
      Complete: {
        backgroundColor: "$secondary_80",
      },
      Incomplete: {
        backgroundColor: "$grey_50",
      },
    },
  },
});

const getIcon = (IconName: string) => {
  // Retrieve the Icon Component using the Icon name
  const Icon: FeatherIcons.Icon = FeatherIcons[IconName];

  return styled(Icon, {
    height: "$space$space_16",
    width: "$space$space_16",

    variants: {
      state: {
        Complete: {
          color: "$colors$white_100",
        },
        Incomplete: {
          color: "$colors$text_100",
        },
      },
    },
  });
};

export enum CheckoutProgressIndicatorState {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
}

interface ICheckoutProgressIndicatorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  iconName: string;
  state: CheckoutProgressIndicatorState;
  key?: React.Key;
}

export const CheckoutProgressIndicator = ({
  iconName,
  state,
  onClick,
  key,
  ...props
}: ICheckoutProgressIndicatorProps) => {
  const [Icon, setIcon] = useState<ReturnType<typeof getIcon> | undefined>();

  useEffect(() => {
    setIcon(getIcon(iconName));
  }, []);

  if (!Icon) {
    return null;
  }

  return (
    <Container {...props} key={key} onClick={onClick} state={state}>
      <Icon state={state} />
    </Container>
  );
};
