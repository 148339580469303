import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { UseFormRegister, UseFormUnregister } from "react-hook-form";
import { UpdateProductFields } from "../../../../../types/ProductFields";
import { Button } from "../../../../../stitches/button/Button";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { styled } from "@stitches/react";
import { useDeviceDimensions } from "../../../../../hooks/useDevice";
import Drawer from "react-modern-drawer";

const DialogActionStyle = styled(DialogActions, {
  display: "flex",
  justifyContent: "space-evenly",
  padding: "0 24px",
});

interface IDesktopProductVariantDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  register: UseFormRegister<UpdateProductFields>;
  unregister: UseFormUnregister<UpdateProductFields>;
  handleSuccessDialogClose: () => void;
  isEmpty: boolean;
  typeName: string | undefined;
}

export default function ProductVariantDialog({
  isDialogOpen,
  handleDialogClose,
  register,
  handleSuccessDialogClose,
  isEmpty,
  typeName,
}: IDesktopProductVariantDialogProps) {
  const { isDesktop } = useDeviceDimensions();

  return isDesktop ? (
    <Dialog
      open={isDialogOpen}
      keepMounted
      onClose={handleDialogClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle>
        <Heading variant="heading_02">Edit Variant</Heading>
      </DialogTitle>
      <DialogContent>
        <TextBox
          placeholder="Eg: Size, Color, etc."
          label="Type"
          {...register(`attributes.${0}.name`, {
            required: "Required Field",
          })}
          defaultValue={typeName}
        />
      </DialogContent>
      <DialogActionStyle>
        <Button
          variant="secondary"
          outlined
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleDialogClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleSuccessDialogClose}
          disabled={!isEmpty}
          type="button"
        >
          Confirm
        </Button>
      </DialogActionStyle>
    </Dialog>
  ) : (
    <Drawer
      open={isDialogOpen}
      onClose={handleDialogClose}
      direction={"bottom"}
    >
      <DialogTitle>
        <Heading variant="heading_02">Create Variant</Heading>
      </DialogTitle>
      <DialogContent>
        <TextBox
          placeholder="Eg: Size, Color, etc."
          label="Type"
          {...register(`attributes.${0}.name`, {
            required: "Required Field",
          })}
          defaultValue={typeName}
        />
      </DialogContent>
      <DialogActionStyle>
        <Button
          variant="secondary"
          outlined
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleDialogClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleSuccessDialogClose}
          disabled={!isEmpty}
          type="button"
        >
          Create
        </Button>
      </DialogActionStyle>
    </Drawer>
  );
}
