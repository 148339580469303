import ChartDataGenerator from "./ChartDataGenerator";
import ChartGenerators from "./ChartGenerators";

export type ILineChartOptions = {
  responsive: boolean;
  maintainAspectRatio: boolean;
  plugins: {
    legend: {
      display: boolean;
      position: "top" | "bottom" | "left" | "right";
    };
    title: {
      display: boolean;
      text: string;
    };
  };
};

export type generateLineChartOptionsProps = {
  props: {
    position: "top" | "bottom" | "left" | "right";
    title: string;
  };
};

export class LineChartGenerator extends ChartDataGenerator {
  generateLineChartOptions({
    props,
  }: generateLineChartOptionsProps): ILineChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: true,
          position: props.position,
        },
        title: {
          display: true,
          text: props.title,
        },
      },
    };
  }
}
