import * as FeatherIcons from "react-feather";
import { styled } from "../../../../../stitches/stitches.config";
import UseWindowHook from "../../../../../hooks/UseWindowHook";
import { Body } from "../../../../../stitches/typography/BodyStyles";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";
import Chip from "../../../../../stitches/chip/Chip";
import { IconType } from "react-icons/lib";

const Container = styled("div", {
  width: "100%",
  minHeight: "333px",
  borderRadius: "34px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "26px 26px 26px 26px",
  rowGap: "16px",
  boxSizing: "border-box",
  background:
    "linear-gradient(180deg, rgba(74, 184, 207, 0.039) 0%, rgba(51, 156, 179, 0.1274) 100%)",

  "@Desktop": {
    minHeight: "392px",
  },

  variants: {
    isWhatsAppCard: {
      true: {
        "@Desktop": {
          flexDirection: "row",
          columnGap: "36px",
        },
      },
    },
  },
});

const IconCard = styled("div", {
  height: "100px",
  width: "100px",
  borderRadius: "34px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
  background:
    "linear-gradient(180deg, rgba(74, 184, 207, 0.039) 0%, rgba(51, 156, 179, 0.1274) 100%)",

  variants: {
    isWhatsAppCard: {
      true: {
        "@Desktop": {
          height: "340px",
          minWidth: "340px",
        },
      },
    },
  },
});

const getStyledIcon = (Icon: FeatherIcons.Icon) => {
  return styled(Icon, {
    color: "$secondary_80",
    width: "40px",
    height: "40px",

    variants: {
      whatsAppCardIcon: {
        primary: {
          "@Desktop": {
            width: "162px",
            height: "162px",
          },
        },
        secondary: {
          color: "rgba(63, 152, 171, 0.09)",
          width: "66.5px",
          height: "66.5px",

          "@Desktop": {
            width: "169px",
            height: "169px",
          },
        },
      },
    },
  });
};

const WhatsAppCardStyledIconsContainer = styled("img", {
  position: "absolute",
  top: "16px",
  right: "16px",
  width: "133px",
  height: "133px",
  zIndex: "-1",

  "@Desktop": {
    top: "24px",
    right: "26px",
    width: "338px",
    height: "338px",
  },
});

const CardTextContainer = styled("div", {
  display: "flex",
  rowGap: "16px",
  flexDirection: "column",

  variants: {
    isWhatsAppCard: {
      true: {
        "@Desktop": {
          marginTop: "calc(56px - 26px)",
          maxWidth: "526px",
        },
      },
    },
  },
});

const ComingSoonChip = styled(Chip, {
  position: "absolute",
  top: "16px",
  right: "16px",
  backgroundColor: "$secondary_80",
  opacity: "0.7",
  color: "$colors$white_100",
});

interface ICardProps {
  FeatureIcon: FeatherIcons.Icon | IconType;
  headingContent: string;
  bodyContent: string;
  isWhatsAppCard?: boolean;
  isFutureFeature?: boolean;
}

export const Card = ({
  FeatureIcon,
  headingContent,
  bodyContent,
  isWhatsAppCard,
  isFutureFeature,
}: ICardProps) => {
  const Icon = getStyledIcon(FeatureIcon);

  const { width } = UseWindowHook();

  const isDesktopView: boolean = width >= 1024;

  return (
    <Container isWhatsAppCard={isWhatsAppCard}>
      {isFutureFeature && <ComingSoonChip size="large" text="Coming Soon" />}
      <IconCard isWhatsAppCard={isWhatsAppCard}>
        <Icon
          {...(isWhatsAppCard ? { whatsAppCardIcon: "primary" } : undefined)}
        />
      </IconCard>
      <CardTextContainer isWhatsAppCard={isWhatsAppCard}>
        <Heading variant={isDesktopView ? "heading_04" : "heading_03"}>
          {headingContent}
        </Heading>
        <Body variant={isDesktopView ? "body_02" : "body_01"}>
          {bodyContent}
        </Body>
      </CardTextContainer>
      {isWhatsAppCard && (
        <WhatsAppCardStyledIconsContainer
          src="/WhatsApp feature card Splash.png"
          alt="WhatsApp feature card Splash"
        />
      )}
    </Container>
  );
};
