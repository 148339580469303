import React from "react";
import { IChartProps } from "../../../types/ChartFields";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ChartView({ type, options, data }: IChartProps) {
  return <Chart data={data} type={type} options={options} />;
}
