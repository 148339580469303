import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { OrderWithReferenceId } from "../../../types/OrderFields";
import { styled } from "../../../stitches/stitches.config";
import { Body } from "../../../stitches/typography/BodyStyles";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import StatusChip from "./StatusChip";
import { useOrdersTable } from "./useOrdersTable";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";

const columnHelper = createColumnHelper<OrderWithReferenceId>();

const TableHeaderCell = styled("th", {
  textAlign: "center",
  verticalAlign: "middle",
  color: "$secondary_40",
});

const Table = styled("table", {
  width: "100%",
  height: "100%",
  borderCollapse: "collapse",
  display: "flex",
  flexDirection: "column",
});

const TableHeader = styled("thead", {
  display: "block",
  width: "100%",
  boxShadow: "0px 3px 5px -2px rgba(0, 0, 0, 0.08)",
  padding: "20px 0",
});

const TableHeaderRow = styled("tr", {
  display: "table",
  width: "100%",
  tableLayout: "fixed",
});

const TableBodyRow = styled("tr", {
  display: "table",
  width: "100%",
  tableLayout: "fixed",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$grey_100",
  },
});

const TableData = styled("td", {
  textAlign: "center",
  verticalAlign: "middle",
  padding: "8px 16px",
  display: "table-cell",
});

const TableBody = styled("tbody", {
  display: "block",
  overflow: "auto",
});

const TableDataText = styled(Body, {});

const TableHeaderText = styled(Heading, {});

export default function OrdersTable() {
  const { filteredOrderSetByStatus, navigateToOrderDetails, sortedData } =
    useOrdersTable();

  const columns = [
    columnHelper.accessor((row) => row.referenceId, {
      id: "referenceId",
      cell: (info) => (
        <TableDataText variant="body_02">{info.getValue()}</TableDataText>
      ),
      header: () => (
        <TableHeaderText variant="heading_02">Order ID</TableHeaderText>
      ),
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      cell: (info) => (
        <TableDataText variant="body_02">{info.getValue()}</TableDataText>
      ),
      header: () => (
        <TableHeaderText variant="heading_02">Date</TableHeaderText>
      ),
    }),
    columnHelper.accessor((row) => row.orderDetails.items, {
      id: "items",
      cell: (info) => (
        <TableDataText variant="body_02">
          {info.getValue().length}
        </TableDataText>
      ),
      header: () => (
        <TableHeaderText variant="heading_02">Items</TableHeaderText>
      ),
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => <StatusChip status={info.getValue()} />,
      header: () => (
        <TableHeaderText variant="heading_02">Status</TableHeaderText>
      ),
    }),
    {
      id: "payment",
      cell: () => <TableDataText variant="body_02">COD</TableDataText>,
      header: () => (
        <TableHeaderText variant="heading_02">Payment</TableHeaderText>
      ),
    },
    columnHelper.accessor((row) => row.total, {
      id: "total",
      cell: (info) => (
        <TableDataText variant="body_02">Rs. {info.getValue()}</TableDataText>
      ),
      header: () => (
        <TableHeaderText variant="heading_02">Total</TableHeaderText>
      ),
    }),
  ];

  const table = useReactTable({
    data: sortedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHeaderCell>
            ))}
          </TableHeaderRow>
        ))}
      </TableHeader>
      <OverlayScrollbarsComponent>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableBodyRow
              key={row.id}
              onClick={() => navigateToOrderDetails(row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableData key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              ))}
            </TableBodyRow>
          ))}
        </TableBody>
      </OverlayScrollbarsComponent>
    </Table>
  );
}
