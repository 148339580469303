import React, { useEffect, useState } from "react";
import { Image as ImageType } from "../../../../types/product/new/product.model";
import { styled } from "../../../../stitches/stitches.config";
import { Blurhash } from "react-blurhash";
import { DEFAULT_BLURHASH } from "../../../../constants/Constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const StoreImg = styled(LazyLoadImage, {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "transform 0.5s ease-in-out",
  opacity: 0,
});

const StoreImageContainer = styled("div", {
  position: "relative",
  height: "216px",
  overflow: "hidden",
  borderRadius: "$space$space_10",
  border: "1px solid $grey_50",
});

interface IStoreImageContainer {
  images: ImageType[];
  width?: string;
  height?: string;
  borderRadius?: string;
}

export default function StoreImage({ images }: IStoreImageContainer) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <StoreImageContainer>
      <Blurhash
        hash={DEFAULT_BLURHASH}
        width={400}
        height={216}
        resolutionX={32}
        resolutionY={32}
        punch={1}
        style={{
          opacity: imageLoaded ? 0 : 1,
        }}
      />
      <StoreImg
        src={images[0].thumbnail ?? images[0].url}
        alt="image"
        loading="lazy"
        effect="blur"
        onLoad={() => setImageLoaded(true)}
        style={{ opacity: imageLoaded ? 1 : 0 }}
        width="100%"
        height="100%"
      />
    </StoreImageContainer>
  );
}
