import React from "react";
import { CustomerStore } from "../../../../pullstate/Customer";
import { useStoreState } from "pullstate";
import { AlertCircle, CheckCircle, ShoppingBag } from "react-feather";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { Button } from "../../../../stitches/button/Button";
import { useDeviceDimensions } from "../../../../hooks/useDevice";

import { styled } from "../../../../stitches/stitches.config";
import OrderTotal from "./OrderTotal";
import Item from "./Item";
import { IOrderProduct } from "./OrderSummary";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { IOrderStatus } from "@gahakapp/client";
import { OrderWithReferenceId } from "../../../../types/OrderFields";

const ItemsContainer = styled("div", {
  width: "90%",
  margin: "0 auto",
  padding: "20px $space$space_10 0 $space$space_10",
  display: "flex",
  flexDirection: "column",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
  "@Desktop": {
    overflow: "auto",
    borderRadius: "$space$space_10 $space$space_10 0 0",
  },
});

const ItemsHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  paddingBottom: "18px",
});

const ItemsHeaderIcon = styled(ShoppingBag, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const ItemsHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const UpdateOrderStatusButton = styled(Button, {
  width: "100%",
  height: "48px",
  color: "$black_100 !important",
  marginTop: "26px",

  variants: {
    status: {
      [IOrderStatus.IN_PROGRESS]: {
        backgroundColor: "$colors$tertiary_100",
        "&:hover": {
          backgroundColor: "$colors$tertiary_80",
        },
        "&:active": {
          backgroundColor: "$colors$tertiary_60",
        },
      },
      [IOrderStatus.COMPLETED]: {
        backgroundColor: "$colors$success_100",
        "&:hover": {
          backgroundColor: "$colors$success_75",
        },
        "&:active": {
          backgroundColor: "$colors$success_50",
        },
      },
      [IOrderStatus.CANCELLED]: {
        backgroundColor: "$colors$Danger_100",
      },
    },
  },
});

const StickyButtonContainer = styled("div", {
  width: "100%",
  margin: "0 auto",
  marginTop: "20px",
  marginBottom: "$space$space_16",
  padding: "20px 10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
  "@Desktop": {
    bottom: "50px",
    padding: "16px 20px 20px 20px",
    backgroundColor: "$white_100",
    boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 10px 10px",
  },
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",

  "@Desktop": {
    maxWidth: "469px",
    maxHeight: "662px",
  },
});

const ItemsListContainer = styled("div", {
  "@Desktop": {
    overflow: "auto",
  },
});

interface IItemsCard {
  items: IOrderProduct[];
  orderTotal: number;
  orderStatus: IOrderStatus;
  orderId: string;
  changeOrderStatus: (
    selectedOrderId: string,
    orderStatus: IOrderStatus
  ) => Promise<void>;
}

const statusDisplay = {
  [IOrderStatus.IN_PROGRESS]: {
    buttonText: "Mark Order as In Progress",
  },
  [IOrderStatus.COMPLETED]: {
    buttonText: "Mark Order as Completed",
  },
};

export default function ItemsCard({
  items,
  orderTotal,
  orderStatus,
  orderId,
  changeOrderStatus,
}: IItemsCard) {
  const { isDesktop } = useDeviceDimensions();

  let possibleUpdatedStatus: IOrderStatus = IOrderStatus.COMPLETED;
  if (orderStatus === IOrderStatus.COMPLETED) {
    possibleUpdatedStatus = IOrderStatus.IN_PROGRESS;
  }

  return (
    <Container>
      <ItemsContainer>
        <ItemsHeadingContainer>
          <ItemsHeaderIcon size={24} />
          <ItemsHeaderHeading variant="body_02">Items</ItemsHeaderHeading>
        </ItemsHeadingContainer>
        <OverlayScrollbarsComponent>
          <ItemsListContainer>
            {items.map((item, idx) => {
              return <Item key={idx} item={item} />;
            })}
          </ItemsListContainer>
        </OverlayScrollbarsComponent>
      </ItemsContainer>
      <StickyButtonContainer>
        <OrderTotal orderTotal={orderTotal} />
        <UpdateOrderStatusButton
          variant="secondary"
          withIcon
          size="small"
          status={possibleUpdatedStatus}
          onClick={() => changeOrderStatus(orderId, possibleUpdatedStatus)}
        >
          {possibleUpdatedStatus === IOrderStatus.COMPLETED ? (
            <CheckCircle size={24} />
          ) : (
            <AlertCircle size={24} />
          )}
          {statusDisplay[possibleUpdatedStatus].buttonText}
        </UpdateOrderStatusButton>
      </StickyButtonContainer>
    </Container>
  );
}
