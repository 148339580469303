import { styled } from "@stitches/react";
import React from "react";
import Chip from "../../../../components/Chip/View/Chip";
import Spinner from "../../../../components/Spinner/Spinner";
import {
  IOrderStatus,
  OrderWithReferenceId,
} from "../../../../types/OrderFields";
import { useOrder } from "./useOrder";

type IOrder = {
  order: OrderWithReferenceId;
};

const statusToColorMapper = {
  [IOrderStatus.IN_PROGRESS]: "info",
  [IOrderStatus.COMPLETED]: "success",
  [IOrderStatus.CANCELLED]: "disabled",
};

const OrderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  border: "2px solid $grey_100",
  borderRadius: "$space_10",
  margin: "$space_16",
  backgroundColor: "white",
});

const OrderTop = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "$space_10",
});

const OrderLeft = styled("div", {
  display: "flex",
  alignItems: "center",
});

const OrderRight = styled("div", {
  display: "flex",
  alignItems: "center",
});

const OrderMiddle = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "$space_8",
  "& .item-count": {
    color: "$secondary_80",
    marginRight: "$space_8",
    display: "inline-block",
    width: "$space_10",
    height: "$space_10",
    borderRadius: "50%",
    background: "$secondary_80",
  },
});

const OrderBottom = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: "#eee",
  padding: "$space_8",
  borderRadius: "0 0 4px 4px",
  marginTop: "$space_8",
});

const OrderId = styled("div", {
  fontSize: "smaller",
  "& .bold-text": {
    fontWeight: "bold",
  },
});

const OrderDate = styled("div", {
  color: "$text_80",
  fontSize: "smaller",
  marginRight: "4px",
});

const TotalAmount = styled("div", {
  fontWeight: "var(--medium)",
  "& .total-text": {
    color: "$text_80",
  },
  "& .rs-text": {
    color: "$secondary_60",
  },
});

const ItemDetailsContainer = styled("div", {
  color: "$text_20",
});

function formatDate(inputDate: string): string {
  const date = new Date(inputDate);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${month}/${day}/${year}`;
}

const formattedDate = formatDate("2022-08-03");

export default function Order({ order }: IOrder) {
  const { storeName, navigateToOrderDetails } = useOrder(order);
  const formattedDate = formatDate(order.date);

  if (!storeName) {
    return <Spinner />;
  }

  return (
    <OrderContainer onClick={navigateToOrderDetails}>
      <OrderTop>
        <OrderLeft>
          <OrderId>
            Order <span className="bold-text">{order.referenceId}</span>
          </OrderId>
        </OrderLeft>
        <OrderRight>
          <OrderDate>{formattedDate} </OrderDate>
          <Chip
            text={order.status}
            color={statusToColorMapper[order.status]}
            isOnClick={false}
            isFilled={true}
          />
        </OrderRight>
      </OrderTop>
      <OrderMiddle>
        <ItemDetailsContainer>
          <span className="item-count"></span>
          {order.orderDetails.items.length}{" "}
          {order.orderDetails.items.length === 1 ? "item" : "items"}
        </ItemDetailsContainer>
      </OrderMiddle>

      <OrderBottom>
        <TotalAmount>
          <span className="total-text">Total</span>
          <span className="rs-text"> Rs {order.total}</span>
        </TotalAmount>
      </OrderBottom>
    </OrderContainer>
  );
}
