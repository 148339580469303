import { CSSProperties, useEffect, useState } from "react";
import { styled } from "../stitches.config";
import { bodyStyles } from "../typography/BodyStyles";
import * as FeatherIcons from "react-feather";

export const ChipText = styled("p", {
  margin: "0",

  variants: {
    size: {
      small: {
        ...bodyStyles.body_mini_01,
      },
      large: {
        ...bodyStyles.body_02,
      },
    },
    iconPosition: {
      leading: {
        order: "2",
      },
      trailing: {
        order: "1",
      },
    },
  },
});

const getIcon = (IconName: string) => {
  // Retrieve the Icon Component using the Icon name
  const Icon: FeatherIcons.Icon = FeatherIcons[IconName];

  return styled(Icon, {
    variants: {
      size: {
        small: {
          width: "16px",
          height: "16px",
        },

        large: {
          width: "24px",
          height: "24px",
        },
      },
      position: {
        leading: {
          order: "1",
        },
        trailing: {
          order: "2",
        },
      },
    },
  });
};

export const ChipInner = styled("div", {
  backgroundColor: "$caution_100",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "$space$space_10",

  variants: {
    size: {
      small: {
        gap: "4px",
        padding: "4px 6px",
      },
      large: {
        gap: "6px",
        padding: "6px 9px",
      },
    },
  },
});

interface IconData {
  name: string;
  position: "leading" | "trailing";
}

type ValidCSSProperties = keyof CSSProperties;

interface ChipCSS {
  ChipInner: {
    [key in ValidCSSProperties]?: CSSProperties[key];
  };
}

export interface IChipProps {
  size: "small" | "large";
  iconData?: IconData;
  text: string;
  onClick?: React.MouseEventHandler<SVGElement> | undefined;
}

export default function Chip({
  text,
  size,
  iconData,
  onClick,
  ...props
}: IChipProps) {
  const [Icon, setIcon] = useState<ReturnType<typeof getIcon> | undefined>();

  useEffect(() => {
    if (iconData) setIcon(getIcon(iconData.name));
  }, []);

  if (iconData && !Icon) {
    return null;
  }

  return (
    <ChipInner size={size} {...props}>
      {Icon && (
        <Icon onClick={onClick} size={size} position={iconData?.position} />
      )}
      <ChipText size={size} iconPosition={iconData?.position}>
        {text}
      </ChipText>
    </ChipInner>
  );
}
