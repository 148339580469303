import { styled } from "../../../../stitches/stitches.config";
import { IOrderStatus } from "../../../../types/OrderFields";
import { Button } from "../../../../stitches/button/Button";
import { Heading } from "../../../../stitches/typography/HeadingStyles";

interface OrderStatusButtonProps {
  status: IOrderStatus;
  onClick?: () => void;
  state: string;
}

const OrderStatusButtonContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});

const ButtonText = styled(Heading, {
  color: "$black_100",
});

const OrderStatusButton = ({
  status,
  onClick,
  state,
}: OrderStatusButtonProps) => {
  const statusDisplayMap = {
    [IOrderStatus.IN_PROGRESS]: "In Progress",
    [IOrderStatus.COMPLETED]: "Completed",
  };

  const displayStatus = statusDisplayMap[status] || "";

  const backgroundColor =
    status === IOrderStatus.COMPLETED
      ? "$colors$success_100"
      : "#FFD057";
  const variant = status === IOrderStatus.COMPLETED ? "success" : "tertiary";

  return (
    <OrderStatusButtonContainer>
      {state === "display" && (
        <Button
          variant={variant}
          size="large"
          style={{
            width: "90%",
            color: "black",
            backgroundColor: backgroundColor,
          }}
        >
          <ButtonText variant="heading_02">Status: {displayStatus}</ButtonText>
        </Button>
      )}
      {state === "change" && (
        <Button
          variant={status === IOrderStatus.COMPLETED ? "primary" : "tertiary"}
          size="large"
          onClick={onClick}
          style={{
            width: "90%",
            color: "black",
            backgroundColor: backgroundColor,
          }}
        >
          <ButtonText variant="heading_02">Mark Order: {displayStatus}</ButtonText>
        </Button>
      )}
    </OrderStatusButtonContainer>
  );
};

export default OrderStatusButton;
