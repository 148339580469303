import React from "react";
import { CustomerStore } from "../../../../pullstate/Customer";
import { useStoreState } from "pullstate";
import { User } from "react-feather";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { styled } from "../../../../stitches/stitches.config";

const PersonalInfoContainer = styled("div", {
  width: "100%",
  marginBottom: "$space$space_16",
  padding: "20px $space$space_10 20px $space$space_10",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  paddingBottom: "8px",
});

const PersonalInfoHeaderIcon = styled(User, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

export default function PersonalInfoCard() {
  const { email, phoneNumber, firstName, lastName } = useStoreState(
    CustomerStore,
    (s) => s.checkout.deliveryDetails
  );

  return (
    <PersonalInfoContainer>
      <PersonalInfoHeadingContainer>
        <PersonalInfoHeaderIcon size={24} />
        <PersonalInfoHeaderHeading variant="body_02">
          Personal Information
        </PersonalInfoHeaderHeading>
      </PersonalInfoHeadingContainer>
      <Body variant="body_01">
        {firstName} {lastName}
      </Body>
      <Body variant="body_01">{phoneNumber}</Body>
      <Body variant="body_01">{email}</Body>
    </PersonalInfoContainer>
  );
}
