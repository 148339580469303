import React from "react";
import { Image } from "react-feather";
import { Body } from "../../../stitches/typography/BodyStyles";
import { Dashboard } from "@uppy/react";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./Uppy.css";
import { styled } from "../../../stitches/stitches.config";

const StickyButtonContainer = styled("div", {
  bottom: "50px",
  backgroundColor: "$white_100",
  filter: "drop-shadow($space$space_0 5px 22px rgba(0, 0, 0, 0.1))",
  borderRadius: "10px",
  overflowY: "auto",
  "@Desktop": {
    width: "374px",
  },
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const ImageHeaderIcon = styled(Image, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

interface IDesktopProductImageProps {
  uppyRef: React.MutableRefObject<Uppy>;
}

export default function ProductImageContainer({
  uppyRef,
}: IDesktopProductImageProps) {
  return (
    <StickyButtonContainer>
      <PersonalInfoHeadingContainer>
        <ImageHeaderIcon size={24} />
        <PersonalInfoHeaderHeading variant="body_02">
          Images
        </PersonalInfoHeaderHeading>
      </PersonalInfoHeadingContainer>
      <Dashboard
        uppy={uppyRef.current}
        hideUploadButton={true}
        proudlyDisplayPoweredByUppy={false}
      />
    </StickyButtonContainer>
  );
}
