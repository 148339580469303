import { styled } from "@stitches/react";
import React from "react";
import { Body } from "../../stitches/typography/BodyStyles";
import Radio, { RadioItemProps } from "../../stitches/radioButton/RadioButton";
import { Button } from "../../stitches/button/Button";
import { Sliders } from "react-feather";

const FilterContainer = styled("div", {
  width: "224px",
  height: "auto",
  marginBottom: "$space$space_16",
  marginLeft: "$space$space_0",
  padding: "20px $space$space_10 20px $space$space_10",
  display: "flex",
  flexDirection: "column",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  borderRadius: "$space$space_10",
});

const FilterHeadingContainer = styled("div", {
  display: "flex",
  gap: "$space$space_16",
  marginBottom: "$space$space_16",
  borderBottom: "2px solid $grey_100",
  paddingBottom: "8px",
});

const FilterHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const Icon = styled(Sliders, {
  color: "$secondary_60",
});

interface DesktopFilterProps {
  radioItems: Omit<RadioItemProps, "onClick">[];
  onRadioItemClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onSubmit: () => void;
}

export default function DesktopFilter({
  radioItems,
  onRadioItemClick,
  onSubmit,
}: DesktopFilterProps) {
  return (
    <>
      <FilterContainer>
        <FilterHeadingContainer>
          <Icon size={24} />
          <FilterHeaderHeading variant="body_02">
            Filter Products
          </FilterHeaderHeading>
        </FilterHeadingContainer>
        <Radio radioItems={radioItems} onClick={onRadioItemClick}></Radio>
        <br />
        <Button variant="secondary" onClick={onSubmit} size="small">
          Apply
        </Button>
      </FilterContainer>
    </>
  );
}
