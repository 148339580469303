import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AdminStore } from "../../../pullstate/Admin";
import { useContext, useEffect, useState } from "react";
import { OrderWithReferenceId } from "../../../types/OrderFields";
import { ApiClientContext } from "../../../context/ApiClientContext";
import UseAuth from "../../../hooks/UseAuth";
import { handleError } from "../../../util/ErrorHandler";

export const useOrderDetails = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState<
    OrderWithReferenceId | undefined
  >();
  const { storeName, orderId } = useParams();
  const order = AdminStore.useState((s) => s.ordersStore.orders).find(
    (order) => order.id === orderId
  );
  const { user } = UseAuth();
  const apiClient = useContext(ApiClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchOrder = async () => {
    try {
      setIsLoading(true);
      const orderData = await apiClient.default.getOrder(
        storeName!,
        user!.uid,
        orderId!
      );

      setOrderData(orderData);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (order || orderData) return;
    if (user) fetchOrder();
  }, [user]);

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const invoice = await axios.get<Blob>(
        `http://localhost:3100/api/v1/seller/order/${storeName}/${
          order!.id
        }/invoice`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([invoice.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `order-summary.pdf`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const navigateBack = () => {
    navigate(`/${storeName}/seller/order`);
  };

  const selectedOrder = orderData || order;
  return {
    order: selectedOrder,
    handleDownload,
    navigateBack,
    isLoading,
  };
};
