import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Display } from "../../../stitches/typography/DisplayStyles";

interface IProductTitle {
  title: string;
  price: number;
}

const TitleContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "22px",
});

const Price = styled(Heading, {
  color: "$secondary_60",
});

export default function ProductTitle({ title, price }: IProductTitle) {
  return (
    <TitleContainer>
      <Heading variant="heading_03">{title}</Heading>
      <Price variant="heading_03">Rs. {price}</Price>
    </TitleContainer>
  );
}
