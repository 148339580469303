import React from "react";
import Radio, { RadioItemProps } from "../../stitches/radioButton/RadioButton";
import { Button } from "../../stitches/button/Button";
import { styled } from "@stitches/react";
import Drawer from "react-modern-drawer";
import { Heading } from "../../stitches/typography/HeadingStyles";

const StyledButton = styled(Button, {
  width: "100%",
  height: "48px",
  padding: "$space$space_16",
  marginBottom: "$space$space_16",
  fontSize: "$space$space_16",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  padding: "15px",
});

export interface StoreFilterProps {
  open: boolean;
  radioItems: Omit<RadioItemProps, "onClick">[];
  onModalClose: () => void;
  onRadioItemClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onSubmit: () => void;
}

export default function StoreFilter({
  open,
  onModalClose,
  radioItems,
  onRadioItemClick,
  onSubmit,
}: StoreFilterProps) {
  return (
    <Drawer open={open} onClose={onModalClose} direction="bottom" size="25vh">
      <Container>
        <Heading variant="heading_02">Sort By</Heading>
        <Radio radioItems={radioItems} onClick={onRadioItemClick}></Radio>
        <StyledButton variant="secondary" onClick={onSubmit}>
          Apply Filter
        </StyledButton>
      </Container>
    </Drawer>
  );
}
