import { useNavigate, useParams } from "react-router-dom";
import StoreImage from "../../../customer/StoreFront/StoreImage/view/StoreImage";
import Chip from "../../../stitches/chip/Chip";
import { styled } from "../../../stitches/stitches.config";
import { headings } from "../../../stitches/typography/HeadingStyles";
import { Product as ProductType } from "../../../types/product/new/product.model";
import { AdminStore } from "../../../pullstate/Admin";

type ProductProps = {
  product: ProductType;
};

const Title = styled("p", {
  ...headings.heading_01,
  margin: "0 0 5px 0",
  color: "$text_60",
});

const Price = styled("p", {
  margin: "0",
  ...headings.heading_02,
  color: "$secondary_60",
});

const PriceVariantsContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px $space$space_10 $space$space_10  0",
});

const TitlePriceVariantsContainer = styled("div", {
  marginTop: "$space$space_10",
  paddingLeft: "$space$space_10",
});

const StoreProductInner = styled("div", {
  position: "relative",
  borderRadius: "$space$space_10",

  variants: {
    outOfStock: {
      true: {
        backgroundColor: "$grey_100",
      },
      false: {
        "@Desktop": {
          "&:hover img": { transform: "scale(1.4) " },
        },
      },
    },
  },

  maxWidth: "165px",

  defaultVariants: {
    outOfStock: "false",
  },
});

const OutOfStockChip = styled(Chip, {
  position: "absolute",
  left: "6px",
  top: "6px",
});

const NumOfVariantsChip = styled(Chip, {
  backgroundColor: "$primary_100",
});

export default function Product({ product }: ProductProps) {
  const navigate = useNavigate();
  const { storeName } = useParams();
  const outOfStock = !!!product.variants.find(
    (variant) => variant.quantity > 0
  );

  const navigateToProductDetails = () => {
    AdminStore.update((s) => {
      s.selectedProduct = product;
    });
    navigate(`/${storeName}/seller/product/${product.id}`);
  };

  return (
    <StoreProductInner onClick={navigateToProductDetails}>
      <StoreImage images={product.images} />
      {outOfStock && <OutOfStockChip size="small" text="Out of Stock" />}
      <TitlePriceVariantsContainer>
        <Title>{product.name}</Title>
        <PriceVariantsContainer>
          <Price>Rs. {product.variants[0].price}</Price>
          {product.variants.length > 1 && (
            <NumOfVariantsChip
              size="small"
              text={`${product.variants.length} variants`}
            />
          )}
        </PriceVariantsContainer>
      </TitlePriceVariantsContainer>
    </StoreProductInner>
  );
}
