import { useForm } from "react-hook-form";

export type VerifyCodeFormData = {
  code: number;
};

enum VerifyCodeFormDataFields {
  code = "code",
}

type VerifyCodeProps = {
  verificationType: string;
  onSubmit: (data: VerifyCodeFormData) => void;
};

export function VerifyCodeForm({
  verificationType,
  onSubmit,
}: VerifyCodeProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<VerifyCodeFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <p> Enter verification code sent to your {verificationType} </p>
        {errors.code && <p>{errors.code.message}</p>}
        <input
          className="Signup-Form-Input"
          type="number"
          {...register(VerifyCodeFormDataFields.code, {
            required: "Input verification code",
            valueAsNumber: true,
          })}
          placeholder="E.g. 1234"
        />
      </div>
      <button type="submit">Next</button>
    </form>
  );
}
