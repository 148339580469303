import React from "react";
import { Product } from "../../../../types/product/new/product.model";
import StoreImage from "../../StoreImage/view/StoreImage";
import { headings } from "../../../../stitches/typography/HeadingStyles";
import { styled } from "../../../../stitches/stitches.config";
import Chip from "../../../../stitches/chip/Chip";
import { useStoreProduct } from "./useStoreProduct";

type StoreProductProps = {
  product: Product;
};

const Title = styled("p", {
  ...headings.heading_01,
  margin: "0 0 5px 0",
  color: "$text_60",
});

const Price = styled("p", {
  margin: "0",
  ...headings.heading_02,
  color: "$secondary_60",
});

const PriceVariantsContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px $space$space_10 $space$space_10  0",
});

const TitlePriceVariantsContainer = styled("div", {
  marginTop: "$space$space_10",
  paddingLeft: "$space$space_10",
});

const StoreProductInner = styled("div", {
  position: "relative",
  borderRadius: "$space$space_10",

  variants: {
    outOfStock: {
      true: {
        backgroundColor: "$grey_100",
      },
      false: {
        "@Desktop": {
          "&:hover img": { transform: "scale(1.4) " },
        },
      },
    },
  },

  "@Desktop": {
    width: "218px",
  },

  "@Wide": {
    width: "218px",
  },

  defaultVariants: {
    outOfStock: "false",
  },
});

const OutOfStockChip = styled(Chip, {
  position: "absolute",
  left: "6px",
  top: "6px",
});

const NumOfVariantsChip = styled(Chip, {
  backgroundColor: "$primary_100",
});

export default function StoreProduct({ product }: StoreProductProps) {
  const { name, price, numOfVariants, images, handleClick, outOfStock } =
    useStoreProduct(product);

  return (
    <StoreProductInner
      {...(outOfStock ? { outOfStock: true } : { onClick: handleClick })}
    >
      <StoreImage images={images} />
      {outOfStock && <OutOfStockChip size="small" text="Out of Stock" />}
      <TitlePriceVariantsContainer>
        <Title>{name}</Title>
        <PriceVariantsContainer>
          <Price>Rs. {price}</Price>
          {numOfVariants > 1 && (
            <NumOfVariantsChip
              size="small"
              text={`${numOfVariants} variants`}
            />
          )}
        </PriceVariantsContainer>
      </TitlePriceVariantsContainer>
    </StoreProductInner>
  );
}
