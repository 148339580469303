import { Card } from "./Card/Card";
import { Monitor, Package, PieChart } from "react-feather";
import { FaWhatsapp } from "react-icons/fa";
import UseWindowHook from "../../../../hooks/UseWindowHook";
import { styled } from "../../../../stitches/stitches.config";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import { Body } from "../../../../stitches/typography/BodyStyles";

const Container = styled("div", {
  display: "flex",
  width: "calc(100% - 32px)",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 16px",

  "@Mobile": { rowGap: "35px" },

  "@Tablet": { rowGap: "35px" },
});

const FeaturesHeading = styled(Heading, {
  color: "$secondary_60",
});

const FeaturesBody = styled(Body, {
  textAlign: "center",
});

const FeaturesBodyDesktop = styled(Heading, {
  marginTop: "16px",
  textAlign: "center",

  "@Desktop": {
    marginTop: "26px",
    maxWidth: "872px",
  },
});

const CardsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  rowGap: "35px",
  width: "100%",
  alignItems: "center",

  "@Desktop": {
    flexDirection: "row",
    columnGap: "60px",
    marginTop: "66px",
    marginBottom: "60px",
  },
});

export const Features = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { width } = UseWindowHook();

  const isDesktopView: boolean = width >= 1200;

  return (
    <Container {...props}>
      <FeaturesHeading variant={isDesktopView ? "heading_07" : "heading_05"}>
        Features
      </FeaturesHeading>
      {isDesktopView ? (
        <FeaturesBodyDesktop variant="heading_04">
          All the bells and whistles you need to start and build your e-commerce
          presence
        </FeaturesBodyDesktop>
      ) : (
        <FeaturesBody variant="body_01">
          All the bells and whistles you need to start and build your e-commerce
          presence
        </FeaturesBody>
      )}
      <CardsContainer>
        <Card
          FeatureIcon={Monitor}
          headingContent="Fast & Easy Store Setup"
          bodyContent="Get your store online in minutes. With just a few fields to fill in, you can swiftly set up your business and start selling hassle-free."
        />
        <Card
          FeatureIcon={Package}
          headingContent="Efficient Order Handling"
          bodyContent="Easily track your orders, manage different order statuses, and access invoices, ensuring smooth and organized handling of your business transactions."
        />
        <Card
          FeatureIcon={PieChart}
          isFutureFeature={true}
          headingContent="Power Analytics Built-in"
          bodyContent="Unleash the power of analytics with our built-in tools. Gain valuable insights into your business with top-notch analytics, enabling you to make data-driven decisions and drive growth."
        />
      </CardsContainer>

      <Card
        FeatureIcon={FaWhatsapp}
        isWhatsAppCard={true}
        isFutureFeature={true}
        headingContent="Manage Store Through WhatsApp"
        bodyContent="Take full control of your business through WhatsApp, effortlessly handling operations, engaging customers, and maximizing efficiency with autonomous store management."
      />
    </Container>
  );
};
