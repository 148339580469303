import React, { useState } from "react";
import { styled } from "../../../stitches/stitches.config";
import { Header } from "../Components/Header/Header";
import Banner from "../Components/Banner/Banner";
import { Features } from "../Components/Features/Features";
import { Footer } from "../Components/Footer/Footer";
import Spinner from "../../../components/Spinner/Spinner";

const Container = styled("div", {
  position: "relative",
  paddingTop: "calc(145px + 46px)",
  paddingBottom: "calc(394px + 113px)",
  zIndex: "0",
  overflow: "hidden",
});

export default function Landing() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Container>
        <Header />
        <Banner id="banner" setIsLoading={setIsLoading} />
        <Features id="features" />
        <Footer />
      </Container>
    </>
  );
}
