import React from "react";
import {
  Attribute,
  VariantAttribute,
} from "../../../types/product/new/product.model";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Button } from "../../../stitches/button/Button";
import { styled } from "../../../stitches/stitches.config";

export const StyledButton = styled(Button, {
  height: "40px",
  boxSizing: "border-box",
  lineHeight: "$space$space_20 !important",
  variants: {
    selected: {
      true: {
        backgroundColor: "$secondary_60 !important",
        color: "$white_100 !important",
      },
    },
  },
});

const Name = styled(Heading, {
  flexBasis: "100%",
  marginBottom: "$space$space_16",
});

const AttributeButtonsContainer = styled("div", {
  display: "flex",
  gap: "$space$space_10",
  flexWrap: "wrap", // Allow the flex items to wrap onto the next line
});

interface ProductItemAttributeProps {
  attribute: VariantAttribute;
  selectedAtrributes: Attribute[];
  handleOnClick: (attributeValue: string, attributeName: string) => void;
}
export default function ProductItemAttribute({
  attribute,
  selectedAtrributes,
  handleOnClick,
}: ProductItemAttributeProps) {
  return (
    <>
      <Name variant="heading_02" color="$text_20">
        Select {attribute.name}
      </Name>
      <AttributeButtonsContainer>
        {attribute.value.map((attributeValue, key) => {
          const isSelected = selectedAtrributes.find((selectedAttribute) => {
            return selectedAttribute.value === attributeValue;
          });
          return (
            <StyledButton
              key={key}
              variant="secondary"
              outlined="true"
              size="small"
              selected={!!isSelected}
              onClick={() => handleOnClick(attributeValue, attribute.name)}
            >
              {attributeValue}
            </StyledButton>
          );
        })}
      </AttributeButtonsContainer>
    </>
  );
}
