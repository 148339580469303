import { styled } from "@stitches/react";
import { bodyStyles } from "../typography/BodyStyles";
import * as FeatherIcons from "react-feather";
import { ResponseIndicator } from "./ResponseIndicator";
import React, { CSSProperties } from "react";

export const textFieldReset = {
  appearance: "none",
  borderWidth: "0",
  boxSizing: "border-box",
  fontFamily: "inherit",
  margin: "0",
  outline: "none",
  padding: "0",
  width: "100%",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  "&::before": {
    boxSizing: "border-box",
  },
  "&::after": {
    boxSizing: "border-box",
  },
};
export const InputTextBox = styled("input", {
  ...textFieldReset,
  position: "relative",
  height: "48px",
  width: "100%",
  padding: "$space$space_10 $space$space_14",
  borderRadius: "$space$space_10",
  color: "$black_100",
  backgroundColor: "$grey_100",
  border: "2px solid transparent",
  "&:hover:not(:disabled)": {
    border: "2px solid $grey_50",
  },
  "&:focus:not(:disabled)": {
    border: "2px solid $grey_50",
    backgroundColor: "$white_100",
    color: "$text_40",
  },
  "&:disabled": {
    backgroundColor: "$grey_50",
    color: "$text_100",
  },
  ...bodyStyles.body_01,

  variants: {
    leadingIcon: {
      true: {
        display: "flex",
        paddingLeft: "calc($space$space_14 + 24px)",
      },
    },
    trailingIcon: {
      true: {
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "calc($space$space_14 + 24px)",
      },
    },
    isTextArea: {
      true: {
        height: "auto",
      },
      false: {
        height: "48px",
      },
    },
  },
}) as any;

export const TextBoxContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$space$space_10",
});

export const TextBoxLabel = styled("label", {
  ...bodyStyles.body_02,
  color: "$black_100",
  lineHeight: 1.2,
});

export const InputFieldContainer = styled("div", {
  position: "relative",
});

export enum TextBoxIconPosition {
  LEADING = "leading",
  TRAILING = "trailing",
}

type ValidCSSProperties = keyof CSSProperties;

interface TextBoxCSS {
  TextBoxContainer?: {
    [key in ValidCSSProperties]?: CSSProperties[key];
  };
}

export type TextBoxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  icon?: {
    position: TextBoxIconPosition;
    Icon: FeatherIcons.Icon;
    onIconClick?: () => void;
  };
  error?: string;
  isMultiLine?: boolean;
};

export const TextBox = React.forwardRef<HTMLInputElement, TextBoxProps>(
  ({ icon, error, label, isMultiLine, ...props }, ref) => {
    const iconPosition = icon ? icon.position : undefined;
    const Icon = icon ? icon.Icon : undefined;

    const LeadingIconContainer =
      Icon &&
      styled(Icon!, {
        position: "absolute",
        top: "50%",
        left: "$space$space_10",
        transform: "translateY(-50%)",
      });

    const TrailingIconContainer =
      Icon &&
      styled(Icon, {
        position: "absolute",
        top: "50%",
        right: "$space$space_10",
        transform: "translateY(-50%)",
      });

    const leadingIcon = Boolean(iconPosition === "leading");
    const trailingIcon = Boolean(iconPosition === "trailing");
    return (
      <TextBoxContainer {...props}>
        {label && <TextBoxLabel>{label}</TextBoxLabel>}
        <InputFieldContainer>
          <InputTextBox
            as={isMultiLine ? "textarea" : "input"}
            {...(isMultiLine ? { rows: 5, cols: 40 } : {})}
            isTextArea={isMultiLine}
            {...props}
            ref={ref}
            leadingIcon={leadingIcon}
            trailingIcon={trailingIcon}
          />
          {leadingIcon && LeadingIconContainer && (
            <LeadingIconContainer onClick={icon?.onIconClick} />
          )}
          {trailingIcon && TrailingIconContainer && (
            <TrailingIconContainer onClick={icon?.onIconClick} />
          )}
        </InputFieldContainer>
        {error && <ResponseIndicator variant="error" children={error} />}
      </TextBoxContainer>
    );
  }
);
