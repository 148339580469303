import { useNavigate, useParams } from "react-router-dom";
import { AdminStore } from "../../../pullstate/Admin";
import { useStoreState } from "pullstate";
import { useMemo } from "react";
import { parse } from "date-fns";

export const useOrdersTable = () => {
  const navigate = useNavigate();
  const { storeName } = useParams();
  const navigateToOrderDetails = (orderId: string) => {
    navigate(`/${storeName}/seller/order/${orderId}`);
  };

  const { orders, filteredOrders, filterSearchTerm, selectedStatusFilter } =
    useStoreState(AdminStore, (s) => s.ordersStore);
  const orderSet = filterSearchTerm.length > 0 ? filteredOrders : orders;

  const filteredOrderSetByStatus = useMemo(() => {
    return !selectedStatusFilter
      ? orderSet
      : orderSet.filter((order) => order.status === selectedStatusFilter);
  }, [orderSet, selectedStatusFilter]);

  const sortedData = useMemo(() => {
    return [...filteredOrderSetByStatus].sort((a, b) => {
      const format = "MM/dd/yyyy";
      const dateA = parse(a.date, format, new Date()) as Date;
      const dateB = parse(b.date, format, new Date()) as Date;

      return dateB.getTime() - dateA.getTime();
    });
  }, [filteredOrderSetByStatus]);
  return {
    navigateToOrderDetails,
    filteredOrderSetByStatus,
    sortedData,
  };
};
