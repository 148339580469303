import React, { useState } from "react";
import PaymentFooter from "./PaymentFooter";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import SelectPaymentType from "./SelectPaymentType";
import { styled } from "@stitches/react";
import { useDeviceDimensions } from "../../../hooks/useDevice";

interface IPaymentProps {
  handleOnClick: () => void;
  navigateBackToDeliveryDetails: () => void;
}

export enum PaymentType {
  CASH_ON_DELIVERY = "Cash on Delivery",
  CREDIT_CARD = "Credit Card",
}

const PaymentTypeHeading = styled(Heading, {
  marginBottom: "26px",
  padding: "$space$space_16 $space$space_16 $space$space_0 $space$space_16",
  color: "$secondary_60",
});

export default function Payment({
  handleOnClick,
  navigateBackToDeliveryDetails,
}: IPaymentProps) {
  const [active, setActive] = useState(false);
  const { isDesktop } = useDeviceDimensions();
  return (
    <>
      {!isDesktop && (
        <PaymentTypeHeading variant="heading_02">
          Select a payment method
        </PaymentTypeHeading>
      )}

      <SelectPaymentType paymentTypes={[PaymentType.CASH_ON_DELIVERY]} />
      {!isDesktop && (
        <PaymentFooter
          navigateToDeliveryDetails={navigateBackToDeliveryDetails}
          navigateToOrderReview={handleOnClick}
        />
      )}
    </>
  );
}
