import React from "react";
import OrderSummary from "../OrderInformation/OrderSummary/OrderSummary";
import { useOrderDetails } from "./useOrderDetails";
import Spinner from "../../../components/Spinner/Spinner";

export default function OrderDetails() {
  const { order, navigateBack, isLoading } = useOrderDetails();

  if (isLoading) {
    return <Spinner />;
  }

  if (!order) {
    return null;
  }

  return <OrderSummary order={order} navigateBack={navigateBack} />;
}
