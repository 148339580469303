import { Store, registerInDevtools } from "pullstate";
import { Product, Variant } from "../types/product/new/product.model";
import { CartProduct } from "../types/CartFields";
import { enableMapSet } from "immer";
import { Checkout, PaymentType } from "../customer/Checkout/Types/Checkout";

enableMapSet();

export interface ICartStore {
  isOpen: boolean;
  cartDisplayMap: Map<string, CartProduct[]>;
  numberOfItems: number;
  total: number;
}

export interface ICustomerStore {
  storeDisplayName: string | undefined;
  selectedProduct: Product | undefined;
  selectedVariant: Variant | undefined;
  products: Product[];
  isFiltered: boolean;
  filter: string | undefined;
  storeViewProducts: Product[];
  storeViewProductsWithoutFiltering: Product[];
  cart: ICartStore;
  checkout: Checkout;
}

export const CustomerStore = new Store<ICustomerStore>({
  storeDisplayName: undefined,
  selectedProduct: undefined,
  selectedVariant: undefined,
  products: [],
  storeViewProducts: [],
  storeViewProductsWithoutFiltering: [],
  isFiltered: false,
  filter: undefined,
  cart: {
    isOpen: false,
    cartDisplayMap: new Map(),
    numberOfItems: 0,
    total: 0,
  },
  checkout: {
    activeStep: 0,
    steps: [
      { label: "Personal Information", iconName: "User" },
      { label: "Delivery Options", iconName: "DollarSign" },
      { label: "Review Order", iconName: "FileText" },
    ],
    deliveryDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: {
        addressLine: "",
        city: "",
        country: "",
      },
    },
    paymentDetails: {
      mode: PaymentType.CASH_ON_DELIVERY,
    },
  },
});

export const resetCart = (storename: string): void => {
  CustomerStore.update((s) => {
    s.cart = {
      isOpen: false,
      cartDisplayMap: new Map(),
      numberOfItems: 0,
      total: 0,
    };
  });
  sessionStorage.removeItem(`${storename}_gahak_cart`);
};

registerInDevtools({
  CustomerStore,
});
