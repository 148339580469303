import { CheckCircle, ChevronLeft, ChevronRight } from "react-feather";
import { Button } from "../../../../stitches/button/Button";
import OrderTotal from "./OrderTotal";
import { styled } from "@stitches/react";

const Container = styled("div", {
  width: "100%",
  position: "fixed",
  bottom: "$space$space_0",
  boxShadow: "$space$space_0 -5px 22px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  height: "142px",
  backgroundColor: "$white_100",
  padding: "$space$space_0 $space$space_16 $space$space_0 $space$space_16",
});

const NavigateToPaymentButton = styled(Button, {
  width: "93px",
  height: "48px",
});

const CompleteOrderbutton = styled(Button, {
  width: "100%",
  height: "48px",
});

const FooterButtonsContainer = styled("div", {
  display: "flex",
  columnGap: "16px",
  marginTop: "26px",
});

interface IFooterProps {
  onClick: () => void;
  navigateBackToPayment: () => void;
}

export const Footer = ({ onClick, navigateBackToPayment }: IFooterProps) => {
  return (
    <Container>
      <OrderTotal />
      <FooterButtonsContainer>
        <NavigateToPaymentButton
          variant="secondary"
          withIcon
          outlined
          size="small"
          onClick={navigateBackToPayment}
        >
          <ChevronLeft size={24} />
          Back
        </NavigateToPaymentButton>
        <CompleteOrderbutton
          variant="secondary"
          withIcon
          size="small"
          onClick={onClick}
        >
          Complete order
          <CheckCircle size={24} />
        </CompleteOrderbutton>
      </FooterButtonsContainer>
    </Container>
  );
};
