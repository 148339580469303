import React from "react";
import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { AdminStore } from "../../../pullstate/Admin";
import Card, {
  ICardProps,
} from "../../../components/Seller/Dashboard/Overview/Card";
import { Body } from "../../../stitches/typography/BodyStyles";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "calc(100% - 32px)",
  margin: "auto",
});

const DashboardHeading = styled(Heading, {
  color: "$secondary_60",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "26px 0 32px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
});

const Section = styled("div", {});

const SectionTitle = styled(Heading, {
  color: "$black_100",
  marginBottom: "$space_16",
});

const SellerGuidesSectionTitle = styled(Heading, {
  color: "$black_100",
  marginBottom: "10px",
});

const SellerGuidesSectionDescription = styled(Body, {
  color: "$text_60",
});
const CardsContainer = styled("div", {
  display: "grid",
  gap: "26px",

  "@Mobile": {
    gridTemplateColumns: "repeat(1, minmax(0,1fr))",
  },
  "@Tablet": {
    gridTemplateColumns: "repeat(2, minmax(0,1fr))",
  },
});

const SellerGuidesCardsContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(326px, 1fr))",
  marginTop: "26px",
  gap: "26px",
});

const SellerGuideCard = styled("div", {
  borderRadius: "10px",
  textAlign: "center",
});

const SectionsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  rowGap: "66px",
});

const VideoFrame = styled("iframe", {
  width: "326px",
  height: "209px",
  border: "none",
  borderRadius: "10px",
});

export interface IMobileDashboardProps {
  overviewCards: ICardProps[];
}

const guidesLinks = [
  "https://www.youtube.com/watch?v=948bKC6pGcA",
  "https://www.youtube.com/watch?v=NN7YEIlc-Oc",
  "https://www.youtube.com/watch?v=ar6aLcEN9JE",
  "https://www.youtube.com/watch?v=dYrtu34RUr4",
  "https://www.youtube.com/watch?v=CiyNGOIbwcE",
  "https://www.youtube.com/watch?v=_Eg0z7Nu8pU",
];

export default function MobileDashboard({
  overviewCards,
}: IMobileDashboardProps) {
  return (
    <MainContainer>
      <HeaderContainer>
        <IconBadge
          outlined={false}
          size="large"
          iconName="AlignJustify"
          customCSS={{
            Icon: {
              color: "secondary_60",
            },
            IconBadgeContainer: {
              // marginLeft: "16px",
            },
          }}
          onClick={() => {
            AdminStore.update((s) => {
              s.isMenuOpen = true;
            });
          }}
        />
        <DashboardHeading variant="heading_05">Dashboard</DashboardHeading>
      </HeaderContainer>

      <SectionsContainer>
        <Section>
          <SectionTitle variant="heading_04">Overview</SectionTitle>
          <CardsContainer>
            {overviewCards.map((card, index) => (
              <Card
                key={index}
                iconName={card.iconName}
                iconColor={card.iconColor}
                value={card.value}
                label={card.label}
              />
            ))}
          </CardsContainer>
        </Section>

        <Section>
          <SellerGuidesSectionTitle variant="heading_04">
            Seller Guides
          </SellerGuidesSectionTitle>
          <SellerGuidesSectionDescription variant="body_01">
            Tips & tricks on how to crack the e-commerce market and make your
            business grow
          </SellerGuidesSectionDescription>
          <SellerGuidesCardsContainer>
            {guidesLinks.map((link, index) => (
              <SellerGuideCard>
                <VideoFrame
                  src={`https://www.youtube.com/embed/${link.split("v=")[1]}`}
                  allowFullScreen
                />
              </SellerGuideCard>
            ))}
          </SellerGuidesCardsContainer>
        </Section>
      </SectionsContainer>
    </MainContainer>
  );
}
