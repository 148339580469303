import React from "react";
import {
  UseFormRegister,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { ProductFields } from "../../../types/ProductFields";

import { TextBox } from "../../../stitches/textbox/TextBox";
import { Archive, Copy, Plus, Trash } from "react-feather";
import { Button } from "../../../stitches/button/Button";
import { Body } from "../../../stitches/typography/BodyStyles";
import ProductVariantDialog from "./ProductVariantDialog";
import _ from "lodash";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { styled } from "../../../stitches/stitches.config";

interface IDesktopProductVariantsProps {
  register: UseFormRegister<ProductFields>;
  unregister: UseFormUnregister<ProductFields>;
  watch: UseFormWatch<ProductFields>;
  setHasVariantToTrue: () => void;
}

const InputWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "$space$space_16",
  alignItems: "center",
  marginBottom: "$space$space_20",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const VariantHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  alignItems: "center",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
  width: "100%",
});

const VariantHeadingIconContainer = styled(Archive, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const VariantHeadingHeader = styled(Body, {
  color: "$secondary_60",
});

export default function ProductVariants({
  register,
  unregister,
  watch,
  setHasVariantToTrue,
}: IDesktopProductVariantsProps) {
  const [indexes, setIndexes] = React.useState<number[]>([0]);
  const [counter, setCounter] = React.useState(1);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isVariantFieldSelected, setIsVariantFieldSelected] =
    React.useState(false);
  const attributeLabel = watch("attributes.0.name");

  const addVariant = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeVariant = (index: number) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
    if (counter - 1 === 0) {
      setIsVariantFieldSelected(false);
    }
    unregister(`variants.${index}.attributes.${0}.value`);
    unregister(`variants.${index}.quantity`);
    unregister(`variants.${index}.price`);
  };

  const handleVariantButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSuccessDialogClose = () => {
    setIsDialogOpen(false);
    setIsVariantFieldSelected(true);
    setCounter(1);
    setIndexes([0]);
    setHasVariantToTrue();
  };

  return (
    <>
      <VariantHeadingContainer>
        <VariantHeadingIconContainer size={24} />
        <VariantHeadingHeader variant="body_02">
          Inventory Details
        </VariantHeadingHeader>
        <Button
          variant="secondary"
          outlined
          withIcon
          type="button"
          onClick={handleVariantButtonClick}
          style={{ marginLeft: "auto" }}
          disabled={isVariantFieldSelected}
        >
          <Copy size={24} />
          Create Variant
        </Button>
      </VariantHeadingContainer>
      {isVariantFieldSelected ? (
        <>
          {indexes.map((index) => (
            <InputWrapper key={index}>
              <TextBox
                label={index === 0 ? _.capitalize(attributeLabel) : undefined}
                placeholder="Value"
                {...register(`variants.${index}.attributes.${0}.value`, {
                  required: "Required Field",
                })}
              />
              <TextBox
                label={index === 0 ? "Price (PKR)" : undefined}
                placeholder="Enter price"
                {...register(`variants.${index}.price`, {
                  required: "Required Field",
                })}
              />
              <TextBox
                label={index === 0 ? "Quantity" : undefined}
                placeholder="Enter quantity"
                {...register(`variants.${index}.quantity`, {
                  required: "Required Field",
                })}
              />
              <IconBadge
                onClick={removeVariant(index)}
                iconName="Trash"
                outlined={false}
                size="medium"
                customCSS={{
                  Icon: {
                    color: "$danger_100",
                  },
                  IconBadgeContainer: {
                    transform: "translateY(10px)",
                  },
                }}
              />
            </InputWrapper>
          ))}
          <Button
            type="button"
            onClick={addVariant}
            variant="secondary"
            size="small"
            withIcon
          >
            <Plus size={24} />
            Add Variant
          </Button>
        </>
      ) : (
        <InputWrapper>
          <Input
            label="Price (PKR)"
            placeholder="Enter Price"
            {...register(`variants.${0}.price`, {
              required: "Required Field",
            })}
            type="number"
          />
          <Input
            label="Quantity"
            placeholder="Enter quantity"
            {...register(`variants.${0}.quantity`, {
              required: "Required Field",
            })}
            type="number"
          />
        </InputWrapper>
      )}

      <ProductVariantDialog
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleDialogClose}
        register={register}
        unregister={unregister}
        handleSuccessDialogClose={handleSuccessDialogClose}
        isEmpty={!!attributeLabel?.length}
      />
    </>
  );
}
