import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner/Spinner";
import { auth } from "../firebase/firebase.config";

export const AuthContext = React.createContext<{
  currentUser: User | null;
  userStoreName: string | undefined;
}>({
  currentUser: null,
  userStoreName: undefined,
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [pending, setPending] = useState(true);
  const [userStoreName, setUserStoreName] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      setPending(true);
      if (user) {
        setCurrentUser(user);
        const idTokenResult = await user.getIdTokenResult();
        const userStoreNameClaim = idTokenResult.claims.storeName as
          | string
          | undefined;
        setUserStoreName(userStoreNameClaim);
      } else {
        setCurrentUser(null);
        setUserStoreName(undefined);
      }
      setPending(false);
    });

    return () => unsubscribe();
  }, []);

  if (pending) {
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userStoreName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
