import React from "react";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { styled } from "../../../stitches/stitches.config";
import { Variant } from "../../../types/product/new/product.model";
import Counter, {
  CounterSize,
  CounterVariant,
} from "../../../components/Counter/Counter";

interface IProductQuantity {
  selectedQuantity: number;
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>;
  selectedVariant: Variant;
}

const Container = styled("div", {
  margin: "26px $space$space_0 36px $space$space_0",
});

export default function ProductQuantity({
  selectedQuantity,
  setSelectedQuantity,
  selectedVariant,
}: IProductQuantity) {
  return (
    <Container>
      <Heading variant="heading_02">Quantity</Heading>
      <Counter
        variant={CounterVariant.ProductPage}
        size={CounterSize.Medium}
        availableQuantity={selectedVariant.quantity}
        selectedQuantity={selectedQuantity}
        changeItemQuantity={setSelectedQuantity}
      />
    </Container>
  );
}
