import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import UseAuth from "../../hooks/UseAuth";
import EditEmail from "./Children/EditEmail";
import EditPassword from "./Children/EditPassword";
import { ApiClientContext } from "../../context/ApiClientContext";
import { styled } from "../../stitches/stitches.config";
import { Heading } from "../../stitches/typography/HeadingStyles";
import { Button } from "../../stitches/button/Button";
import IconBadge from "../../stitches/iconBadge/IconBadge";

export enum Step {
  View,
  EditEmail,
  EditPassword,
}

const Header = styled("div", {
  display: "flex",
  columnGap: "$space$space_10",
  width: "100%",
  padding: "24px $space$space_16 $space$space_16 $space$space_16",
  boxShadow: "$space$space_0 5px 22px rgba(0, 0, 0, 0.1)",
  marginBottom: "10px",
});

const HeaderTitle = styled(Heading, {
  display: "flex",
  alignItems: "center",
});

const StyledButton = styled(Button, {
  margin: "10px 0",
  width: "50%",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export default function EditCredentials() {
  const [email, setEmail] = useState<string>("");
  const { storeName } = useParams();
  const { user } = UseAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [step, setStep] = useState<Step>(Step.View);
  const apiClient = useContext(ApiClientContext);
  const navigate = useNavigate();

  const editEmail = () => {
    setStep(Step.EditEmail);
  };

  const editPassword = () => {
    setStep(Step.EditPassword);
  };

  useEffect(() => {
    const getEmail = async () => {
      const response = await apiClient.default.getEmail(
        storeName || "",
        user!.uid
      );
      setEmail(response.email);
    };

    user && getEmail();
    setIsLoading(false);
  }, [user]);

  const navigatetoDashboard = () => {
    navigate(`/${storeName}/seller/dashboard`);
  };

  const render = (step: Step) => {
    if (step === Step.EditEmail) {
      return <EditEmail setStep={setStep} email={email} setEmail={setEmail} />;
    } else if (step === Step.EditPassword) {
      return <EditPassword setStep={setStep} />;
    } else {
      return (
        <Container>
          <Header>
            <IconBadge
              outlined={false}
              size="medium"
              iconName="ChevronLeft"
              customCSS={{
                Icon: {
                  color: "$black_100",
                },
              }}
              onClick={navigatetoDashboard}
            />
            <HeaderTitle variant="heading_04">Dashboard</HeaderTitle>
          </Header>
          <h2>Edit Credentials</h2>

          <StyledButton
            variant="secondary"
            size="large"
            outlined
            onClick={editPassword}
          >
            Change Password
          </StyledButton>
        </Container>
      );
    }
  };

  return isLoading || !email ? <Spinner /> : render(step);
}
