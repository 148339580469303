import React from "react";
import { useStoreState } from "pullstate";
import { AdminStore } from "../../../pullstate/Admin";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { styled } from "../../../stitches/stitches.config";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import Product from "./Product";
import { Button } from "../../../stitches/button/Button";
import { Plus } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "auto",
  height: "100vh",
});

const ProductsHeading = styled(Heading, {
  color: "$secondary_60",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "26px 0 32px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
  justifyContent: "space-between",
});

const IconAndHeadingContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ProductsContainer = styled("div", {
  display: "grid",
  columnGap: "32px",
  rowGap: "$space$space_16",
  placeItems: "center center",
  gridTemplateColumns: "repeat(auto-fill, minmax(165px, 1fr))",
});

const AddProductButton = styled(Button, {
  marginRight: "8px",
});

export default function MobileProducts() {
  const products = useStoreState(AdminStore, (s) => s.products);
  const navigate = useNavigate();
  const { storeName } = useParams();
  const navigateToAddProduct = () => {
    navigate(`/${storeName}/seller/product/new`);
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <IconAndHeadingContainer>
          <IconBadge
            outlined={false}
            size="large"
            iconName="AlignJustify"
            customCSS={{
              Icon: {
                color: "secondary_60",
              },
            }}
            onClick={() => {
              AdminStore.update((s) => {
                s.isMenuOpen = true;
              });
            }}
          />
          <ProductsHeading variant="heading_05">Products</ProductsHeading>
        </IconAndHeadingContainer>
        <AddProductButton
          variant="secondary"
          size="small"
          withIcon={true}
          onClick={navigateToAddProduct}
        >
          <Plus size={24} />
          Add
        </AddProductButton>
      </HeaderContainer>

      {products.length === 0 ? (
        <EmptyState message="No products added yet" />
      ) : (
        <ProductsContainer>
          {products.map((product, idx) => (
            <Product product={product} key={idx} />
          ))}
        </ProductsContainer>
      )}
    </MainContainer>
  );
}
