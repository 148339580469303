import { useNavigate, useParams } from "react-router-dom";
import { OrderWithReferenceId } from "../../../../types/OrderFields";

export const useOrder = (order: OrderWithReferenceId) => {
  const navigate = useNavigate();
  const { storeName } = useParams();
  const navigateToOrderDetails = () => {
    navigate(`/${storeName}/seller/order/${order.id}`);
  };

  return {
    storeName,
    navigateToOrderDetails,
  };
};
