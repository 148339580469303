import { AlignJustify } from "react-feather";
import * as FeatherIcons from "react-feather";
import { Link } from "react-scroll";
import { styled } from "../../../../stitches/stitches.config";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import UseWindowHook from "../../../../hooks/UseWindowHook";
import { Button } from "../../../../stitches/button/Button";
import { useNavigate } from "react-router-dom";

const Container = styled("div", {
  width: "100%",
  boxSizing: "border-box",
  height: "145px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "fixed",
  top: "0",
  zIndex: "1",
  background: "rgba(255, 255, 255, 0.66)",
  backdropFilter: "blur(25px)",

  "@Desktop": {
    padding: "0 64px",
  },
});

const ContainerBottomBorder = styled("div", {
  width: "calc(100% - 32px)",
  height: "4px",
  position: "absolute",
  bottom: "0",
  background: "radial-gradient(#3598AB, rgba(255, 255, 255, 0) 66%)",
});

const GahakLogo = styled("img", {
  height: "60.62px",
});

const getStyledIcon = (Icon: FeatherIcons.Icon) => {
  return styled(Icon, {
    color: "$secondary_80",
    width: "40px",
    height: "40px",
    visibility: "hidden",
  });
};

const DesktopViewNavBarButtonsContainer = styled("div", {
  display: "flex",
  columnGap: "60px",
});

const DesktopNavBarButton = styled(Heading, {
  color: "$secondary_40",

  "&:hover": {
    cursor: "pointer",
  },
});

const ButtonsContainer = styled("div", {
  display: "flex",
  columnGap: "16px",
});

export const Header = () => {
  const HambugerMenuButton = getStyledIcon(AlignJustify);
  const navigate = useNavigate();

  const { width } = UseWindowHook();

  const isDesktopView: boolean = width >= 1200;

  const onLoginClick = () => {
    navigate("/seller/signin");
  };

  const onSignupClick = () => {
    navigate("/seller/signup");
  };

  return (
    <>
      <Container>
        <GahakLogo src="/GahakHeader.png" alt="GahakHeader" />
        {isDesktopView ? (
          <DesktopViewNavBarButtonsContainer>
            <DesktopNavBarButton variant="heading_03">
              <Link to="banner" smooth={true} offset={-191}>
                Home
              </Link>
            </DesktopNavBarButton>
            <DesktopNavBarButton variant="heading_03">
              <Link to="features" smooth={true} offset={-145}>
                Features
              </Link>
            </DesktopNavBarButton>
          </DesktopViewNavBarButtonsContainer>
        ) : (
          <HambugerMenuButton />
        )}
        <ButtonsContainer>
          <Button
            outlined
            variant="secondary"
            size="large"
            onClick={onLoginClick}
          >
            Login
          </Button>
          <Button variant="secondary" size="large" onClick={onSignupClick}>
            Sign Up
          </Button>
        </ButtonsContainer>
        <ContainerBottomBorder />
      </Container>
    </>
  );
};
