import React, { useEffect } from "react";
import { styled } from "../../../stitches/stitches.config";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { Display } from "../../../stitches/typography/DisplayStyles";
import Menu from "../../Menu/Menu";
import SearchView from "../View/SearchView";
import Tab from "../../../stitches/Tab/Tab";
import OrdersTable from "./OrdersTable";
import { useStoreState } from "pullstate";
import { AdminStore } from "../../../pullstate/Admin";
import { IOrderStatus } from "@gahakapp/client";
import EmptyState from "../../../components/EmptyState";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  margin: "auto",
  height: "100vh",
  padding: "32px",
  boxSizing: "border-box",
  columnGap: "32px",
});

const MenuContainer = styled("div", {
  height: "100%",
});

const ContentContainer = styled("div", {
  borderRadius: "10px",
  border: "3px solid #F0F0F0",
  // padding: "30px 26px",
  paddingTop: "30px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const DashboardHeading = styled(Display, {
  color: "$secondary_60",
  margin: "0",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "0 0 45px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
  justifyContent: "space-between",
  padding: "0 26px",
});

const FiltersContainer = styled("div", {
  display: "flex",
  columnGap: "26px",
  marginBottom: "26px",
  padding: "0 26px",
});

const StyledTab = styled(Tab, {
  width: "fit-content !important",
});

const OrdersTableContainer = styled("div", {
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
});

export interface IDesktopOrdersProps {
  handleSearchChange: (e: any) => void;
  searchTerm: string;
}

export default function DesktopOrders({
  handleSearchChange,
  searchTerm,
}: IDesktopOrdersProps) {
  const { selectedStatusFilter, orders } = useStoreState(
    AdminStore,
    (s) => s.ordersStore
  );

  const setStatusForFiltration = (orderStatus?: IOrderStatus) => {
    AdminStore.update((s) => {
      s.ordersStore.selectedStatusFilter = orderStatus;
    });
  };

  return (
    <MainContainer>
      <MenuContainer>
        <Menu />
      </MenuContainer>
      <ContentContainer>
        <HeaderContainer>
          <DashboardHeading variant="display_sm_01">Orders</DashboardHeading>
          <SearchView
            onChangeHandler={handleSearchChange}
            searchInput={searchTerm}
          />
        </HeaderContainer>

        <FiltersContainer>
          <StyledTab
            iconName="List"
            size="medium"
            selected={!selectedStatusFilter}
            onClick={() => {
              setStatusForFiltration(undefined);
            }}
          >
            All
          </StyledTab>
          <StyledTab
            iconName="AlertCircle"
            size="medium"
            iconColor="$colors$tertiary_60"
            selected={selectedStatusFilter === IOrderStatus.IN_PROGRESS}
            onClick={() => {
              setStatusForFiltration(IOrderStatus.IN_PROGRESS);
            }}
          >
            In Progress
          </StyledTab>
          <StyledTab
            iconName="CheckCircle"
            size="medium"
            iconColor="$colors$success_50"
            selected={selectedStatusFilter === IOrderStatus.COMPLETED}
            onClick={() => {
              setStatusForFiltration(IOrderStatus.COMPLETED);
            }}
          >
            Completed
          </StyledTab>
        </FiltersContainer>

        {orders.length === 0 ? (
          <EmptyState message="No orders received yet" />
        ) : (
          <OrdersTableContainer>
            <OrdersTable />
          </OrdersTableContainer>
        )}
      </ContentContainer>
    </MainContainer>
  );
}
