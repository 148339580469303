import { styled } from "../../../stitches/stitches.config";
import { IOrderStatus } from "../../../types/OrderFields";

export const Container = styled("div", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "$space$space_10",
  width: "120px",
  padding: "4px 6px",

  variants: {
    status: {
      [IOrderStatus.IN_PROGRESS]: {
        backgroundColor: "$colors$tertiary_100",
      },
      [IOrderStatus.COMPLETED]: {
        backgroundColor: "$colors$success_100",
      },
      [IOrderStatus.CANCELLED]: {
        backgroundColor: "$colors$Danger_100",
      },
    },
  },
});

interface IStatusChipProps {
  status: IOrderStatus;
}

const statusDisplay = {
  [IOrderStatus.IN_PROGRESS]: "In Progress",
  [IOrderStatus.COMPLETED]: "Completed",
  [IOrderStatus.CANCELLED]: "Cancelled",
};

export default function StatusChip({ status }: IStatusChipProps) {
  return <Container status={status}>{statusDisplay[status]}</Container>;
}
