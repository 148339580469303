import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Uppy from "@uppy/core";
import { AdminStore } from "../../../../../pullstate/Admin";
import { UpdateProductFields } from "../../../../../types/ProductFields";
import { productValidationSchema } from "../../../../AddProduct/resolver/Product.resolver";
import UseAuth from "../../../../../hooks/UseAuth";
import { ApiClientContext } from "../../../../../context/ApiClientContext";
import { compressImagesWithThumbnail } from "../../../../../util/CompressImage";
import {
  deleteAwsS3Images,
  getAwsS3ImageUrls,
  getAwsS3ImageWithThumbnails,
} from "../../../../../util/s3Images.util";
import { handleError } from "../../../../../util/ErrorHandler";
import { Image, Product } from "../../../../../types/product/new/product.model";
import { ProductAndVariantsUpdate } from "@gahakapp/client";

export const useProductContainer = () => {
  const product = AdminStore.useState((s) => s.selectedProduct);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    unregister,
  } = useForm<UpdateProductFields>({
    mode: "onSubmit",
    resolver: yupResolver(productValidationSchema),
    defaultValues: {
      id: product?.id,
      name: product?.name,
      isDeleted: false,
      hasVariants: product?.hasVariants,
      description: product?.description,
      attributes: product?.attributes,
      variants: product?.variants,
      images: undefined,
    },
  });
  const { user } = UseAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const prevImagesLoadedRef = useRef(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isCancelChangesModalOpen, setIsCancelChangesModalOpen] =
    useState(false);
  const { storeName } = useParams();
  const apiClient = useContext(ApiClientContext);
  const currentImagesNames = useRef<Set<string>>(new Set());
  const currentImagesToDeleteNames = useRef<Set<string>>(new Set());
  const deleteVariantsIdsRef = useRef<Set<string>>(new Set());
  const watcher = watch([
    "variants",
    "description",
    "name",
    "images",
    "hasVariants",
  ]);

  const setHasVariantToTrue = () => {
    setValue("hasVariants", true);
  };

  const navigateToProducts = () => {
    // setIsExitModalOpen(true);
    navigate("/" + storeName + "/seller/products");
  };

  const handleDialogClose = () => {
    setIsExitModalOpen(false);
    setIsCancelChangesModalOpen(false);
  };

  const openExitWithoutSavingDialog = () => {
    setIsExitModalOpen(true);
  };

  const openCancelChangesDialog = () => {
    setIsCancelChangesModalOpen(true);
  };

  const handleExitWithoutSaving = () => {
    handleExitEditMode();
  };

  const handleCancelChanges = () => {
    handleExitEditMode();
  };
  const handleExitEditMode = () => {
    navigate(`/${storeName}/seller/product/${product?.id}`);
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSizeChartUpload = (image: File | null) => {
    setValue("sizeChart", image ?? undefined);
  };

  useEffect(() => {
    const [variants, description, name, images, hasVariants] = watcher;
    const isBaseEmpty = !description || !name || !!!images?.length;
    if (hasVariants) {
      const isVariantEmpty = variants?.some((variant) => {
        return (
          !variant.attributes?.[0].value || !variant.price || !variant.quantity
        );
      });
      setIsButtonDisabled(isBaseEmpty || isVariantEmpty);
    }

    if (!hasVariants) {
      const isVariantEmpty = variants?.some(
        (variant) => !variant.price || !variant.quantity
      );
      setIsButtonDisabled(isBaseEmpty || isVariantEmpty);
    }
  }, [watcher]);

  const uppyRef = useRef<Uppy>(
    new Uppy({
      onBeforeFileAdded: (currentFile, files) => {
        for (const value of Object.values(files)) {
          if (value.name === currentFile.name) {
            return false;
          }
        }
        return true;
      },
    })
  );

  async function getBlob(url: string): Promise<void> {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();

      const filename: string = decodeURIComponent(
        url.substring(url.lastIndexOf("/") + 1).split(".")[0]
      );

      const uppyFile = {
        name: filename, // file name
        type: blob.type, // file type
        data: blob, // file blob
      };
      uppyRef.current.addFile(uppyFile);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (product) {
      register("images", { value: new DataTransfer().files });

      if (!prevImagesLoadedRef.current) {
        product.images.forEach((image) => {
          currentImagesNames.current.add(
            image.url.substring(image.url.lastIndexOf("/") + 1).split(".")[0]
          );
          getBlob(image.url);
        });

        prevImagesLoadedRef.current = true;
      }
    }
  }, []);

  useEffect(() => {
    uppyRef.current.on("files-added", (file) => {
      if (file.length > 0) {
        handleImages(uppyRef.current.getFiles());
      }
    });

    uppyRef.current.on("file-removed", (file) => {
      if (currentImagesNames.current.has(file.meta.name)) {
        const imageNameWithType = `${file.meta.name}.${
          file.meta.type?.split("/")[1]
        }`;
        currentImagesToDeleteNames.current.add(imageNameWithType);
      }
      handleImages(uppyRef.current.getFiles());
    });
  }, []);

  const handleImages = (uppyFiles: any[]) => {
    if (uppyFiles.length > 0) {
      if (errors.images) {
        clearErrors("images");
      }
    } else {
      setError("images", {
        type: "custom",
        message: "Please enter Product Images",
      });
    }
    const dt = new DataTransfer();
    uppyFiles.forEach((uppyFile: any) => {
      const data = uppyFile.data;
      if (data instanceof Blob) {
        const file = new File([data], uppyFile.name, { type: data.type });
        dt.items.add(file);
        return;
      }
      dt.items.add(data);
    });
    setValue("images", dt.files);
  };

  /**
   *
   * @param data
   * @returns
   */
  const onSubmit = async (data: UpdateProductFields) => {
    setIsLoading(true);

    const formattedData: Omit<UpdateProductFields, "images" | "sizeChart"> & {
      images: Image[];
      sizeChart?: Image;
    } & {
      deletedVariantsIds: string[];
    } = {
      id: data.id,
      isDeleted: false,
      name: data.name,
      description: data.description,
      hasVariants: data.hasVariants,
      images: [],
      attributes: [],
      variants: [],
      deletedVariantsIds: Array.from(deleteVariantsIdsRef.current),
    };

    if (!data.hasVariants) {
      const variants = data.variants.map((variant) => {
        return {
          ...variant,
          attributes: [],
        };
      });

      formattedData.variants = variants;
    } else {
      const productAttributeValues: string[] = [];

      let hasDuplicate = false;
      const uniqueVariants = data.variants.filter((variant, idx) => {
        variant.attributes.filter((attribute) => {
          if (productAttributeValues.includes(attribute.value)) {
            setError(`variants.${idx}.attributes.${0}.value`, {
              type: "custom",
              message: "Duplicate value",
            });
            hasDuplicate = true;
            return false;
          }
          productAttributeValues.push(attribute.value);
        });
        return true;
      });

      if (hasDuplicate) {
        setIsLoading(false);
        return;
      }

      const variants = uniqueVariants.map((variant) => {
        const variantAttributes = variant.attributes.map((attribute) => {
          return {
            ...attribute,
            name: data.attributes[0].name,
          };
        });

        return {
          ...variant,
          attributes: variantAttributes,
        };
      });

      formattedData.variants = variants;

      const productAttributes = [
        { name: data.attributes.at(0)!.name, value: productAttributeValues },
      ];
      formattedData.attributes = productAttributes;
    }

    if (currentImagesToDeleteNames.current.size > 0) {
      await deleteAwsS3Images(
        storeName!,
        Array.from(currentImagesToDeleteNames.current)
      );
    }
    const compressedImages = await compressImagesWithThumbnail(data.images);
    const images: Image[] = await getAwsS3ImageWithThumbnails(
      storeName!,
      compressedImages
    );
    if (images.length !== data.images.length) {
      handleError(
        "Something went wrong with images addition. Please try again by re-adding the images."
      );
      setIsLoading(false);
      return;
    }
    formattedData.images = images;

    if (data.sizeChart) {
      const sizeChart = await getAwsS3ImageUrls(storeName!, [data.sizeChart]);
      if (sizeChart.length === 0) {
        handleError(
          "Something went wrong with size chart addition. Please try again by re-adding the size chart image."
        );
        setIsLoading(false);
        return;
      }
      formattedData.sizeChart = sizeChart[0];
    }

    const newProductData: ProductAndVariantsUpdate = {
      id: formattedData.id,
      name: formattedData.name,
      description: formattedData.description,
      images: formattedData.images,
      hasVariants: formattedData.hasVariants,
      variants: formattedData.variants,
      isDeleted: formattedData.isDeleted,
      attributes: formattedData.attributes,
      deletedVariantsIds: formattedData.deletedVariantsIds,
      sizeChart: formattedData.sizeChart,
    };

    try {
      // setIsLoading(true);
      const updatedProductData = await apiClient.default.updateProduct(
        storeName!,
        user!.uid,
        product!.id,
        newProductData
      );

      AdminStore.update((s) => {
        const productIdx = s.products.findIndex((s) => s.id == product?.id);
        if (productIdx >= 0) {
          s.products[productIdx] = updatedProductData;
          s.selectedProduct = updatedProductData;
        }
      });
      handleExitEditMode();
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
    handleSubmit,
    register,
    unregister,
    watch,
    uppyRef,
    isButtonDisabled,
    setHasVariantToTrue,
    isLoading,
    navigateToProducts,
    isExitModalOpen,
    isCancelChangesModalOpen,
    handleDialogClose,
    product,
    handleExitEditMode,
    setValue,
    deleteVariantsIdsRef,
    handleExitWithoutSaving,
    handleCancelChanges,
    openExitWithoutSavingDialog,
    openCancelChangesDialog,
    handleSizeChartUpload,
  };
};
