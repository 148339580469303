import React from "react";
import { styled } from "../stitches.config";
import { headings } from "../typography/HeadingStyles";

export const Button = styled("button", {
  // mini reset
  appearance: "none",
  border: "none",
  backgroundColor: "transparent",
  lineHeight: 1,
  padding: "10px",
  borderRadius: "$space$space_10",
  gap: "4px",
  color: "$white_100",
  boxSizing: "border-box",

  variants: {
    size: {
      small: {
        ...headings.heading_02,
        color: "$white_100",
      },
      large: {
        ...headings.heading_03,
        color: "$white_100",
      },
    },
    variant: {
      success: {
        backgroundColor: "$success_100",
        "&:hover": {
          backgroundColor: "$success_100",
        },
        "&:active": {
          backgroundColor: "$success_100",
        },
        "&:disabled": {
          backgroundColor: "$grey_50",
        },
      },
      primary: {
        backgroundColor: "$secondary_40",
        "&:hover": {
          backgroundColor: "$secondary_60",
        },
        "&:active": {
          backgroundColor: "$secondary_20",
        },
        "&:disabled": {
          backgroundColor: "$grey_50",
        },
      },

      secondary: {
        backgroundColor: "$secondary_80",
        "&:hover": {
          backgroundColor: "$secondary_100",
        },
        "&:active": {
          backgroundColor: "$secondary_60",
        },
        "&:disabled": {
          backgroundColor: "$grey_100",
        },
      },
      tertiary: {
        color: "black",
        backgroundColor: "$tertiary_80",
        "&:hover": {
          backgroundColor: "$tertiary_100",
        },
        "&:active": {
          backgroundColor: "$tertiary_60",
        },
        "&:disabled": {
          backgroundColor: "$grey_100",
        },
      },
    },

    outlined: {
      true: {
        backgroundColor: "transparent",
      },
    },

    withIcon: {
      true: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },

  compoundVariants: [
    {
      variant: "primary",
      outlined: true,
      css: {
        border: "2px solid $secondary_40",
        background: "transparent",
        color: "$text_60",
        "&:hover": {
          borderColor: "$secondary_80",
          background: "transparent",
        },
        "&:active": {
          borderColor: "$secondary_20",
          background: "transparent",
        },
        "&:disabled": {
          borderColor: "$text_100",
          background: "transparent",
          color: "$text_100",
        },
      },
    },
    {
      variant: "secondary",
      outlined: true,
      css: {
        border: "2px solid $secondary_80",
        background: "transparent",
        color: "$text_80",
        "&:hover": {
          borderColor: "$secondary_100",
          background: "transparent",
        },
        "&:active": {
          borderColor: "$secondary_60",
          background: "transparent",
        },
        "&:disabled": {
          borderColor: "$grey_50",
          background: "transparent",
          color: "$text_100",
        },
      },
    },
  ],
});
