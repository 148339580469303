import React, { useState, useEffect } from "react";
import { styled } from "@stitches/react";
import styles from "./Chip.module.css";

type ChipProps = {
  text: string;
  color: string;
  activeColor?: string;
  isOnClick?: boolean;
  isFilled?: boolean;
};

const colorType: {
  [key: string]: string;
} = {
  primary: "#1976d2",
  secondary: "#a100f2",
  success: "#50E969",
  warning: "#e09f3e",
  error: "#d00000",
  info: "#FFD057",
  disabled: "#6c757d",
};

export default function Chip({
  text,
  color,
  activeColor,
  isOnClick,
  isFilled = false,
}: ChipProps) {
  const handleClick = () => {
    setActive(!active);
    if (active) {
      setColour(colorType[activeColor ?? "disabled"]);
    } else {
      setColour(colorType.disabled);
    }
  };

  useEffect(() => {
    setColour(colorType[color]);
  }, [color]);

  const [active, setActive] = useState(true);
  const [colour, setColour] = useState(
    isFilled ? colorType[color] : colorType.disabled
  );

  return (
    <div
      className={styles.chip}
      style={{
        color: isFilled ? "black" : colour,
        backgroundColor: isFilled ? colour : "transparent",
        outline: `1px solid ${colour}`,
        cursor: "pointer",
      }}
      onClick={!!isOnClick ? handleClick : undefined}
    >
      {text === "IN_PROGRESS" ? "In Progress" : "Completed"}
    </div>
  );
}
