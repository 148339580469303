import React from "react";
import { TextBox, TextBoxIconPosition } from "../../stitches/textbox/TextBox";
import { Search as SearchIcon } from "react-feather";

type Props = {
  onChangeHandler: (e: any) => void;
  searchInput: string;
  placeholder: string;
};

export default function Search({
  onChangeHandler,
  searchInput,
  placeholder,
}: Props) {
  const icon = {
    position: TextBoxIconPosition.LEADING,
    Icon: SearchIcon,
  };

  return (
    <>
      <TextBox
        placeholder={placeholder}
        icon={icon}
        value={searchInput}
        onChange={onChangeHandler}
      />
    </>
  );
}
