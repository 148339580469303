import React from "react";
import styles from "./Button.module.css";

interface IButtonProps {
  children: React.ReactNode;
  onSubmit: () => void;
  backgroundColor?: string;
  color?: string;
  margin?: string;
}
export default function Button({
  children,
  onSubmit,
  backgroundColor = "#3485ff",
  color = "white",
  margin = "50px",
}: IButtonProps) {
  return (
    <button
      className={styles.button}
      onClick={onSubmit}
      style={{ backgroundColor, color, margin }}
    >
      {children}
    </button>
  );
}
