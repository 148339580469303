import { CartProduct } from "../../types/CartFields";
import { Product, Variant } from "../../types/product/new/product.model";
import { CustomerStore, ICartStore } from "../../pullstate/Customer";
import _ from "lodash";

export const addProductToCart = (
  product: Product,
  selectedVariant: Variant,
  selectedQuantity: number,
  storeName?: string
): void => {
  CustomerStore.update((s) => {
    s.cart.numberOfItems += selectedQuantity;
    s.cart.total += Number(selectedVariant.price) * selectedQuantity;

    // Update available quantity in pullstate
    const selectedVariantIndex: number = s.selectedProduct!.variants.findIndex(
      (variant) => variant.id === selectedVariant.id
    );
    const selectedVariantProductIndex = s.products.findIndex(
      (productFromArray) => productFromArray.id === product.id
    );
    s.selectedProduct!.variants[selectedVariantIndex].quantity -=
      selectedQuantity;
    s.products[selectedVariantProductIndex].variants[
      selectedVariantIndex
    ].quantity -= selectedQuantity;

    const isProductPresent = s.cart.cartDisplayMap.has(product.id);
    const cartItem: CartProduct = prepareCartItems(
      product,
      selectedVariant,
      selectedQuantity
    );

    // CASE: Product is not present in cart
    if (!isProductPresent) {
      s.cart.cartDisplayMap.set(product.id, [cartItem]);
      return;
    }

    const productVariants: CartProduct[] = s.cart.cartDisplayMap.get(
      product.id
    )!;
    const isVariantPresent = productVariants.findIndex(
      (productVariant) => productVariant.variant.id === selectedVariant.id
    );

    // CASE: Product is present in cart but variant is not present
    if (isVariantPresent === -1) {
      productVariants.push(cartItem);
      return;
    }

    // CASE: Product and variant is present in cart
    productVariants[isVariantPresent].selectedQuantity += selectedQuantity;
    return;
  });

  const updatedCart = CustomerStore.getRawState().cart;
  saveCartToLocalStorage(updatedCart, storeName);
};

export const removeProductFromCart = (
  product: Product,
  selectedVariant: Variant,
  storeName?: string
): void => {
  CustomerStore.update((s) => {
    const isProductPresent = s.cart.cartDisplayMap.has(product.id);

    // CASE: Product is not present in cart
    if (!isProductPresent) {
      return;
    }

    const productVariants = s.cart.cartDisplayMap.get(product.id)!;
    const variantIndex = productVariants.findIndex(
      (productVariant) => productVariant.variant.id === selectedVariant.id
    );

    // CASE: Product is present in cart but variant is not present
    if (variantIndex === -1) {
      return;
    }

    // CASE: Product and variant is present in cart and quantity is 1
    if (productVariants[variantIndex].selectedQuantity === 1) {
      productVariants.splice(variantIndex, 1);

      if (!productVariants.length) {
        s.cart.cartDisplayMap.delete(product.id);
      }
    } else {
      // CASE: Product and variant is present in cart
      productVariants[variantIndex].selectedQuantity -= 1;
    }

    s.cart.numberOfItems -= 1;
    s.cart.total -= Number(selectedVariant.price);

    return;
  });

  const updatedCart = CustomerStore.getRawState().cart;
  saveCartToLocalStorage(updatedCart, storeName);
};

export const prepareCartItems = (
  product: Product,
  selectedVariant: Variant,
  selectedQuantity: number
): CartProduct => {
  const { variants, ...productWithoutVariants } = product;

  return {
    ...productWithoutVariants,
    variant: selectedVariant,
    selectedQuantity,
  };
};

type CartDisplayMapEntry = [string, CartProduct[]];
type SerializedCart = Omit<ICartStore, "cartDisplayMap"> & {
  cartDisplayMap: CartDisplayMapEntry[];
};

export function saveCartToLocalStorage(
  cart: ICartStore,
  storeName?: string
): void {
  const cartToSave: SerializedCart = {
    ...cart,
    isOpen: false,
    cartDisplayMap: Array.from(cart.cartDisplayMap.entries()),
  };
  sessionStorage.setItem(`${storeName}_gahak_cart`, JSON.stringify(cartToSave));

  if (storeName && cart.numberOfItems === 0) {
    sessionStorage.removeItem(`${storeName}_gahak_cart`);
  }
}

export function loadCartFromLocalStorage(
  storeName?: string
): ICartStore | null {
  const savedCart = sessionStorage.getItem(`${storeName}_gahak_cart`);
  const currentCart = CustomerStore.getRawState().cart;
  if (savedCart) {
    const parsedCart: SerializedCart = JSON.parse(savedCart);
    const cart: ICartStore = {
      ...parsedCart,
      cartDisplayMap: new Map(parsedCart.cartDisplayMap),
    };

    if (!_.isEqual(cart, currentCart)) {
      CustomerStore.update((s) => {
        s.cart = cart;
      });
    }

    return cart;
  }
  return null;
}
