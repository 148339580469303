import React from "react";
import { styled } from "../stitches.config";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { bodyStyles } from "../typography/BodyStyles";

const Container = styled(RadioGroup.Root, {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const Item = styled(RadioGroup.Item, {
  lineHeight: 1.2,
  padding: 0,
  margin: 0,
  backgroundColor: "$white_100",
  width: "$space$space_16",
  height: "$space$space_16",
  borderRadius: "100%",
  border: "1px solid $text_100",

  "&:hover": {
    borderColor: "$text_60",
  },
});

const Indicator = styled(RadioGroup.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  boxSizing: "border-box",

  "&::after": {
    content: "",
    display: "block",
    width: "10px",
    height: "10px",
    borderRadius: "$radii$ROUND",
    backgroundColor: "$primary_80",
  },

  variants: {
    disabled: {
      true: {
        "&::after": {
          backgroundColor: "$text_100",
        },
      },
    },
  },
});

const ItemContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$space$space_10",
});

const ItemLabel = styled("label", {
  ...bodyStyles.body_01,
  color: "$text_20",

  variants: {
    disabled: {
      true: {
        color: "$text_100",
      },
    },
  },
});

export type RadioItemProps = {
  value: string;
  label: string;
  id: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

export type RadioProps = {
  radioItems: Omit<RadioItemProps, "onClick">[];
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function Radio({ radioItems, onClick }: RadioProps) {
  return (
    <>
      <Container>
        {radioItems.map((radioItem, idx) => (
          <RadioItem
            key={idx}
            id={radioItem.id}
            label={radioItem.label}
            value={radioItem.value}
            disabled={radioItem.disabled}
            onClick={onClick}
          />
        ))}
      </Container>
    </>
  );
}

export function RadioItem({
  label,
  value,
  id,
  disabled,
  onClick,
}: RadioItemProps) {
  return (
    <ItemContainer>
      <Item value={value} id={id} onClick={onClick}>
        <Indicator disabled={!!disabled} />
      </Item>
      <ItemLabel disabled={!!disabled} htmlFor={id}>
        {label}
      </ItemLabel>
    </ItemContainer>
  );
}
