import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { UseFormRegister, UseFormUnregister } from "react-hook-form";
import { ProductFields } from "../../../../../types/ProductFields";
import { Button } from "../../../../../stitches/button/Button";
import { Heading } from "../../../../../stitches/typography/HeadingStyles";
import { TextBox } from "../../../../../stitches/textbox/TextBox";
import { styled } from "@stitches/react";
import { useDeviceDimensions } from "../../../../../hooks/useDevice";
import Drawer from "react-modern-drawer";

const DialogActionStyle = styled(DialogActions, {
  display: "flex",
  justifyContent: "space-evenly",
  padding: "0 24px",
});
const ExitButton = styled(Button, {
  backgroundColor: "$danger_100 !important",
  color: "$white_100",
  flexGrow: 1,
});

interface ProductDeleteDialogProps {
  isDialogOpen: boolean;
  handleExitDialog: () => void;
  handleConfirmDeletion: () => void;
}

export default function ProductDeleteDialog({
  isDialogOpen,
  handleConfirmDeletion,
  handleExitDialog,
}: ProductDeleteDialogProps) {
  const { isDesktop } = useDeviceDimensions();

  return isDesktop ? (
    <Dialog
      open={isDialogOpen}
      keepMounted
      onClose={handleExitDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle>
        <Heading variant="heading_02">Delete Product?</Heading>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete the product? Once deleted, you can't
        undo this action.
      </DialogContent>
      <DialogActionStyle>
        <Button
          variant="secondary"
          outlined
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleExitDialog}
          type="button"
        >
          Keep
        </Button>
        <ExitButton
          variant="secondary"
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleConfirmDeletion}
          type="button"
        >
          Delete
        </ExitButton>
      </DialogActionStyle>
    </Dialog>
  ) : (
    <Drawer open={isDialogOpen} onClose={handleExitDialog} direction={"bottom"}>
      <DialogTitle>
        <Heading variant="heading_02">Delete Product?</Heading>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete the product? Once deleted, you can't
        undo this action.
      </DialogContent>
      <DialogActionStyle>
        <Button
          variant="secondary"
          outlined
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleExitDialog}
          type="button"
        >
          Keep
        </Button>
        <ExitButton
          variant="secondary"
          size="small"
          style={{ flexGrow: 1 }}
          onClick={handleConfirmDeletion}
          type="button"
        >
          Delete
        </ExitButton>
      </DialogActionStyle>
    </Drawer>
  );
}
