import React, { useContext, useEffect, useState } from "react";
import OrderList from "../OrderList/View/OrderList";
import SearchView from "./SearchView";
import UseAuth from "../../../hooks/UseAuth";
import styles from "./Orders.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { IOrderStatus, OrderWithReferenceId } from "../../../types/OrderFields";

import {
  AdminStore,
  IAdminStore,
  OrderOperationEnum,
} from "../../../pullstate/Admin";
import { useStoreState } from "pullstate";
import { ApiClientContext } from "../../../context/ApiClientContext";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { handleError } from "../../../util/ErrorHandler";
import { useDeviceDimensions } from "../../../hooks/useDevice";
import DesktopOrders from "../Desktop/DesktopOrders";
import { styled } from "@stitches/react";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import EmptyState from "../../../components/EmptyState";

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "26px 0 32px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
});

const HeadingText = styled(Heading, {
  color: "$secondary_60",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
});

export default function Orders() {
  const { user } = UseAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const { storeName } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const apiClient = useContext(ApiClientContext);

  const orders = useStoreState(AdminStore, (s) => s.ordersStore.orders);

  const fetchOrders = async () => {
    const orders = await apiClient.default.getOrders(storeName!);

    AdminStore.update((state) => {
      state.ordersStore.orders = orders;
    });

    setIsLoading(false);
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
    const filteredOrders = orders.filter((order) => {
      return order.referenceId.includes(e.target.value.toUpperCase());
    });

    AdminStore.update((state) => {
      state.ordersStore.filterSearchTerm = e.target.value;
      state.ordersStore.filteredOrders = filteredOrders;
    });
  };

  const markOrderAsComplete = async (selectedOrder: OrderWithReferenceId) => {
    try {
      setIsLoading(true);
      const updateStatusData = {
        id: selectedOrder.id,
        status: IOrderStatus.COMPLETED,
      };
      await apiClient.default.updateStatus(storeName || "", user!.uid, {
        data: updateStatusData,
      });

      AdminStore.update((state) => {
        const orderIndex = state.ordersStore.orders.findIndex(
          (orderFromArray) => selectedOrder.id === orderFromArray.id
        );
        state.ordersStore.orders[orderIndex] = {
          ...state.ordersStore.orders[orderIndex],
          status: IOrderStatus.COMPLETED,
        };
      });
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const deleteOrder = async (selectedOrder: OrderWithReferenceId) => {
    try {
      setIsLoading(true);

      await apiClient.default.deleteOrder(
        storeName || "",
        user!.uid,
        selectedOrder.id
      );

      AdminStore.update((state) => {
        state.ordersStore.orders = state.ordersStore.orders.filter(
          (prevOrder) => {
            return prevOrder.id !== selectedOrder.id;
          }
        );
      });
      setIsLoading(false);
    } catch (error: any) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const orderOperationEventListener = (
    selectedOrder: OrderWithReferenceId | undefined,
    allState: IAdminStore
  ) => {
    const selectedOrderOperation: OrderOperationEnum | undefined =
      allState.ordersStore.selectedOrderOperation;

    switch (selectedOrderOperation) {
      case OrderOperationEnum.UPDATE:
        markOrderAsComplete(selectedOrder!);
        break;
      case OrderOperationEnum.DELETE:
        deleteOrder(selectedOrder!);
        break;
    }
  };

  useEffect(() => {
    if (user) {
      if (orders.length === 0) fetchOrders();
      else setIsLoading(false);

      const unsubscribe = AdminStore.subscribe(
        (s) => s.ordersStore.selectedOrder,
        orderOperationEventListener
      );

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    AdminStore.update((s) => {
      s.ordersStore.selectedStatusFilter = undefined;
      s.ordersStore.filterSearchTerm = "";
    });
  }, []);

  const navigateToDashboard = () => {
    navigate(`/${storeName}/seller/dashboard`);
  };

  const { isDesktop } = useDeviceDimensions();

  if (isLoading) {
    return <Spinner />;
  }

  return isDesktop ? (
    <DesktopOrders
      handleSearchChange={handleSearchChange}
      searchTerm={searchTerm}
    />
  ) : (
    <Container>
      <HeaderContainer>
        <IconBadge
          outlined={false}
          size="large"
          iconName="AlignJustify"
          customCSS={{
            Icon: {
              color: "secondary_60",
            },
            IconBadgeContainer: {
              marginLeft: "16px",
            },
          }}
          onClick={() => {
            AdminStore.update((s) => {
              s.isMenuOpen = true;
            });
          }}
        />
        <HeadingText variant="heading_05">Orders</HeadingText>
      </HeaderContainer>

      {orders.length === 0 ? (
        <EmptyState message="No orders received yet" />
      ) : (
        <>
          <div className={styles.searchContainer}>
            <div className={styles.search}>
              <SearchView
                onChangeHandler={handleSearchChange}
                searchInput={searchTerm}
              />
            </div>
          </div>
          <OrderList />
        </>
      )}
    </Container>
  );
}
