import React from "react";
import { CartProduct } from "../../../../types/CartFields";
import { Heading } from "../../../../stitches/typography/HeadingStyles";
import { Body } from "../../../../stitches/typography/BodyStyles";
import { styled } from "@stitches/react";

const ItemContainer = styled("div", {
  width: "100%",
  height: "144px",
  boxSizing: "border-box",
  padding: "$space$space_10",
  display: "flex",
  columnGap: "$space$space_16",
});

const ItemImageContainer = styled("div", {
  height: "100%",
  borderRadius: "$space$space_10",
  boxSizing: "content-box",
  width: "94px",

  "@media (min-width: 1200px)": {
    width: "200px",
  },

});

const ItemImage = styled("img", {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  border: "1px solid $grey_50",
  borderRadius: "$space$space_10",
});

const ItemNameQuantityPrice = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "167px",
  rowGap: "8px",
});

const ItemPriceHeading = styled(Heading, {
  color: "$secondary_60",
});

interface IItemProp {
  item: CartProduct;
}
export default function Item({ item }: IItemProp) {
  const {
    name,
    variant: { price, attributes },
    images,
    selectedQuantity,
    hasVariants,
  } = item;

  return (
    <ItemContainer>
      <ItemImageContainer>
        <ItemImage src={images[0].url} />
      </ItemImageContainer>
      <ItemNameQuantityPrice>
        <Heading variant="heading_02">{name}</Heading>
        {hasVariants && (
          <Body variant="body_01">
            {attributes[0].name}: {attributes[0].value}
          </Body>
        )}
        <Body variant="body_01">Qty: {selectedQuantity}</Body>
        <ItemPriceHeading variant="heading_02">Rs. {price}</ItemPriceHeading>
      </ItemNameQuantityPrice>
    </ItemContainer>
  );
}
