import React from "react";
import { AdminStore } from "../../../pullstate/Admin";
import { Heading } from "../../../stitches/typography/HeadingStyles";
import { styled } from "../../../stitches/stitches.config";
import IconBadge from "../../../stitches/iconBadge/IconBadge";
import { Box, Save } from "react-feather";
import { TextBox } from "../../../stitches/textbox/TextBox";
import { Body } from "../../../stitches/typography/BodyStyles";
import { useProductContainer } from "./useProductContainer";
import ProductVariants from "./ProductVariants";
import ProductImageContainer from "./ProductImageContainer";
import { Button } from "../../../stitches/button/Button";
import ProductExitDialog from "./ProductExitDialog";
import Spinner from "../../../components/Spinner/Spinner";
import ProductFileUploader from "./ProductFileUploader";

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "auto",
});

const ProductsHeading = styled(Heading, {
  color: "$secondary_60",
});

const HeaderContainer = styled("div", {
  display: "flex",
  margin: "26px 0 32px 0",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "16px",
  justifyContent: "space-between",
});

const IconAndHeadingContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
});

const Input = styled(TextBox, {
  marginBottom: "$space$space_16",
  width: "100%",
});

const Form = styled("form", {
  width: "100%",
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

const PersonalInfoHeaderHeading = styled(Body, {
  color: "$secondary_60",
});

const PersonalInfoHeadingContainer = styled("div", {
  display: "flex",
  columnGap: "$space$space_16",
  borderBottom: "1px solid $grey_100",
  padding: "$space_20 $space_10 $space_20 $space_10",
  marginBottom: "$space_8",
});

const PersonalInfoHeaderIcon = styled(Box, {
  width: "24px",
  height: "24px",
  color: "$secondary_60",
});

const SaveButton = styled(Button, {
  "&:disabled": {
    color: "$text_100",
  },
});

const DisabledSaveIcon = styled(Save, {
  color: "$text_100",
});

export default function MobileProductContainer() {
  const {
    handleSubmit,
    onSubmit,
    register,
    unregister,
    uppyRef,
    watch,
    setHasVariantToTrue,
    isButtonDisabled,
    isLoading,
    handleBackClick,
    handleExitDialog,
    isExitModalOpen,
    handleDialogClose,
    handleSizeChartUpload,
  } = useProductContainer();

  return (
    <MainContainer>
      <HeaderContainer>
        <IconAndHeadingContainer>
          <IconBadge
            iconName="ChevronLeft"
            outlined={false}
            size="large"
            customCSS={{
              Icon: {
                color: "secondary_60",
              },
              IconBadgeContainer: {
                marginLeft: "16px",
              },
            }}
            onClick={handleBackClick}
          />
          <ProductsHeading variant="heading_05">Add Product</ProductsHeading>
        </IconAndHeadingContainer>
      </HeaderContainer>
      <SaveButton
        variant="secondary"
        size="small"
        withIcon="true"
        onClick={handleSubmit(onSubmit)}
        disabled={isButtonDisabled}
      >
        {isButtonDisabled ? <DisabledSaveIcon size={24} /> : <Save size={24} />}
        Save Product
      </SaveButton>

      {isLoading ? (
        <Spinner />
      ) : (
        <Form>
          <PersonalInfoHeadingContainer>
            <PersonalInfoHeaderIcon size={24} />
            <PersonalInfoHeaderHeading variant="body_02">
              Product Details
            </PersonalInfoHeaderHeading>
          </PersonalInfoHeadingContainer>
          <Input
            label="Name"
            placeholder="Enter a name for this product"
            {...register("name", {
              required: "Required Field",
            })}
          />
          <TextBox
            label="Description"
            placeholder="Enter a description for this product"
            {...register("description", {
              required: "Required Field",
            })}
            isMultiLine={true}
          />
          <ProductFileUploader handleSizeChart={handleSizeChartUpload} />
          <ProductVariants
            register={register}
            unregister={unregister}
            watch={watch}
            setHasVariantToTrue={setHasVariantToTrue}
          />

          <ProductImageContainer uppyRef={uppyRef} />
        </Form>
      )}

      <ProductExitDialog
        handleDialogClose={handleDialogClose}
        isDialogOpen={isExitModalOpen}
        handleExitDialog={handleExitDialog}
      />
    </MainContainer>
  );
}
