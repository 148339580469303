import React from "react";
import { motion } from "framer-motion";

interface OrderTransitionProps {
  children: React.ReactNode;
}
export default function OrderTransition({ children }: OrderTransitionProps) {
  return (
    <motion.div
      animate={{
        scale: [1, 2, 2, 1, 1],
      }}
      transition={{ delay: 0.5, duration: 2 }}
    >
      {children}
    </motion.div>
  );
}
