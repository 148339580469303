import errorCodes from "./Error";
import * as Yup from "yup";

export const deliveryDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(errorCodes.firstName),
  lastName: Yup.string().required(errorCodes.lastName),
  email: Yup.string().email("Invalid email format").required(errorCodes.email),
  phoneNumber: Yup.string().required(errorCodes.phoneNumber),
  address: Yup.object().shape({
    addressLine: Yup.string().required(errorCodes.addressLine),
    city: Yup.string().required().required(errorCodes.city),
    country: Yup.string().required().required(errorCodes.country),
  }),
});
