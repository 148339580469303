import { useForm } from "react-hook-form";

export type IdentifiersFormData = {
  email: string;
};

enum IdentifiersFormFields {
  email = "email",
}

type IdentifiersProps = {
  onSubmit: (data: IdentifiersFormData) => void;
};

export function IdentifiersForm({ onSubmit }: IdentifiersProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IdentifiersFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h4>Email</h4>
        {errors.email && <p>{errors.email.message}</p>}
        <input
          {...register(IdentifiersFormFields.email, {
            required: "Input Email",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email format",
            },
          })}
          name={IdentifiersFormFields.email}
          placeholder="Enter your email here."
        />
      </div>

      <button type="submit">Next</button>
    </form>
  );
}
