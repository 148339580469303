import React from "react";
import { Button } from "../../../stitches/button/Button";
import { ChevronLeft, ChevronRight } from "react-feather";
import { styled } from "@stitches/react";

const DeliveryDetailsFooterLayout = styled("div", {
  height: "80px",
  width: "100%",
  backgroundColor: "$white_100",
  display: "flex",
  gap: "$space$space_16",
  alignItems: "center",
  boxShadow: "$space$space_0 -5px 22px rgba(0, 0, 0, 0.05)",
  position: "fixed",
  bottom: "$space$space_0",
  padding: "$space$space_16",
});

const NavigateBackToCartButton = styled(Button, {
  height: "48px",
  width: "93px",
});

const NavigateToPaymentButton = styled(Button, {
  height: "48px",
  width: "100%",
});

interface IDeliveryDetailsFooter {
  navigateToPaymentSelection: () => void;
}

export default function DeliveryDetailsFooter({
  navigateToPaymentSelection,
}: IDeliveryDetailsFooter) {
  return (
    <DeliveryDetailsFooterLayout>
      <NavigateBackToCartButton
        variant="secondary"
        outlined="true"
        size="small"
        withIcon
        disabled={true}
      >
        <ChevronLeft size={24} />
        Back
      </NavigateBackToCartButton>
      <NavigateToPaymentButton
        variant="secondary"
        size="small"
        withIcon
        onClick={navigateToPaymentSelection}
      >
        Next
        <ChevronRight size={24} />
      </NavigateToPaymentButton>
    </DeliveryDetailsFooterLayout>
  );
}
