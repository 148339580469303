import { CustomerFields } from "../../../types/CustomerFields";

export interface CheckOutStep {
  label: string;
  iconName: string;
}

export interface Checkout {
  steps: CheckOutStep[];
  activeStep: number;
  deliveryDetails: Omit<CustomerFields, "id">;
  paymentDetails: PaymentDetails;
}

export interface PaymentDetails {
  mode: PaymentType;
}

export enum PaymentType {
  CASH_ON_DELIVERY = "Cash on Delivery",
}
