import { useLocation } from "react-router-dom";

export const DocumentTitleUpdater = ({ children }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  pathSegments.shift();

  if (pathSegments.length === 1 && pathSegments[0].length === 0) {
    document.title = `Gahak - Welcome`;
    return children;
  }

  if (pathSegments[0] !== "seller") {
    const title = pathSegments[0];
    document.title = `Gahak - ${title}`;
    return children;
  }

  const lastSegment = pathSegments[pathSegments.length - 1];
  const title = lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
  document.title = `Gahak - ${title}`;

  return children;
};

export default DocumentTitleUpdater;
