import { styled } from "../../../../stitches/stitches.config";
import { Body } from "../../../../stitches/typography/BodyStyles";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  bottom: "0",
  width: "100%",
  height: "394px",
  textAlign: "center",
  background:
    "radial-gradient(50.03% 100% at 50.03% 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.76) 100%)",
});

const FooterBackground = styled("img", {
  position: "absolute",
  bottom: "0",
  width: "100%",
  height: "394px",
  zIndex: "-1",
});

const FooterGahakLogo = styled("img", {
  marginTop: "57.95px",
  height: "60.06px",
});

const FooterPunchLine = styled(Body, { marginTop: "17px" });

const FooterFollowUs = styled(Body, {
  marginTop: "41px",
});

const FooterSocialsContainer = styled("div", {
  display: "flex",
  columnGap: "26px",
  marginTop: "16px",
});

const FooterSocial = styled("img", {
  width: "32px",
  height: "32px",
});

const FooterCopyright = styled(Body, {
  marginTop: "53px",
});

export const Footer = () => {
  return (
    <Container>
      <FooterBackground
        src="/FooterBackgroundArt.png"
        alt="FooterBackgroundArt"
      />
      <FooterGahakLogo src="/GahakBlack.png" alt="GahakBlack" />
      <FooterPunchLine variant="body_02">
        Empowering businesses with online presence, one store at a time
      </FooterPunchLine>
      <FooterFollowUs variant="body_02">Follow us</FooterFollowUs>
      <FooterSocialsContainer>
        <a href="https://twitter.com/GahakApp">
          <FooterSocial src="/Twitter.png" alt="Twitter" />
        </a>
        <a href="https://www.instagram.com/gahak_app/?igshid=MmIzYWVlNDQ5Yg%3D%3D">
          <FooterSocial src="/Instagram.png" alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/people/GahakApp-Venture/pfbid029q9cTQbUACiJBnUggLqVMW7Qkur6j1EcbknxrS6fQGpKPrqfB1ZDuqnnmXeKjiwCl/?mibextid=LQQJ4d">
          <FooterSocial src="/Facebook.png" alt="Facebook" />
        </a>
      </FooterSocialsContainer>
      <FooterCopyright variant="body_mini_03">
        Copyright © Kaajus 2023
      </FooterCopyright>
    </Container>
  );
};
